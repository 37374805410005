import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import queryString from "query-string"

import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  fetchStore,
  onboardingLink,
} from "../../store/actions"
import { Container, Row, Col } from "reactstrap"
import stripeImg from "../../assets/images/store/stripe.png"

import SweetAlert from "react-bootstrap-sweetalert"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/RightSidebar"

//redux
import { useSelector, useDispatch } from "react-redux"

const Layout = props => {
  const dispatch = useDispatch()

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }))

  const {
    store,
    success,
    error,
    link,
    onboardingSuccess,
    onboardingLoading,
    loading,
  } = useSelector(state => ({
    success: state.store.storeFetchSuccess,
    error: state.store.storeFetchError,
    store: state.store.store,
    link: state.Login.link,
    onboardingSuccess: state.Login.onboardingSuccess,
    onboardingLoading: state.Login.onboardingLoading,
    loading: state.store.storeFetchLoading,
  }))
  const [onboardingSuccessCheck, setonboardingSuccessCheck] = useState(false)
  const [onboardingFailed, setonboardingFailed] = useState(false)

  useEffect(() => {
    let params = queryString.parse(props.location.search)
    if (params && params.onboarding) {
      if (store && !store.has_onboarded) {
        if (params.onboarding === "failed") {
          setonboardingFailed(true)
        }
      }
      if (params.onboarding === "completed") {
        setonboardingSuccessCheck(true)
      }
    }
    if (!success && !loading) {
      dispatch(fetchStore())
    }
    if (
      store &&
      !store.has_onboarded &&
      !onboardingLoading &&
      !onboardingSuccess
    ) {
      const data = {
        refresh_url: process.env.REACT_APP_STRIPEREFRESHURL,
        return_url: process.env.REACT_APP_STRIPERETURNURL,
      }
      dispatch(onboardingLink(data))
    }
  }, [store, success, error, link])

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  /*
  layout  settings
  */

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [isPreloader])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(changeLayout("vertical"))
  }, [dispatch])

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme))
    }
  }, [leftSideBarTheme, dispatch])

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage))
    }
  }, [leftSideBarThemeImage, dispatch])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [layoutWidth, dispatch])

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType))
    }
  }, [leftSideBarType, dispatch])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme("dark"))
    }
  }, [topbarTheme, dispatch])

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={false}
        />
        {onboardingSuccessCheck ? (
          <Container fluid>
            <Row>
              <Col xl="3" lg="4" sm="6" className="mb-2">
                <SweetAlert
                  title={"SUCCESSFULLY ONBOARDED!"}
                  success
                  confirmBtnBsStyle="success"
                  confirmBtnText={"Go to home page"}
                  onConfirm={() => {
                    window.location.replace("/")
                  }}
                >
                  You are successfully onboarded to Stripe. Go back to home
                  page.
                </SweetAlert>
              </Col>
            </Row>
          </Container>
        ) : onboardingFailed ? (
          <Container fluid>
            <Row>
              <Col xl="3" lg="4" sm="6" className="mb-2">
                <SweetAlert
                  title={"ONBOARDING UNSUCCESSFULL!"}
                  error
                  confirmBtnBsStyle="success"
                  confirmBtnText={"Go to home page"}
                  onConfirm={() => {
                    window.location.replace("/")
                  }}
                >
                  To complete the sign-up process, please go back to home.
                </SweetAlert>
              </Col>
            </Row>
          </Container>
        ) : store && store.has_onboarded ? (
          <div className="main-content">{props.children}</div>
        ) : (
          <Container>
            <Row>
              <Col xl="3" lg="4" sm="6" className="mb-2">
                {link !== "" && typeof link !== "undefined" && (
                  <SweetAlert
                    title={"CONNECT TO STRIPE"}
                    customIcon={stripeImg}
                    custom
                    confirmBtnBsStyle="success"
                    confirmBtnText={"Connect to Stripe"}
                    onConfirm={() => {
                      window.open(link, "_blank")
                    }}
                  >
                    To complete the sign-up process, please connect to your
                    Stripe account.
                  </SweetAlert>
                )}
              </Col>
            </Row>
          </Container>
        )}
        <Footer />
      </div>
      {showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
  history: PropTypes.object,
}

export default withRouter(Layout)
