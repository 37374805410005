import React, { useEffect, useState } from "react"
// Redux
import InputMask from "react-input-mask"

import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Row, Col, CardBody, Card, Input, Container, Alert } from "reactstrap"
// availity-reactstrap-validation
import { useSelector, useDispatch } from "react-redux"
import line from "../../../assets/images/register/line.png"
import tick from "../../../assets/images/register/tick.png"
import twoDark from "../../../assets/images/register/2-dark.png"
import threeLight from "../../../assets/images/register/3-light.png"
import fourLight from "../../../assets/images/register/4-light.png"

import { AvForm, AvField } from "availity-reactstrap-validation"

const Step2 = props => {
  const handleValidSubmit = (event, values) => {
    props.setdata({
      ...props.data,
      phone: values.phoneNumber,
      email: values.email,
    })
    props.setstep(3)
  }
  return (
    <React.Fragment>
      <Row className=" justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <div className="bg-soft">
            <Row>
              <Col className="col-12">
                <div className="p-4 text-center">
                  <h5
                    style={{ color: "#ffffff", fontSize: "28px" }}
                  >{`LET'S STAY CONNECTED`}</h5>
                </div>
              </Col>
            </Row>
          </div>
          <Card className=" overflow-hidden">
            <CardBody className="pt-4 mx-4">
              <div className="mt-4 mb-4 text-center">
                <span>
                  <img
                    src={tick}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstep(1)
                    }}
                  />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={twoDark} alt="" />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={threeLight} alt="" />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={fourLight} alt="" />
                </span>
              </div>
              <div className="p-0">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <div className="mb-3">
                    <AvField
                      name="phoneNumber"
                      label="Phone Number*"
                      value={props.data ? props.data.phone : null}
                      className="form-control"
                      placeholder="Enter phone number"
                      type="text"
                      mask="+99999999999"
                      tag={[Input, InputMask]}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email Address*"
                      value={props.data ? props.data.email : null}
                      type="text"
                      required
                      placeholder="Enter email address"
                    />
                  </div>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-success btn-block"
                      style={{ background: "#76C4CC", borderColor: "#76C4CC" }}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </AvForm>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(Step2)

Step2.propTypes = {
  setstep: PropTypes.func,
  setdata: PropTypes.func,
  data: PropTypes.object,
}
