import React from 'react'
import { withRouter, Link, NavLink } from "react-router-dom"
export default function Footer() {
    return (
        <>
            <div className="main-footer">
                <div className='footer-bottom '>
                    <div className='d-flex justify-content-center container'>
                        <div className='hyper-link'>
                            <NavLink to={`/privacy`} target='_blank' rel="noopener noreferrer" className='me-5 text-white' href=''>TERMS AND CONDITIONS</NavLink>
                            <NavLink to={`/terms`} target='_blank' rel="noopener noreferrer" className='text-white ' href=''>PRIVACY POLICY</NavLink>
                        </div>
                    </div>
                </div>

                <div className='text-center footer-bottom2'>
                Copyright © 2024 TheConvenienceapp - All Rights Reserved.
                </div>
            </div>
        </>
    )
}