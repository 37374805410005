import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
// Redux
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { UncontrolledAlert, Container } from "reactstrap"
import Step1 from "./step1"
import Step2 from "./step2"
import Step3 from "./step3"
import Step4 from "./step4"
// import images
import logo from "../../../assets/images/logo-svg.svg"
import logoLightSvg from "../../../assets/images/logo-svg.svg"
import { useSelector, useDispatch } from "react-redux"
import { registerUser, changePreloader } from "../../../store/actions"

const Login = props => {
  const dispatch = useDispatch()
  const [step, setstep] = useState(1)
  const [data, setdata] = useState(null)
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertError, setAlertError] = useState(false)
  const { error, success, loading } = useSelector(state => ({
    error: state.Account.registrationError,
    success: state.Account.registrationSuccess,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    if (success) {
      setAlertSuccess(true)
      setTimeout(function () {
        setAlertSuccess(false)
      }, 5000)
      props.history.push("/myStore")
    }
    if (error) {
      setAlertError(true)
      setTimeout(function () {
        setAlertError(false)
      }, 5000)
    }
    if (loading) {
      dispatch(changePreloader(true))
    }
  }, [success, error, loading])

  const submitData = userData => {
    const user = { ...userData, user_type: 2 }
    try {
      dispatch(registerUser(user, props.history))
    } catch (e) {
      // console.log(e)
    }
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | The Convenience App</title>
      </MetaTags>
      <div
        style={{
          backgroundImage:
            "url(" +
            "https://ngineered.s3.us-west-2.amazonaws.com/login.jpg" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          height: "100vh",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Link to="/" className="logo logo-dark">
          <img src={logo} alt="" height="60" />
        </Link>

        <Link to="/" className="logo logo-light">
          <img src={logoLightSvg} alt="" height="60" />
        </Link>
        <div className="auth-full-bg my-5 pt-sm-5">
          <Container>
            {alertError ? (
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                {error}{" "}
              </UncontrolledAlert>
            ) : null}
            {alertSuccess ? (
              <UncontrolledAlert
                color="success"
                className="alert-dismissible fade show"
                role="alert"
              >
                Record saved successfully!
              </UncontrolledAlert>
            ) : null}
            {step === 1 && (
              <Step1 setstep={setstep} setdata={setdata} data={data} />
            )}
            {step === 2 && (
              <Step2 setstep={setstep} setdata={setdata} data={data} />
            )}
            {step === 3 && (
              <Step3 setstep={setstep} setdata={setdata} data={data} />
            )}
            {step === 4 && (
              <Step4
                setstep={setstep}
                setdata={setdata}
                submitData={submitData}
                data={data}
              />
            )}
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
