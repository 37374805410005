import {
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_ERROR,
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CHANGE_PASSWORD_ERROR,
  REQUEST_CHANGE_PASSWORD_SUCCESS,
  RESET_PASSWORD_CHANGE_STATE,
} from "./actionTypes"

export const passwordResetEmail = data => {
  return {
    type: PASSWORD_RESET_EMAIL,
    payload: { data },
  }
}

export const passwordResetEmailSuccess = data => {
  return {
    type: PASSWORD_RESET_EMAIL_SUCCESS,
    payload: data,
  }
}

export const passwordResetEmailError = error => {
  return {
    type: PASSWORD_RESET_EMAIL_ERROR,
    payload: error,
  }
}
export const requestChangePassword = (data, id) => {
  return {
    type: REQUEST_CHANGE_PASSWORD,
    payload: { data, id },
  }
}

export const requestChangePasswordSucces = data => {
  return {
    type: REQUEST_CHANGE_PASSWORD_SUCCESS,
    payload: data,
  }
}

export const requestChangePasswordError = error => {
  return {
    type: REQUEST_CHANGE_PASSWORD_ERROR,
    payload: error,
  }
}
export const resetPasswordChangeState = () => {
  return {
    type: RESET_PASSWORD_CHANGE_STATE,
  }
}
