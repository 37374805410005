import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SEARCH_ADDRESS,
  SEARCH_ADDRESS_SUCCESS,
  SEARCH_ADDRESS_ERROR
} from "./actionTypes"

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}

export const registerUserSuccessful = () => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const searchAddress = keyword => {
  return {
    type: SEARCH_ADDRESS,
    payload: { keyword },
  }
}

export const searchAddressSuccessful = addresses => {
  return {
    type: SEARCH_ADDRESS_SUCCESS,
    payload: addresses,
  }
}

export const searchAddressFailed = error => {
  return {
    type: SEARCH_ADDRESS_ERROR,
    payload: error,
  }
}