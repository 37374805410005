import React, { useEffect, useState } from "react"
// Redux
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Row, Col, CardBody, Card, Container, Alert } from "reactstrap"
// availity-reactstrap-validation
import { useSelector, useDispatch } from "react-redux"
import line from "../../../assets/images/register/line.png"
import tick from "../../../assets/images/register/tick.png"
import threeDark from "../../../assets/images/register/3-dark.png"
import fourLight from "../../../assets/images/register/4-light.png"
import Select from "react-select"
import { searchAddress } from "../../../store/actions"
import AutoSuggest from "react-autosuggest"
import "./style.css"
import { AvForm, AvField } from "availity-reactstrap-validation"

const Step3 = props => {
  const dispatch = useDispatch()
  const { addresses } = useSelector(state => ({
    addresses: state.Account.addresses,
  }))
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [placeId, setPlaceId] = useState("")
  const [warnAddress, setwarnAddress] = useState(false)

  const lowerCasedAddresses = addresses.map(address => {
    return {
      id: address.place_id,
      name: address.address.toLowerCase(),
    }
  })
  const handleValidSubmit = (event, values) => {
    if (placeId) {
      props.setdata({
        ...props.data,
        store_name: values.storeName,
        store_address_place_id: placeId,
      })
      props.setstep(4)
    } else {
      setwarnAddress(true)
      setTimeout(function () {
        setwarnAddress(false)
      }, 4000)
    }
  }
  function getSuggestions(value) {
    return lowerCasedAddresses.filter(address => {
      return address.name.includes(value.trim().toLowerCase())
    })
  }
  useEffect(() => {}, [addresses, suggestions, getSuggestions])

  return (
    <React.Fragment>
      <Row className=" justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <div className="bg-soft">
            <Row>
              <Col className="col-12">
                <div className="p-4 text-center">
                  <h5
                    style={{ color: "#ffffff", fontSize: "28px" }}
                  >{`STORE INFORMATION`}</h5>
                </div>
              </Col>
            </Row>
          </div>
          <Card className="">
            <CardBody className="pt-4 mx-4">
              <div className="mt-4 mb-4 text-center">
                <span>
                  <img
                    src={tick}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstep(1)
                    }}
                  />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img
                    src={tick}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstep(2)
                    }}
                  />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={threeDark} alt="" />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={fourLight} alt="" />
                </span>
              </div>
              <div className="p-0">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <div className="mb-3">
                    <AvField
                      name="storeName"
                      label="Store Name*"
                      value={props.data ? props.data.store_name : null}
                      className="form-control"
                      placeholder="Enter store name"
                      type="text"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label style={warnAddress ? { color: "red" } : null}>
                      Store Address*
                    </label>
                    <AutoSuggest
                      suggestions={suggestions}
                      onSuggestionsClearRequested={() => setSuggestions([])}
                      onSuggestionsFetchRequested={({ value }) => {
                        dispatch(searchAddress(value))
                        setValue(value)
                        setSuggestions(getSuggestions(value))
                      }}
                      onSuggestionSelected={(_, data) =>
                        setPlaceId(data.suggestion.id)
                      }
                      getSuggestionValue={suggestion => suggestion.name}
                      renderSuggestion={suggestion => (
                        <span>{suggestion.name}</span>
                      )}
                      inputProps={{
                        placeholder: "Type your address",
                        value: value,
                        onChange: (_, { newValue, method }) => {
                          setValue(newValue)
                        },
                      }}
                      className="form-control is-touched is-dirty av-invalid is-invalid form-control"
                      highlightFirstSuggestion={true}
                    />
                  </div>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-success btn-block"
                      style={{ background: "#76C4CC", borderColor: "#76C4CC" }}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </AvForm>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(Step3)

Step3.propTypes = {
  setstep: PropTypes.func,
  setdata: PropTypes.func,
  data: PropTypes.object,
}
