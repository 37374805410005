import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Row, Col } from "reactstrap"

import copy from "../../assets/images/store/copy.png"

import { useSelector } from "react-redux"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "../../helpers/utils"

const WorkingHours = props => {
  const { store, updateChanges, updateStoreStatus } = props
  const [clearActive, setClearActive] = useState(false)
  const [active, setactive] = useState()
  const [activeDays, setactiveDays] = useState([
    { day: "Sunday", disabled: false },
    { day: "Monday", disabled: false },
    { day: "Tuesday", disabled: false },
    { day: "Wednesday", disabled: false },
    { day: "Thursday", disabled: false },
    { day: "Friday", disabled: false },
    { day: "Saturday", disabled: false },
  ])
  const { success, error, loading } = useSelector(state => ({
    success: state.store.storeUpdateComplete,
    error: state.store.storeUpdateError,
    loading: state.store.storeUpdateLoading,
  }))

  useEffect(() => {
    success || error ? setClearActive(false) : null
    if (error && !loading) {
      resetChanges()
    }
  }, [success, error])

  const defaultValues = () => {
    setactive(store.is_active)
    const storeDays = activeDays
    activeDays.forEach(day => {
      let selectedDay = storeDays.find(d => d.day === day.day)
      let selectedDayIndex = storeDays.indexOf(selectedDay)
      if (store[day.day.substr(0, 3).toLowerCase() + "_open"]) {
        selectedDay["startDate"] =
          store[day.day.substr(0, 3).toLowerCase() + "_open"]
        selectedDay["endDate"] =
          store[day.day.substr(0, 3).toLowerCase() + "_close"]
        selectedDay["disabled"] = false
      } else {
        selectedDay["disabled"] = true
        selectedDay["startDate"] = ""
        selectedDay["endDate"] = ""
      }
      storeDays[selectedDayIndex] = selectedDay
    })
    setactiveDays([...storeDays])
  }

  useEffect(() => {
    defaultValues()
  }, [])

  const resetChanges = () => {
    defaultValues()
    setClearActive(false)
  }
  const changeTime = (value, day, type) => {
    let days = activeDays
    let selectedDay = days.find(d => d.day === day)
    let selectedDayIndex = days.indexOf(selectedDay)
    selectedDay[type === "start" ? "startDate" : "endDate"] = value
    days[selectedDayIndex] = selectedDay
    setactiveDays([...days])
    setClearActive(true)
  }
  const changeDayStatus = (value, day) => {
    let days = activeDays
    let selectedDay = days.find(d => d.day === day)
    let selectedDayIndex = days.indexOf(selectedDay)
    selectedDay["disabled"] = !value
    selectedDay["startDate"] = ""
    selectedDay["endDate"] = ""
    days[selectedDayIndex] = selectedDay
    setactiveDays([...days])
    setClearActive(true)
  }

  const copyTimeToAll = () => {
    const firstDay = activeDays.find(day => day.disabled === false)
    let newDays = []
    activeDays.forEach(day => {
      if (!day.disabled) {
        day["startDate"] = firstDay.startDate
        day["endDate"] = firstDay.endDate
      }
      newDays.push(day)
    })
    setactiveDays([...newDays])
  }

  const updateData = () => {
    let dataObj = {}
    activeDays.forEach(day => {
      if (!day.disabled) {
        if (day.startDate.length > 0 || day.endDate.length > 0) {
          dataObj[day.day.substr(0, 3).toLowerCase() + "_open"] = day.startDate
          dataObj[day.day.substr(0, 3).toLowerCase() + "_close"] = day.endDate
        } else {
          dataObj[day.day.substr(0, 3).toLowerCase() + "_open"] = null
          dataObj[day.day.substr(0, 3).toLowerCase() + "_close"] = null
          changeDayStatus(false, day.day)
        }
      } else {
        dataObj[day.day.substr(0, 3).toLowerCase() + "_open"] = null
        dataObj[day.day.substr(0, 3).toLowerCase() + "_close"] = null
      }
    })
    dataObj["native_timezone"] = "us/eastern"
    updateChanges(dataObj)
  }

  return (
    <React.Fragment>
      {" "}
      <Row>
        <Col sm="12">
          <Row className="mb-1 mt-4">
            <h6 className="col">
              Configure the standard working hours for your store.
            </h6>
            <Col
              style={{
                textAlign: "end",
              }}
            >
              Store Active
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#76C4CC"
                className="ms-1"
                onChange={() => {
                  setClearActive(true)
                  updateStoreStatus({ is_active: !active })
                  setactive(!active)
                }}
                checked={active}
              />
            </Col>
            <Col xl="1"></Col>
          </Row>
          <Row className="mt-3" style={{ display: "flex" }}>
            <Col lg="7" sm="12" md="8">
              {activeDays.map((day, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Row className="mt-3" style={{ display: "flex" }}>
                    <Col sm="3">
                      <div
                        style={{
                          marginTop: "10px",
                          marginRight: "20px",
                          width: "150px",
                        }}
                      >
                        <label
                          htmlFor="sundayTime"
                          style={{ color: "#898989" }}
                        >
                          {day.day}
                        </label>
                      </div>
                    </Col>
                    <Col sm="2" className="px-0 mx-0">
                      <div className="form-check form-switch form-switch-lg mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizelg"
                          checked={!day.disabled}
                          onChange={e =>
                            changeDayStatus(e.target.checked, day.day)
                          }
                          style={{
                            backgroundColor:
                              day && day.disabled ? "#ffffff" : "#76C4CC",
                            borderColor: "#76C4CC",
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm="3" className="px-0 mx-0">
                      <input
                        className="form-control px-2"
                        type="time"
                        disabled={day.disabled}
                        value={
                          activeDays.length > 0 &&
                          activeDays.find(d => d.day === day.day) &&
                          activeDays.find(d => d.day === day.day).startDate
                            ? activeDays.find(d => d.day === day.day).startDate
                            : ""
                        }
                        id={day.day + "-start"}
                        style={{ maxWidth: "120px", minWidth: "100px" }}
                        onChange={e =>
                          changeTime(e.target.value, day.day, "start")
                        }
                      />
                    </Col>
                    <Col xs="1" className="px-0 mx-0">
                      <div
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        <label
                          htmlFor="sundayTime"
                          style={{ color: "#898989" }}
                        >
                          to
                        </label>
                      </div>
                    </Col>
                    <Col sm="3" className="px-0 mx-0">
                      <input
                        className="form-control px-2"
                        type="time"
                        disabled={day.disabled}
                        value={
                          activeDays.length > 0 &&
                          activeDays.find(d => d.day === day.day) &&
                          activeDays.find(d => d.day === day.day).endDate
                            ? activeDays.find(d => d.day === day.day).endDate
                            : ""
                        }
                        id={day.day + "-end"}
                        style={{ maxWidth: "120px", minWidth: "100px" }}
                        onChange={e =>
                          changeTime(e.target.value, day.day, "end")
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
            <Col lg="5" md="4" sm="12">
              <div className="form-check mb-3" style={{ marginTop: "20px" }}>
                <label
                  style={{ whiteSpace: "nowrap" }}
                  className="form-check-label"
                  onClick={e => copyTimeToAll()}
                >
                  Copy time to all days <img src={copy} width="24px" />
                </label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <Col xl={6}></Col>
        <Col xl={6}>
          <label
            className="btn btn-success float-end ms-3"
            style={{ background: "#76C4CC", width: "150px", height: "35px" }}
          >
            {loading ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
            ) : null}
            Save Changes
            <input
              type="button"
              style={{ display: "none" }}
              onClick={() => (clearActive ? updateData() : null)}
            />
          </label>
          {clearActive && (
            <label
              className="btn btn-success float-end"
              style={{
                background: "#ffffff",
                borderColor: "#76C4CC",
                color: "#76C4CC",
                width: "150px",
                height: "35px",
              }}
            >
              Reset
              <input
                type="button"
                style={{ display: "none" }}
                onClick={() => resetChanges()}
              />
            </label>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WorkingHours

WorkingHours.propTypes = {
  store: PropTypes.object,
  updateChanges: PropTypes.func,
  updateStoreStatus: PropTypes.func,
}
