import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_ERROR,
  SAVE_PRODUCT,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,
  UPDATE_PRODUCT_PHOTO,
  UPDATE_PRODUCT_PHOTO_SUCCESS,
  UPDATE_PRODUCT_PHOTO_ERROR,
  RESET_PRODUCT_STATE,
  RESET_PRODUCTS_STATE,
} from "./actionTypes"

export const fetchTags = () => {
  return {
    type: FETCH_TAGS,
  }
}
export const fetchTagsSuccess = tags => {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: tags,
  }
}
export const fetchTagsError = error => {
  return {
    type: FETCH_TAGS_ERROR,
    payload: error,
  }
}

export const fetchProducts = (offset, search) => {
  return {
    type: FETCH_PRODUCTS,
    payload: { offset, search },
  }
}
export const fetchProductsSuccess = products => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products,
  }
}
export const fetchProductsError = error => {
  return {
    type: FETCH_PRODUCTS_ERROR,
    payload: error,
  }
}

export const fetchProduct = id => {
  return {
    type: FETCH_PRODUCT,
    payload: { id },
  }
}
export const fetchProductSuccess = product => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: product,
  }
}
export const fetchProductError = error => {
  return {
    type: FETCH_PRODUCT_ERROR,
    payload: error,
  }
}

export const saveProduct = data => {
  return {
    type: SAVE_PRODUCT,
    payload: { data },
  }
}
export const saveProductSuccess = product => {
  return {
    type: SAVE_PRODUCT_SUCCESS,
    payload: product,
  }
}
export const saveProductError = error => {
  return {
    type: SAVE_PRODUCT_ERROR,
    payload: error,
  }
}

export const updateProduct = (data, id) => {
  return {
    type: UPDATE_PRODUCT,
    payload: { data, id },
  }
}
export const updateProductSuccess = product => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
    payload: product,
  }
}
export const updateProductError = error => {
  return {
    type: UPDATE_PRODUCT_ERROR,
    payload: error,
  }
}
export const updateProductPhoto = (data, id) => {
  return {
    type: UPDATE_PRODUCT_PHOTO,
    payload: { data, id },
  }
}
export const updateProductPhotoSuccess = product => {
  return {
    type: UPDATE_PRODUCT_PHOTO_SUCCESS,
    payload: product,
  }
}
export const updateProductPhotoError = error => {
  return {
    type: UPDATE_PRODUCT_PHOTO_ERROR,
    payload: error,
  }
}
export const resetProductState = () => {
  return {
    type: RESET_PRODUCT_STATE,
  }
}
