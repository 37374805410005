import React, { useEffect, useState } from "react"
// Redux
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Row, Col, CardBody, Card, Container, Alert } from "reactstrap"
// availity-reactstrap-validation
import { useSelector, useDispatch } from "react-redux"
import line from "../../../assets/images/register/line.png"
import oneDark from "../../../assets/images/register/1-dark.png"
import twoLight from "../../../assets/images/register/2-light.png"
import threeLight from "../../../assets/images/register/3-light.png"
import fourLight from "../../../assets/images/register/4-light.png"

import { AvForm, AvField } from "availity-reactstrap-validation"

const Step1 = props => {
  const handleValidSubmit = (event, values) => {
    props.setdata({
      ...props.data,
      first_name: values.firstName,
      last_name: values.lastName,
    })
    props.setstep(2)
  }
  return (
    <React.Fragment>
      <Row className=" justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <div className="bg-soft">
            <Row>
              <Col className="col-12">
                <div className="p-4 text-center">
                  <h5 style={{ color: "#ffffff", fontSize: "28px" }}>
                    WELCOME TO CONVENIENCE APP FOR STORES
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
          <Card className=" overflow-hidden">
            <CardBody className="pt-4 mx-4">
              <div className="mt-4 mb-4 text-center">
                <span>
                  <img src={oneDark} alt="" />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={twoLight} alt="" />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={threeLight} alt="" />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={fourLight} alt="" />
                </span>
              </div>
              <div className="p-0">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <div className="mb-3">
                    <AvField
                      name="firstName"
                      label="First Name*"
                      value={props.data ? props.data.first_name : null}
                      className="form-control"
                      placeholder="Enter first name"
                      type="text"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="lastName"
                      label="Last Name*"
                      value={props.data ? props.data.last_name : null}
                      type="text"
                      required
                      placeholder="Enter last name"
                    />
                  </div>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-success btn-block"
                      style={{ background: "#76C4CC", borderColor: "#76C4CC" }}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </AvForm>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(Step1)

Step1.propTypes = {
  setstep: PropTypes.func,
  setdata: PropTypes.func,
  data: PropTypes.object,
}
