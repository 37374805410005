import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SEARCH_ADDRESS,
  SEARCH_ADDRESS_SUCCESS,
  SEARCH_ADDRESS_ERROR
} from "./actionTypes"

const initialState = {
  registrationError: null,
  registrationSuccess:false,
  message: null,
  loading: false,
  user: null,
  addresses:[],
  searchError:null
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        registrationSuccess:true,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationSuccess:false,
        registrationError: action.payload,
      }
      break
    case SEARCH_ADDRESS:
      state = {
        ...state,
        searchError: null,
      }
      break
    case SEARCH_ADDRESS_SUCCESS:
      state = {
        ...state,
        addresses: action.payload,
        searchError: null,
      }
      break
    case SEARCH_ADDRESS_ERROR:
      state = {
        ...state,
        addresses: null,
        searchError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
