import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import stripeImg from "../../assets/images/store/stripe.png"
import openLink from "../../assets/images/financials/openLink.png"
const Financials = props => {
  const linkStripe = "https://dashboard.stripe.com/login"
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Financials | The Convenience App</title>
        </MetaTags>
        <Container fluid>
          <h4>Financials</h4>
          <label
            className="btn btn-success m-1"
            style={{
              background: "#76C4CC",
              borderColor: "#76C4CC",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            <img src={stripeImg} width="26px" className="me-1" />
            Open Stripe
            <img src={openLink} width="12px" className="ms-2" />
            <input
              type="button"
              style={{ display: "none" }}
              onClick={() => window.open(linkStripe, "_blank")}
            />
          </label>
          {/* <div className="row" style={{ height: "100vh" }}>
            <iframe
              className="col-12"
              src={linkStripe}
              title="Stripe Payments"
            ></iframe>
          </div> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Financials
