import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import PropTypes from "prop-types"
import queryString from "query-string"
import UserInfo from "./userInfo"
//redux
import { useSelector, useDispatch } from "react-redux"
import {
  fetchProfile,
  editProfile,
  resetProfileState,
  requestUpdatePassword,
} from "../../store/actions"
import ChangePassword from "./changePassword"

const MyStore = props => {
  const dispatch = useDispatch()
  const { profile, success, error, loading } = useSelector(state => ({
    success: state.Profile.fetchProfileSuccess,
    error: state.Profile.fetchProfileError,
    profile: state.Profile.user,
    loading: state.Profile.fetchProfileLoading,
  }))
  useEffect(() => {
    return () => {
      dispatch(resetProfileState())
    }
  }, [])
  useEffect(() => {
    if (!success && !loading) {
      try {
        dispatch(fetchProfile())
      } catch (e) {}
    }
  }, [profile, success, error])

  const updateChanges = data => {
    dispatch(editProfile(data))
  }

  const updatePassword = data => {
    dispatch(requestUpdatePassword(data, profile.pk))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>My Profile | The Convenience App</title>
        </MetaTags>
        <Container fluid>
          <h4>My Profile</h4>
          {profile && (
            <UserInfo profile={profile} updateChanges={updateChanges} />
          )}
          {profile && (
            <ChangePassword profile={profile} updatePassword={updatePassword} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyStore

MyStore.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}
