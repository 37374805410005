import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_SUCCESS,
  REQUEST_UPDATE_PASSWORD,
  REQUEST_UPDATE_PASSWORD_SUCCESS,
  REQUEST_UPDATE_PASSWORD_ERROR,
  RESET_PROFILE_STATE,
} from "./actionTypes"

const initialState = {
  error: null,
  complete: false,
  loading: false,
  user: null,
  fetchProfileError: null,
  fetchProfileSuccess: false,
  fetchProfileLoading: false,
  passwordUpdate: null,
  passwordUpdateSuccess: false,
  passwordUpdateLoading: false,
  passwordUpdateError: null,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, complete: true, loading: true, error: null }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        complete: true,
        loading: false,
        error: null,
      }
      break
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload,
        complete: true,
        loading: false,
      }
      break
    case FETCH_PROFILE:
      state = {
        ...state,
        fetchProfileSuccess: true,
        fetchProfileLoading: true,
        fetchProfileError: null,
      }
      break
    case FETCH_PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        fetchProfileSuccess: true,
        fetchProfileLoading: false,
        fetchProfileError: null,
      }
      break
    case FETCH_PROFILE_ERROR:
      state = {
        ...state,
        fetchProfileError: action.payload,
        fetchProfileSuccess: true,
        fetchProfileLoading: false,
      }
      break
    case REQUEST_UPDATE_PASSWORD:
      state = {
        ...state,
        passwordUpdateLoading: true,
        passwordUpdateSuccess: false,
        passwordUpdateError: null,
      }
      break
    case REQUEST_UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        passwordUpdateLoading: false,
        passwordUpdateSuccess: true,
        passwordUpdate: action.payload,
        passwordUpdateError: null,
      }
      break
    case REQUEST_UPDATE_PASSWORD_ERROR:
      state = {
        ...state,
        passwordUpdateLoading: false,
        passwordUpdateSuccess: true,
        passwordUpdateError: action.payload,
      }
      break
    case RESET_PROFILE_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
