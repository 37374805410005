import {
  FETCH_STORE,
  FETCH_STORE_SUCCESS,
  FETCH_STORE_ERROR,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_ERROR,
  UPLOAD_STORE_PHOTO,
  UPLOAD_STORE_PHOTO_SUCCESS,
  UPLOAD_STORE_PHOTO_ERROR,
  RESET_STORE_STATE,
} from "./actionTypes"

const initialState = {
  storeFetchError: null,
  storeFetchSuccess: false,
  storeFetchLoading: false,
  storeUpdateError: null,
  storeUpdateComplete: false,
  storeUpdateLoading: false,
  store: null,
  storePhotoUploadError: null,
  storePhotoUploadComplete: false,
  storePhotoUploadLoading: false,
}

const store = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORE:
      state = {
        ...state,
        storeFetchLoading: true,
        storeFetchSuccess: false,
        storeFetchError: null,
      }
      break
    case FETCH_STORE_SUCCESS:
      state = {
        ...state,
        storeFetchLoading: false,
        storeFetchSuccess: true,
        store: action.payload,
        storeFetchError: null,
      }
      break
    case FETCH_STORE_ERROR:
      state = {
        ...state,
        storeFetchLoading: false,
        storeFetchSuccess: true,
        storeFetchError: action.payload,
      }
      break
    case UPDATE_STORE:
      state = {
        ...state,
        storeUpdateLoading: true,
        storeUpdateComplete: false,
        storeUpdateError: null,
      }
      break
    case UPDATE_STORE_SUCCESS:
      state = {
        ...state,
        storeUpdateLoading: false,
        storeUpdateComplete: true,
        store: action.payload,
        storeUpdateError: null,
      }
      break
    case UPDATE_STORE_ERROR:
      state = {
        ...state,
        storeUpdateLoading: false,
        storeUpdateComplete: true,
        storeUpdateError: action.payload,
      }
      break
    case UPLOAD_STORE_PHOTO:
      state = {
        ...state,
        storePhotoUploadLoading: true,
        storePhotoUploadComplete: false,
        storePhotoUploadError: null,
      }
      break
    case UPLOAD_STORE_PHOTO_SUCCESS:
      state = {
        ...state,
        storePhotoUploadLoading: false,
        storePhotoUploadComplete: true,
        storePhotoUploadError: null,
      }
      break
    case UPLOAD_STORE_PHOTO_ERROR:
      state = {
        ...state,
        storePhotoUploadLoading: false,
        storePhotoUploadComplete: true,
        storePhotoUploadError: action.payload,
      }
      break
    case RESET_STORE_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default store
