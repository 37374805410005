import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
// Redux
import { withRouter, Link, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Row, Col, CardBody, Card, Container, Alert } from "reactstrap"
// import images
import logo from "../../assets/images/logo-svg.svg"
import logoLightSvg from "../../assets/images/logo-svg.svg"
import { useSelector, useDispatch } from "react-redux"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  requestChangePassword,
  resetPasswordChangeState,
} from "../../store/actions"
import sentImg from "../../assets/images/register/sent.png"

const ForgotPassword = props => {
  const dispatch = useDispatch()
  let { uid, token } = useParams()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [sent, setsent] = useState(false)
  const [notSent, setnotSent] = useState(false)
  const [errorCheck, setErrorCheck] = useState("")
  const { error, success, loading } = useSelector(state => ({
    error: state.ResetPassword.passwordChangeError,
    success: state.ResetPassword.passwordChangeSuccess,
    loading: state.ResetPassword.passwordChangeLoading,
  }))
  const re = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  )
  useEffect(() => {
    if (success && !loading && !error) {
      setsent(true)
      dispatch(resetPasswordChangeState())
    }
    if (error && !loading) {
      setnotSent(true)
      setTimeout(function () {
        setnotSent(false)
      }, 5000)
    }
  }, [success, loading, error])
  const valid =
    "Password must contain 8 characters including a special character"
  const validatePassword = (password1, password2) => {
    if (password1 === "" || password2 === "") {
      setErrorCheck("Password field should not be empty!")
      return false
    }
    if (password1 !== password2) {
      setErrorCheck("Passwords do not match!")
      return false
    }
    return true
  }

  const handleValidSubmit = (event, values) => {
    if (validatePassword(values.password, values.confirmPassword)) {
      try {
        const data = {
          uid,
          token,
          new_password1: values.password,
          new_password2: values.confirmPassword,
        }
        dispatch(requestChangePassword(data))
      } catch (e) {
        // console.log(e)
      }
      setErrorCheck("")
    }
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | The Convenience App</title>
      </MetaTags>
      <div
        style={{
          backgroundImage:
            "url(" +
            "https://ngineered.s3.us-west-2.amazonaws.com/login.jpg" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          height: "100vh",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Link to="/" className="logo logo-dark">
          <img src={logo} alt="" height="60" />
        </Link>

        <Link to="/" className="logo logo-light">
          <img src={logoLightSvg} alt="" height="60" />
        </Link>
        <div className="auth-full-bg my-5 pt-sm-5">
          <Container>
            <Row className=" justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div className="p-4 text-center">
                        <h5
                          style={{ color: "#ffffff", fontSize: "28px" }}
                        >{`CREATE NEW PASSWORD`}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Card className=" overflow-hidden">
                  {!sent ? (
                    <CardBody className="pt-2 mx-4 my-4">
                      {notSent || errorCheck ? (
                        <Alert color="danger">
                          {errorCheck != "" ? errorCheck : error}
                        </Alert>
                      ) : null}
                      <div className="pt-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="New Password*"
                              value={password}
                              className="form-control"
                              placeholder="Enter password"
                              type="text"
                              onChange={e => {
                                setPassword(e.target.value)
                              }}
                              required
                            />
                            <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                              {password !== "" && !re.test(password) && valid}
                            </span>
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="confirmPassword"
                              label="Confirm Password*"
                              value={confirmPassword}
                              className="form-control"
                              placeholder="Confirm Password"
                              type="text"
                              onChange={e => {
                                setConfirmPassword(e.target.value)
                              }}
                              required
                            />
                            <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                              {confirmPassword !== "" &&
                                !re.test(confirmPassword) &&
                                valid}
                            </span>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-success btn-block"
                              style={{ background: "#76C4CC" }}
                              type="submit"
                            >
                              {loading ? (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                              ) : null}
                              Change Password
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody className="pt-4 mx-4 my-4 text-center">
                      <h4>Password Changed Successfully!</h4>
                      <div className="mt-4 mb-5 text-center">
                        <img src={sentImg} alt="" />
                      </div>
                      <Link to="/">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-success btn-block"
                            style={{ background: "#76C4CC" }}
                            type="submit"
                          >
                            Return to Login
                          </button>
                        </div>
                      </Link>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgotPassword)

ForgotPassword.propTypes = {
  history: PropTypes.object,
}
