//SEARCH ADDRESSES
export const SEARCH_ADDRESSES_URL = "/merchant/api/v1/addresses"
export const REGISTER_USER_URL = "/merchant/api/v1/auth/registration/"
export const LOGIN_USER_URL = "/merchant/api/v1/auth/login/"
export const PASSWORD_UPDATE_URL = "/merchant/api/v1/auth/password/change/"
export const STORE_URL = "/merchant/api/v1/stores/my/"
export const ONBOARDING_LINK_URL = "/merchant/api/v1/stores/my/onboarding-links/"
export const PROFILE_URL = "/merchant/api/v1/auth/user/"
export const FETCH_TAGS_URL = "/merchant/api/v1/tags/"
export const PRODUCTS_URL = "/merchant/api/v1/stores/my/products/"
export const ORDERS_LIST_URL = "/merchant/api/v1/orders/"
export const REQUEST_CHANGE_PASSWORD_URL =
  "/password-reset/confirm/<uidb64>/<token>/"
export const EMAIL_RESET_PASSWORD_URL = "/password-reset/"
