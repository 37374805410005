import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Board, { moveCard } from "@lourenci/react-kanban"
import {
  Container,
  Row,
  Col,
  CardTitle,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  UncontrolledAlert,
} from "reactstrap"
import OrderDetails from "../../pages/Order/orderDetails"
import CardTaskBox from "../../components/Orders/CardTaskBox"
import removeBlack from "../../assets/images/inventory/remove.png"
import filter from "../../assets/images/orders/filter.png"
//Skeleton
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
//redux
import { useSelector, useDispatch } from "react-redux"
import { getOrdersList, updateOrderStatus } from "../../store/actions"

const Orders = props => {
  const dispatch = useDispatch()

  const {
    orders,
    success,
    error,
    loading,
    updateOrderStatusLoading,
    updateOrderStatusError,
    updateOrderStatusSuccess,
    updateOrderItemQuantitySuccess,
    updateOrderItemQuantityError,
    updateOrderItemQuantityLoading,
  } = useSelector(state => ({
    success: state.orders.getOrdersListComplete,
    error: state.orders.getOrdersListError,
    orders: state.orders.ordersList,
    loading: state.orders.getOrdersListLoading,
    updateOrderStatusSuccess: state.orders.updateOrderStatusSuccess,
    updateOrderStatusError: state.orders.updateOrderStatusError,
    updateOrderStatusLoading: state.orders.updateOrderStatusLoading,
    updateOrderItemQuantitySuccess: state.orders.updateOrderItemQuantitySuccess,
    updateOrderItemQuantityError: state.orders.updateOrderItemQuantityError,
    updateOrderItemQuantityLoading: state.orders.updateOrderItemQuantityLoading,
  }))
  const [orderResult, setOrderResult] = useState()
  const [mapResult, setMapResult] = useState()
  const [searchTerm, setSearchTerm] = useState("")
  const [board, setBoard] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [btnFilter, setBtnFilter] = useState(false)
  const [inStoreFilter, setInStoreFilter] = useState(false)
  const [curbsideFilter, setCurbsideFilter] = useState(false)
  const [isCurbside, setIsCurbside] = useState(null)

  const [orderDetailModel, setOrderDetailModel] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const toggleViewModal = () => setOrderDetailModel(!orderDetailModel)
  //for checkedin alert color
  const [checkedIn, setCheckedIn] = useState(false)
  const toggleCheckedIn = () => setCheckedIn(!checkedIn)
  const [getData, setGetData] = useState(false)

  useEffect(() => {
    let timer = setTimeout(() => {
      toggleCheckedIn()
    }, [1000 * 1])
    return () => {
      clearTimeout(timer)
    }
  }, [checkedIn])

  useEffect(() => {
    let timer = setTimeout(() => {
      // console.log("im here 5",isCurbside)
      setGetData(!getData)
      dispatch(getOrdersList(searchTerm, isCurbside))
    }, [1000 * 20])
    return () => {
      clearTimeout(timer)
    }
  }, [getData])

  useEffect(() => {
    if (!success && !loading) {
      dispatch(getOrdersList(searchTerm, isCurbside))
    }
  }, [orders, success, error])
  //filter
  useEffect(() => {
    if (success && !loading) {
      dispatch(getOrdersList(searchTerm, isCurbside))
    }
  }, [isCurbside])

  useEffect(() => {
    if (
      (updateOrderStatusSuccess || updateOrderStatusError) &&
      !updateOrderStatusLoading
    ) {
      dispatch(getOrdersList(searchTerm, isCurbside))
      setOrderDetailModel(false)
    }
  }, [updateOrderStatusSuccess, updateOrderStatusError])

  useEffect(() => {
    if (
      (updateOrderItemQuantitySuccess || updateOrderItemQuantityError) &&
      !updateOrderItemQuantityLoading
    ) {
      dispatch(getOrdersList(searchTerm, isCurbside))
    }
  }, [updateOrderItemQuantitySuccess, updateOrderItemQuantityError])

  const updateStatus = (stat, id) => {
    const data = {
      status: stat,
    }
    dispatch(updateOrderStatus(data, id))
  }

  useEffect(() => {
    if (orders != null) {
      setOrderResult(orders.results)
    }
  }, [orders])

  useEffect(() => {
    if (orderResult != null) {
      if (curbsideFilter && inStoreFilter) {
        // console.log("im here 1")
        setGetData(!getData)
        setIsCurbside(null)
      } else if (curbsideFilter && !inStoreFilter) {
        // console.log("im here 2")
        setGetData(!getData)
        setIsCurbside(true)
      } else if (inStoreFilter && !curbsideFilter) {
        // console.log("im here 3")
        setGetData(!getData)
        setIsCurbside(false)
      } else {
        // console.log("im here 4")
        setGetData(!getData)
        setIsCurbside(null)
      }
    }
  }, [inStoreFilter, curbsideFilter])

  useEffect(() => {
    if (orderResult) {
      setMapResult(orderResult)
    }
  }, [orderResult])

  useEffect(() => {
    if (mapResult != null) {
      let mapped = {
        columns: [
          {
            id: 1,
            title: "New",
            cards: mapResult.filter(order => {
              if (order.status == 20) {
                return order
              }
            }),
          },
          {
            id: 2,
            title: "Accepted",
            cards: mapResult
              .filter(order => {
                if (order.status == 30) {
                  return order
                }
              })
              .sort(function (a, b) {
                return b.has_checked_in - a.has_checked_in
              }),
          },
          {
            id: 3,
            title: "Packed",
            cards: mapResult
              .filter(order => {
                if (order.status == 40) {
                  return order
                }
              })
              .sort(function (a, b) {
                return b.has_checked_in - a.has_checked_in
              }),
          },
          {
            id: 4,
            title: "Completed",
            cards: mapResult.filter(order => {
              var date = new Date(order.updated_at)
              var timeStamp = Math.round(new Date().getTime() / 1000)
              var timeStampYesterday = timeStamp - 24 * 3600
              var is24 = date >= new Date(timeStampYesterday * 1000).getTime()
              if (order.status == 50) {
                return order
              } else if (order.status == 60) {
                if (is24) {
                  return order
                }
              }
            }),
          },
        ],
      }
      setBoard(mapped)
    }
  }, [mapResult])

  useEffect(() => {
    if (showAlert) {
      {
        setTimeout(function () {
          setShowAlert(false)
        }, 5000)
      }
    }
  }, [showAlert])
  const skeletonBoard = () => {
    return (
      <Row className="mx-4">
        <Col>
          <Skeleton height="100px" />
        </Col>
        <Col>
          <Skeleton height="100px" />
        </Col>
        <Col>
          <Skeleton height="100px" />
        </Col>
        <Col>
          <Skeleton height="100px" />
        </Col>
      </Row>
    )
  }

  return (
    <React.Fragment>
      <OrderDetails
        isOpen={orderDetailModel}
        screen="orders"
        data={currentData}
        toggle={toggleViewModal}
      />
      <div className="page-content">
        <MetaTags>
          <title>Orders | The Convenience App</title>
        </MetaTags>
        <Container fluid>
          <Row className="mb-4">
            <Col>
              <h4>
                Current Orders
                {updateOrderStatusLoading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2" />
                ) : null}
              </h4>
              <Row className="my-1 p-0">
                <Col className="col-12 p-0">
                  <div style={{ display: "flex" }} className="px-4 float-end">
                    <Dropdown
                      isOpen={btnFilter}
                      toggle={() => setBtnFilter(!btnFilter)}
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn  me-2"
                        style={{
                          whiteSpace: "nowrap",
                          border: "1px solid #76C4CC",
                          borderRadius: "4px",
                          backgroundColor: "#F4FAFB",
                        }}
                      >
                        <img src={filter} width="16px" className="me-2" />
                        Filter
                        {inStoreFilter ? (
                          <Badge
                            className={"badge-soft-secondary py-2 px-2 ms-2"}
                          >
                            In-Store pickup
                            <img
                              src={removeBlack}
                              style={{
                                width: "10px",
                                marginLeft: "5px",
                              }}
                              onClick={e => {
                                e.stopPropagation()
                                setBtnFilter(false)
                                setInStoreFilter(false)
                              }}
                            />
                          </Badge>
                        ) : null}
                        {curbsideFilter ? (
                          <Badge
                            className={"badge-soft-secondary py-2 px-2 ms-2"}
                          >
                            Curbside Delivery
                            <img
                              src={removeBlack}
                              style={{
                                width: "10px",
                                marginLeft: "5px",
                              }}
                              onClick={e => {
                                e.stopPropagation()
                                setBtnFilter(false)
                                setCurbsideFilter(false)
                              }}
                            />
                          </Badge>
                        ) : null}
                      </DropdownToggle>
                      <DropdownMenu>
                        <div className="mb-0 ms-3 me-4">
                          <p className="m-2">Type</p>
                          <div style={{ display: "grid" }} className="m-2">
                            <div
                              className="form-check"
                              onChange={() => setInStoreFilter(!inStoreFilter)}
                            >
                              <input
                                type="checkbox"
                                defaultChecked={inStoreFilter}
                                className="form-check-input"
                                id="inStore"
                              />
                              <label
                                className="form-check-label"
                                style={{ whiteSpace: "nowrap" }}
                                htmlFor="inStore"
                              >
                                In-store Pickup
                              </label>
                            </div>

                            <div
                              className="form-check"
                              onChange={() =>
                                setCurbsideFilter(!curbsideFilter)
                              }
                            >
                              <input
                                type="checkbox"
                                defaultChecked={curbsideFilter}
                                className="form-check-input"
                                id="curbside"
                              />
                              <label
                                className="form-check-label"
                                style={{ whiteSpace: "nowrap" }}
                                htmlFor="curbside"
                              >
                                Curbside Delivery
                              </label>
                            </div>
                          </div>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                    <input
                      type="search"
                      placeholder="Search by order # or User Name"
                      className="m-0 px-3 font-size-12"
                      value={searchTerm}
                      style={{
                        borderRadius: 5,
                        borderWidth: 0,
                        width: "250px",
                        height: "40px",
                        marginRight: 10,
                      }}
                      onChange={() => {
                        let value = event.target.value.toLowerCase()
                        setSearchTerm(value)
                        dispatch(getOrdersList(value, isCurbside))
                      }}
                    />
                  </div>
                </Col>
              </Row>

              {showAlert || updateOrderStatusError ? (
                <UncontrolledAlert
                  color="danger"
                  onClose={() => setShowAlert(false)}
                  dismissible="true"
                  className="mx-2 my-2"
                >
                  <p className="mb-0">
                    <i className="mdi mdi-block-helper me-2"></i>
                    {updateOrderStatusError
                      ? updateOrderStatusError
                      : "You can only move cards to next adjacent column on the board"}
                  </p>
                </UncontrolledAlert>
              ) : null}
              <Row className="m-0 p-0">
                <Col className="col-12 m-0 p-0">
                  {success && board ? (
                    <Board
                      disableColumnDrag
                      cardDraggable
                      renderColumnHeader={({ title, cards }) => (
                        <>
                          <CardTitle
                            className="mb-4"
                            style={{ minWidth: "260px" }}
                          >
                            {title}
                            <span
                              className="ms-2"
                              style={{ fontWeight: "normal" }}
                            >
                              {cards.length}
                            </span>
                          </CardTitle>
                        </>
                      )}
                      renderCard={(data, { removeCard, dragging }) => (
                        <CardTaskBox
                          data={data}
                          removeCard={removeCard}
                          dragging={dragging}
                          dispatch={dispatch}
                          checkedIn={checkedIn}
                        >
                          {data}
                        </CardTaskBox>
                      )}
                      onCardDragEnd={(_card, source, destination) => {
                        setShowAlert(false)

                        if (
                          (source.fromColumnId == 1 &&
                            destination.toColumnId != 2) ||
                          (source.fromColumnId == 2 &&
                            destination.toColumnId != 3) ||
                          (source.fromColumnId == 3 &&
                            destination.toColumnId != 4) ||
                          source.fromColumnId == 4
                        ) {
                          setShowAlert(true)
                        } else {
                          let check = false
                          if (
                            source.fromColumnId == 1 &&
                            destination.toColumnId == 2
                          ) {
                            updateStatus(30, _card.id)
                          } else if (
                            source.fromColumnId == 2 &&
                            destination.toColumnId == 3
                          ) {
                            setCurrentData(_card)
                            toggleViewModal()
                            check = true
                            //updateStatus(40, _card.id)
                          } else if (
                            source.fromColumnId == 3 &&
                            destination.toColumnId == 4
                          ) {
                            updateStatus(50, _card.id)
                          } else if (source.fromColumnId == 4) {
                          } else {
                            setShowAlert(true)
                          }
                          !check
                            ? setBoard(moveCard(board, source, destination))
                            : null
                        }
                      }}
                    >
                      {board}
                    </Board>
                  ) : (
                    skeletonBoard()
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Orders
