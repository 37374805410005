import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import Profile from "./auth/profile/reducer"
import ResetPassword from "./auth/forgotPassword/reducer"

import store from "./store/reducer"

import products from "./products/reducer"
import orders from "./orders/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Profile,
  store,
  products,
  orders,
  ResetPassword,
})

export default rootReducer
