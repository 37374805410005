import React, { useEffect, useState } from "react"
// Redux
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import {
  Row,
  Col,
  CardBody,
  Card,
  InputGroup,
  Alert,
  FormGroup,
  Label,
} from "reactstrap"

//import ReactPasswordToggleIcon from "react-password-toggle-icon";

const ChangePassword = props => {
  const { profile, updatePassword } = props
  const [error, setError] = useState("")
  const [oldPassword, setoldPassword] = useState("")
  const [password1, setpassword1] = useState("")
  const [password2, setpassword2] = useState("")
  const [oldpasswordType, setoldpasswordType] = useState(false)
  const [password1Type, setpassword1Type] = useState(false)
  const [password2Type, setpassword2Type] = useState(false)
  const [clearActive, setClearActive] = useState(false)
  const re = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  )

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertError, setAlertError] = useState(false)

  const { passwordUpdateSuccess, passwordUpdateLoading, passwordUpdateError } =
    useSelector(state => ({
      passwordUpdateSuccess: state.Profile.passwordUpdateSuccess,
      passwordUpdateLoading: state.Profile.passwordUpdateLoading,
      passwordUpdateError: state.Profile.passwordUpdateError,
    }))
  const defaultValues = () => {
    setoldPassword("")
    setpassword1("")
    setpassword2("")
  }
  useEffect(() => {
    defaultValues()
  }, [profile])

  const resetChanges = () => {
    defaultValues()
    setClearActive(false)
  }
  const updateData = () => {
    if (validatePassword()) {
      const data = {
        old_password: oldPassword,
        new_password1: password1,
        new_password2: password2,
      }
      updatePassword(data)
      setError("")
    }
  }
  const validatePassword = () => {
    if (password1 === "" || password2 === "") {
      setError("Password field should not be empty!")
      return false
    }
    if (password1 !== password2) {
      setError("Passwords do not match!")
      return false
    }
    return true
  }
  useEffect(() => {
    if (
      passwordUpdateSuccess &&
      !passwordUpdateLoading &&
      !passwordUpdateError
    ) {
      setAlertSuccess(true)
      resetChanges()
      setTimeout(function () {
        setAlertSuccess(false)
      }, 5000)
    }
    if (passwordUpdateError && !passwordUpdateLoading) {
      setAlertError(true)
      resetChanges()
      setTimeout(function () {
        setAlertError(false)
      }, 5000)
    }
  }, [passwordUpdateError, passwordUpdateSuccess, passwordUpdateLoading])

  const errorMsgs = {
    valid: "Password must contain 8 characters including a special character",
    match: "",
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {error || alertError ? (
            <Alert color="danger">
              {error != "" ? error : passwordUpdateError}
            </Alert>
          ) : null}
          {alertSuccess && !passwordUpdateError ? (
            <Alert color="success">Password Updated!</Alert>
          ) : null}
          <div className="p-0">
            <Row>
              <Col sm="6">
                <FormGroup className="mb-3 col-md-10 col-lg-6">
                  <Label>Current Password*</Label>
                  <InputGroup>
                    <input
                      name="password"
                      value={oldPassword}
                      className="form-control"
                      onChange={e => {
                        setClearActive(true)
                        setoldPassword(e.target.value)
                      }}
                      placeholder="Enter password"
                      type={oldpasswordType ? "text" : "password"}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i
                          className={
                            oldpasswordType ? "mdi mdi-eye" : "mdi mdi-eye-off"
                          }
                          onClick={() => setoldpasswordType(!oldpasswordType)}
                        />
                      </span>
                    </div>
                  </InputGroup>
                  <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                    {oldPassword !== "" &&
                      !re.test(oldPassword) &&
                      errorMsgs.valid}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup className="mb-3 col-md-10 col-lg-6">
                  <Label>New Password*</Label>
                  <InputGroup>
                    <input
                      name="password"
                      value={password1}
                      className="form-control"
                      onChange={e => {
                        setClearActive(true)
                        setpassword1(e.target.value)
                      }}
                      placeholder="Enter password"
                      type={password1Type ? "text" : "password"}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i
                          className={
                            password1Type ? "mdi mdi-eye" : "mdi mdi-eye-off"
                          }
                          onClick={() => setpassword1Type(!password1Type)}
                        />
                      </span>
                    </div>
                  </InputGroup>
                  <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                    {password1 !== "" && !re.test(password1) && errorMsgs.valid}
                  </span>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="mb-3 col-md-10 col-lg-6">
                  <Label>Re-enter Password*</Label>
                  <InputGroup>
                    <input
                      name="password"
                      value={password2}
                      className="form-control"
                      onChange={e => {
                        setClearActive(true)
                        setpassword2(e.target.value)
                      }}
                      placeholder="Enter password"
                      type={password2Type ? "text" : "password"}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i
                          className={
                            password2Type ? "mdi mdi-eye" : "mdi mdi-eye-off"
                          }
                          onClick={() => setpassword2Type(!password2Type)}
                        />
                      </span>
                    </div>
                  </InputGroup>
                  <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                    {password2 !== "" && !re.test(password2) && errorMsgs.valid}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <hr />
            <Row className="mt-4">
              <Col xl={6}></Col>
              <Col xl={6}>
                <label
                  className="btn btn-success float-end ms-3"
                  style={{
                    background: "#76C4CC",
                    width: "150px",
                    height: "37px",
                  }}
                >
                  {passwordUpdateLoading ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                  ) : null}
                  Save Changes
                  <input
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => updateData()}
                  />
                </label>
                {clearActive && (
                  <label
                    className="btn btn-success float-end"
                    style={{
                      background: "#ffffff",
                      borderColor: "#76C4CC",
                      color: "#76C4CC",
                      width: "150px",
                      height: "37px",
                    }}
                  >
                    Reset
                    <input
                      type="button"
                      style={{ display: "none" }}
                      onClick={() => resetChanges()}
                    />
                  </label>
                )}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(ChangePassword)

ChangePassword.propTypes = {
  profile: PropTypes.object,
  updatePassword: PropTypes.func,
}
