import React from 'react'

function ContactMap() {
  return (
    <>
       <div className="contact-map ">
          {/* <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14008.49864030895!2d77.3614849871582!3d28.626025699999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff0d9c8fe3d%3A0x8696e7fd91e4382!2sChetu%2C%20Inc!5e0!3m2!1sen!2sin!4v1718891093835!5m2!1sen!2sin"
          style={{height: '570px'}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}


<iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.539038282467!2d-71.3158949!3d42.6499313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3a4465f1ca2bf%3A0xf2662077b0def875!2s650%20Suffolk%20St%2C%20Lowell%2C%20MA%2001854%2C%20USA!5e0!3m2!1sen!2sin!4v1719939883334!5m2!1sen!2sin" style={{height: '570px'}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
      </div>
    </>
  )
}

export default ContactMap