import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderImage from "./Profile/logo.svg"
import './Profile/mycss.css';


const Privacy = () => {
  console.log("in privacy")
  return (
    <>
    <div>
        <header className='myHeader'>
        <NavLink to='https://www.theconvenienceapp.com' target='_blank' rel='noopener noreferrer'>
            <img src={HeaderImage} alt='Header Image'/>
            </NavLink>
        </header>
    </div>
      <div>
      <h2><b><u>Introduction:</u></b></h2>
      <br/>
      <p>The Convenience App, LLC (&quot; Company &quot; or &quot; We &quot;) respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes:</p>
      <ul>
        <li>The types of information we may collect or that you may provide when you download, install, register with, access, or use The Convenience App (the &quot; App &quot;).</li>
        <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
      </ul>
      </div>
      <div>
      <p>This policy applies only to information we collect in this App, and in email, text, and other electronic communications sent through or in connection with this App.</p>
      <br/>
      <p>This policy DOES NOT apply to information that:</p>
      <ul>
        <li>We collect offline or on any other Company apps or websites, including websites you may access through this App.</li>
        <li>You provide to or is collected by any third party (see <span style={{backgroundColor: 'lightblue'}}>Third-Party Information Collection</span>).</li>
      </ul>
      </div>
      <br/>
      <div>
        <p>Our websites and apps, and these other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.</p>
      <br/>
      <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time (see <span style={{backgroundColor: 'lightblue'}}>Changes to Our Privacy Policy</span>). Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.</p>
      </div>
      <br/>
      <div>
        <h5><u>Person Under the Age of 21</u></h5>
        <br/>
        <p>The App is not intended for persons under 21 years of age, and we do not knowingly collect personal information from persons under the age of 21. If we learn we have collected or received personal information from a person under 21 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, or any person under 21 please contact us as provided in the Contact Information section provided below with sufficient detail to enable us to delete that information from our databases.</p>
        <br/>
        <p>California residents under 21 years of age may have additional rights regarding the collection and sale of their personal information. Please see Your California Privacy Rights for more information.</p>
      </div>
      <div>
        <h5><u>Information We Collect and How We Collect It</u></h5>
        <p>We collect information from and about users of our App:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically when you use the App.</li>
        </ul>
        <br/>
        <h5><i>Information You Provide to Us</i></h5>
        <p>When you download, register with, or use this App, we may ask you provide information:</p>
        <ul>
          <li>By which you may be personally identified, such as name, date of birth, photo identification, postal address, email address, telephone number, credit card numbers, bank account numbers, or any other identifier by which you may be contacted online or offline (<span><b>&quot; personal information &quot;</b></span>).</li>
          <li>That is about you but individually does not identify you, such as tracking types of products purchased through the App, responses to surveys and promotional.</li>
          </ul>
          <br/>
          <p>This information includes:</p>
          <ul>
            <li>Information that you provide by filling in forms in the App and for the purposes of using the App. This includes information provided at the time of registering to use the App, posting material, and requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with the App.</li>
            <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
            <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
            <li>Details of transactions you carry out through the App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the App.</li>
            <li>Your search queries on the App.</li>
            <br/>
          </ul>
          <p>You may also provide information for publication or display (<span><b>&quot; Posted &quot;</b></span>) on public areas of the app or websites you access through the App (collectively, <span><b>&quot; User Contributions &quot;</b></span>). Your User Contributions are Posted and transmitted to others at your own risk. Additionally, we cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>
      </div>
      <div>
        <h5><i>Automatic Information Collection and Tracking</i></h5>
        <p>When you download, access, and use the App, it may use technology to automatically collect:</p>
        <ul>
          <li><span><b>Usage Details:</b></span>When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App.</li>
          <li><span><b>Device Information:</b></span>We may collect information about your mobile device and internet connection, including the device &rsquo;s unique device identifier, IP address, operating system, browser type, mobile network information, and the device &rsquo;s telephone number.</li>
          <li><span><b>Stored Information and Files:</b></span>The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</li>
          <li><span><b>Location Information:</b></span>This App collects real-time information about the location of your device. The purpose of the App is to search for convenience stores within a half mile of your location. In order to provide such services the App has a GPS feature in-built into the App to track your real-time information.</li>
        </ul>
        <p>If you do not want us to collect this information you may opt out of collection of certain types of information at any time by opt-out method set out in the <span style={{backgroundColor: 'lightblue'}}>Your Choices About Our Collection, Use, and Disclosure of Your Information</span> section. Note, however, that you cannot opt out of collection of your location information, as opting out of such information will disable its primary purpose which is based on the location-based features.</p>
        <p>We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking). Click here [<span style={{backgroundColor: 'yellow'}}>INCLUDE AS LINK TO DO NOT TRACK DISCLOSURES</span>] for information on how you can opt out of behavioral tracking on or through this app and how we respond to browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.</p>
      </div>
      <div>
        <h6><i>Information Collection and Tracking Technologies</i></h6>
        <p>The technologies we use for automatic information collection may include:</p>
        <ul>
          <li><span><b>Cookies (or mobile cookies):</b></span>A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</li>
          <li><span><b>Web Beacons:</b></span>Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).</li>
        </ul>
      </div>
      <div>
        <h5><i>Third-Party Information Collection</i></h5>
        <p>When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
        <ul>
          <li>Third-Party Service Provider Partners.</li>
          <li>Advertisers, ad networks, and ad servers.</li>
          <li>Analytics companies.</li>
          <li>Your mobile device manufacturer.</li>
          <li>Your mobile service provider.</li>
        </ul>
        <p>These third parties may use tracking technologies to collect information about you when you use this app. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
        <p>We do not control these third parties tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>
      </div>
      <div>
        <h5><u>How We Use Your Information</u></h5>
        <p>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
        <ul>
          <li>Provide you with the App and its contents, and any other information, products or services that you request from us.</li>
          <li>Fulfill any other purpose for which you provide it.</li>
          <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
          <li>Meeting legal, security, processing and regulatory requirements.</li>
          <li>Protecting against fraud, suspicious or other illegal activities.</li>
          <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
        </ul>
        <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>Store information about your preferences, allowing us to customize our App according to your individual interests.</li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you use the App.</li>
        </ul>
        <p>We collect and use real-time location information to search for convenience stores within a half mile of your location. In order to provide such services, the App has a GPS feature in-built into the App to track your real-time information.</p>
        <p>We may also use your information to contact you about our own and third parties goods and services that may be of interest to you. If you do not want us to use your information in this way, you can opt-out by unsubscribing to the email or text messages at any time by either (i) clicking the “unsubscribe” link in the email or (ii) following the unsubscribe procedure set out in the text message.</p>
        <p>For more information, see Your Choices About Our Collection, Use, and Disclosure of Your Information.</p>
      </div>
      <div>
        <h5><u>Disclosure of Your Information</u></h5>
        <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.</p>
        <p>In addition, we may disclose personal information that we collect or you provide:</p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
          <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of The Convenience App, LLC&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by The Convenience App, LLC about our App users is among the assets transferred.</li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>For any other purpose disclosed by us when you provide the information.</li>
          <li>With your consent.</li>
          <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
          <li>To enforce our rights arising from any contracts entered into between you and us, including the App EULA, and for billing and collection.</li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of The Convenience App, LLC, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
        </ul>
      </div>
      <div>
        <h5><u>Your Choices About Our Collection, Use, and Disclosure of Your Information</u></h5>
        <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
        <ul>
          <li><span><b>Tracking Technologies:</b></span>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You can choose whether or not to allow the App to collect information through other tracking technologies by [OPT-OUT METHOD]. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
          <li><span><b>Location Information:</b></span>You cannot opt out of the App collecting and using real-time information about your device&rsquo;s location as blockage of the use of location information will disable all services provided by the App. You can set your privacy settings of your Mobile Device such that the App will not track your real-time location when the App is not in use.</li>
          <li><span><b>Promotion by the Company:</b></span>If you do not want us to use your email address or phone number to promote our own or third parties products or services, you can opt-out by unsubscribing to the email or text messages at any time by either (i) clicking the “unsubscribe” link in the email or (ii) following the unsubscribe procedure set out in the text message.</li>
          <li><span><b>Targeted Advertising by the Company:</b></span>If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers target-audience preferences, you can opt-out by unsubscribing to the email or text messages at any time by either (i) clicking the “unsubscribe” link in the email or (ii) following the unsubscribe procedure set out in the text message.</li>
        </ul>
        <p>We do not control third parties collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&quot; NAI &quot;) on the NAI&rsquo;s website.</p>
        <p>California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.</p>
      </div>
      <div>
        <h5><u>Accessing and Correcting Your Personal Information</u></h5>
        <p>You can review and change your personal information by logging into the App and visiting your account profile page.</p>
        <p>You may also send us an email at <a href="mailto: contact@theconvenienceapp.com">contact@theconvenienceapp.com</a> to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
        <p>If you delete your User Contributions from the App, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other App users. Proper access and use of information provided on the App, including User Contributions, is governed by our terms of use <span style={{backgroundColor: 'lightblue'}}>[LINK TO TERMS OF USE]</span>.</p>
        <p>California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.</p>
      </div>
      <div>
        <h5><u>Your California Privacy Rights</u></h5>
        <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit <a href='https://oag.ca.gov/privacy/ccpa' target='_blank' rel="noopener noreferrer">https://oag.ca.gov/privacy/ccpa</a></p>
        <p>California &rsquo;s &quot; Shine the Light &quot; law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto: contact@theconvenienceapp.com">contact@theconvenienceapp.com</a> or write to us at: The Convenience App, LLC, 2 Mount Royal Avenue Suite 400 Marlborough, Massachusetts 01752.</p>
        <p>to see the privacy rights for California residents</p>
        <NavLink to={`/privacy_california`} target='_blank' rel="noopener noreferrer" style={{ textDecoration: "none" }}><p>Click here</p></NavLink>
      </div>
      <div>
        <h5><u>Data Security</u></h5>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any personal information including photo identification, credit card numbers, bank account numbers, and payment transactions will be encrypted using SSL technology.</p>
        <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the App like message boards. The information you share in public areas may be viewed by any user of the App.</p>
        <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
      </div>
      <div>
        <h5><u>Changes to Our Privacy Policy</u></h5>
        <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and <span style={{backgroundColor: 'lightblue'}}>notify you with an in-App alert the first time you use the App after we make the change</span>.</p>
        <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for checking the in-App alerts and for periodically visiting this privacy policy to check for any changes.</p>
      </div>
      <div>
        <h5><u>Contact Information</u></h5>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
        <p>Email: <a href="mailto: contact@theconvenienceapp.com">contact@theconvenienceapp.com</a></p>
        <p>Postal Address: 2 Mount Royal Avenue Suite 400 Marlborough, Massachusetts 01752 
Toll-free number: [TOLL-FREE NUMBER]</p>
      </div>
      <div>
        <header className='myHeader'>
        </header>
    </div>
      </>
  );
};

export default Privacy;