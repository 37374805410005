import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Progress,
  Badge,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  UncontrolledAlert,
} from "reactstrap"

import { Link } from "react-router-dom"

import SweetAlert from "react-bootstrap-sweetalert"
//Images
import noImage from "../../assets/images/inventory/no-image.png"
import remove from "../../assets/images/inventory/remove.svg"
import tick from "../../assets/images/inventory/tick.svg"
import removeDark from "../../assets/images/inventory/remove.png"
import tickDark from "../../assets/images/inventory/tick.png"
import edit from "../../assets/images/inventory/edit.png"

//redux
import { useSelector, useDispatch } from "react-redux"
import {
  resetOrderQuantityState,
  updateOrderItemQuantity,
  updateOrderStatus,
} from "../../store/actions"

const OrderDetails = props => {
  const dispatch = useDispatch()
  const {
    loading,
    updateOrderItemQuantitySuccess,
    updateOrderItemQuantityError,
    updateOrderItemQuantityLoading,
  } = useSelector(state => ({
    loading: state.orders.updateOrderStatusLoading,
    updateOrderItemQuantitySuccess: state.orders.updateOrderItemQuantitySuccess,
    updateOrderItemQuantityError: state.orders.updateOrderItemQuantityError,
    updateOrderItemQuantityLoading: state.orders.updateOrderItemQuantityLoading,
  }))
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false)
  const [cancelOrdereAlert, setCancelOrderAlert] = useState(false)
  const [successDialogue, setSuccessDialogue] = useState(false)
  const [packedDialogue, setPackedDialogue] = useState(false)
  const [dynamicAlertText, setDynamicAlertText] = useState("")
  const [commentOrderCancel, setCommentOrderCancel] = useState("")
  const [itemsPacked, setItemsPacked] = useState(0)
  const [warn, setWarning] = useState(false)

  const [quantity, setquantity] = useState(0)
  const [editQuantity, setEditQuantity] = useState({
    val: true,
    ind: -1,
    id: -1,
  })
  const { isOpen, toggle } = props

  useEffect(() => {
    if (
      !updateOrderItemQuantityLoading &&
      updateOrderItemQuantitySuccess &&
      !updateOrderItemQuantityError
    ) {
      setEditQuantity({ val: false })
      dispatch(resetOrderQuantityState())
    } else if (updateOrderItemQuantityError) {
      setWarning(true)
    }
  }, [updateOrderItemQuantitySuccess, updateOrderItemQuantityError])

  useEffect(() => {
    if (warn) {
      {
        setTimeout(function () {
          setWarning(false)
        }, 5000)
      }
    }
  }, [warn])
  const phoneIndex = () => {
    if (props.data.customer.phone) {
      if (Number(props.data.customer.phone.substring(1, 2)) == 1) {
        return 2
      } else {
        return 3
      }
    }
  }
  const handleOrderStatus = () => {
    if (props.data.status == 20) {
      //New >> Accepted
      dispatch(updateOrderStatus({ status: 30 }, props.data.id))
    } else if (props.data.status == 30) {
      //Accepted >> Packed
      if (props.data.items.length == itemsPacked) {
        //if all items are checked than pack here
        dispatch(updateOrderStatus({ status: 40 }, props.data.id))
        setItemsPacked(0)
      } else {
        //check items popup
        setPackedDialogue(true)
      }
    } else if (props.data.status == 40) {
      //Packed >> Completed
      dispatch(updateOrderStatus({ status: 50 }, props.data.id))
    }
  }
  const handelCancelOrder = () => {
    dispatch(
      updateOrderStatus(
        { status: 100, store_comment: commentOrderCancel },
        props.data.id
      )
    )
    setSuccessDialogue(true)
    setDynamicAlertText("Cancelled")
  }

  const updateQuantity = () => {
    const data = {
      quantity: quantity,
    }
    if (editQuantity.id && editQuantity.id > 0) {
      dispatch(updateOrderItemQuantity(data, props?.data?.id, editQuantity?.id))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="orderModal"
      tabIndex="-1"
      toggle={toggle}
      size="lg"
    >
      {/* Alerts */}
      {confirmDeleteAlert ? (
        <SweetAlert
          title="Are you sure you want to delete this item?"
          warning
          showCancel
          confirmBtnText="Delete Item"
          cancelBtnText="Cancel"
          confirmBtnStyle={{ background: "#76C4CC", borderWidth: 0 }}
          onConfirm={() => {
            setConfirmDeleteAlert(false)
            setSuccessDialogue(true)
            setDynamicAlertText("Deleted")
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {cancelOrdereAlert ? (
        <SweetAlert
          // title="Comments?"
          warning
          showCancel
          confirmBtnText="Cancel Order"
          cancelBtnText="Go Back"
          cancelBtnStyle={{ color: "black" }}
          confirmBtnStyle={{ background: "#76C4CC", borderWidth: 0 }}
          onConfirm={() => {
            handelCancelOrder()
            setCancelOrderAlert(false)
          }}
          onCancel={() => setCancelOrderAlert(false)}
        >
          {/* <textarea
            className="form-control"
            rows="3"
            onChange={() => {
              setCommentOrderCancel(event.target.value)
            }}
            placeholder="Enter reason for cancellation"
          ></textarea> */}
        </SweetAlert>
      ) : null}

      {successDialogue ? (
        <SweetAlert
          success
          title={dynamicAlertText}
          confirmBtnStyle={{
            backgroundColor: "#76C4CC",
            borderColor: "#76C4CC",
          }}
          onConfirm={() => {
            setSuccessDialogue(false)
          }}
        ></SweetAlert>
      ) : null}
      {packedDialogue ? (
        <SweetAlert
          warning
          title={"Not all items are packed"}
          confirmBtnStyle={{
            backgroundColor: "#76C4CC",
            borderColor: "#76C4CC",
          }}
          onConfirm={() => {
            setPackedDialogue(false)
          }}
        ></SweetAlert>
      ) : null}
      {/* Main Model */}
      {props.data && props.data !== undefined ? (
        <div className="modal-content">
          <ModalHeader toggle={toggle} style={{ color: "black" }}>
            <h3 className="mx-1">Order # {props.data.id}</h3>
            <Badge
              style={
                props.data.is_curbside_pickup
                  ? {
                      backgroundColor: "rgba(31, 98, 199, 0.2)",
                      color: "#4677C1",
                    }
                  : {
                      backgroundColor: "rgba(148, 39, 117, 0.2)",
                      color: "#A1558C",
                    }
              }
              className=" mx-1 py-1 px-2"
            >
              {props.data.is_curbside_pickup
                ? "Curbside Delivery"
                : "In-store Pickup"}
            </Badge>
            <Badge
              style={
                props.data.status == 100
                  ? {
                      color: "#7C715C",
                      backgroundColor: "rgba(124, 113, 92, 0.2)",
                    }
                  : props.data.status >= 50
                  ? {
                      color: "#0E6858",
                      backgroundColor: "rgba(14, 104, 88, 0.2)",
                    }
                  : null
              }
              className="rounded-pill mx-1 py-1 px-2"
            >
              {props.data.status == 100
                ? "Cancelled"
                : props.data.status >= 50
                ? "Completed"
                : null}
            </Badge>
          </ModalHeader>
          <ModalBody>
            <div className="mt-2">
              <div className="position-relative mb-2 mx-5">
                <Progress
                  value={
                    props.data.status == 20
                      ? 0
                      : props.data.status == 30
                      ? 33
                      : props.data.status == 40
                      ? 66
                      : props.data.status == 50 || props.data.status == 60
                      ? 100
                      : 0
                  }
                  color="success"
                  style={{ height: "2px" }}
                />

                <div>
                  <button
                    className={
                      props.data.status >= 20 && props.data.status <= 60
                        ? "position-absolute top-0 start-0 translate-middle btn btn-sm btn-success rounded-pill"
                        : props.data.status == 100
                        ? "position-absolute top-0 start-0 translate-middle btn btn-sm btn-danger rounded-pill"
                        : "position-absolute top-0 start-0 translate-middle btn btn-sm btn-secondary rounded-pill"
                    }
                    style={{
                      width: "2rem",
                      height: "2rem",
                      pointerEvents: "none",
                    }}
                  >
                    <img
                      src={
                        (props.data.status >= 20 && props.data.status <= 60) ||
                        props.data.status == 100
                          ? tick
                          : remove
                      }
                      width="12px"
                    />
                  </button>
                  {props.screen == "orders" ? (
                    <div>
                      <button
                        className={
                          props.data.status >= 30 && props.data.status <= 60
                            ? "position-absolute top-0 translate-middle btn btn-sm btn-success rounded-pill"
                            : "position-absolute top-0 translate-middle btn btn-sm btn-secondary rounded-pill"
                        }
                        style={{
                          width: "2rem",
                          height: "2rem",
                          marginLeft: "33%",
                          pointerEvents: "none",
                        }}
                      >
                        <img
                          src={
                            props.data.status >= 30 && props.data.status <= 60
                              ? tick
                              : remove
                          }
                          width="12px"
                        />
                      </button>
                      <button
                        className={
                          props.data.status >= 40 && props.data.status <= 60
                            ? "position-absolute top-0 translate-middle btn btn-sm btn-success rounded-pill"
                            : "position-absolute top-0 translate-middle btn btn-sm btn-secondary rounded-pill"
                        }
                        style={{
                          width: "2rem",
                          height: "2rem",
                          marginLeft: "67%",
                          pointerEvents: "none",
                        }}
                      >
                        <img
                          src={
                            props.data.status >= 40 && props.data.status <= 60
                              ? tick
                              : remove
                          }
                          width="12px"
                        />
                      </button>
                    </div>
                  ) : null}
                  <button
                    className={
                      "position-absolute top-0 start-100 translate-middle btn btn-sm " +
                      (props.data.status == 50 || props.data.status == 60
                        ? "btn-success"
                        : "btn-secondary") +
                      " rounded-pill"
                    }
                    style={{
                      width: "2rem",
                      height: "2rem",
                      pointerEvents: "none",
                    }}
                  >
                    <img
                      src={
                        props.data.status == 50 || props.data.status == 60
                          ? tick
                          : remove
                      }
                      width="12px"
                    />
                  </button>
                </div>
              </div>
              <Row className="mt-3">
                <Col>
                  <p style={{ textAlign: "start", marginLeft: 25 }}>
                    {props.screen == "orders" ? "New" : "Cancelled"}
                  </p>
                </Col>
                {props.screen == "orders" ? (
                  <Col>
                    <p style={{ textAlign: "center" }}>Accepted</p>
                  </Col>
                ) : null}
                {props.screen == "orders" ? (
                  <Col>
                    <p style={{ textAlign: "center" }}>Packed</p>
                  </Col>
                ) : null}
                <Col>
                  <p style={{ textAlign: "end", marginRight: 10 }}>Completed</p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="col-5">
                {/* <p className="ms-3 mb-1 font-size-14">
                  User #{" "}
                  <span className="text-primary">{props.data.customer.id}</span>
                </p> */}
                <p
                  className="ms-3 mb-1 font-size-14"
                  style={{ fontWeight: "bold" }}
                >
                  Name :{" "}
                  <span
                    className="text-primary"
                    style={{ whiteSpace: "nowrap", fontWeight: "normal" }}
                  >
                    {props.data.customer.first_name}{" "}
                    {props.data.customer.last_name}
                  </span>
                </p>
                <p
                  className="ms-3 mb-4 font-size-14"
                  style={{ fontWeight: "bold" }}
                >
                  Phone #{" "}
                  <span
                    className="text-primary"
                    style={{ fontWeight: "normal" }}
                  >
                    {props.data.customer.phone
                      ? props.data.customer.phone.substring(0, phoneIndex()) +
                        " " +
                        props.data.customer.phone.substring(phoneIndex())
                      : null}
                  </span>
                </p>
              </Col>
              <Col className="col-7">
                <p className="ms-3 mb-1 h5">
                  {props.data.has_checked_in && props.data.vehicle != null
                    ? "Vehicle"
                    : props.data.status == 100
                    ? props.data.store_comment
                      ? "Comments"
                      : null
                    : null}
                </p>
                <p className="ms-3 mb-1 font-size-13">
                  {props.data.has_checked_in && props.data.vehicle != null
                    ? (props.data.vehicle.color_name &&
                        props.data.vehicle.color_name[0].toUpperCase() +
                          props.data.vehicle.color_name.substring(1)) +
                      " " +
                      (props.data.vehicle.manufacturer &&
                        props.data.vehicle.manufacturer) +
                      " " +
                      (props.data.vehicle.model && props.data.vehicle.model)
                    : props.data.status == 100
                    ? props.data.store_comment
                    : null}
                </p>
                {props.data.has_checked_in && props.data.vehicle != null ? (
                  <p className="ms-3 mb-1 font-size-13">
                    {props.data.vehicle.license_plate}
                  </p>
                ) : null}
              </Col>
            </Row>
            {warn && updateOrderItemQuantityError ? ( //todoo
              <UncontrolledAlert
                color="danger"
                onClose={() => setWarning(false)}
                dismissible="true"
                className="mx-2 my-2"
              >
                <p className="mb-0">
                  <i className="mdi mdi-block-helper me-2"></i>
                  {updateOrderItemQuantityError
                    ? updateOrderItemQuantityError
                    : "Error"}
                </p>
              </UncontrolledAlert>
            ) : null}
            <div className="table-responsive">
              <Table className="table align-middle ">
                <thead>
                  <tr>
                    <th className="col-md-1"></th>
                    <th className="col-md-2">Name</th>
                    <th className="col-md-2">Description</th>
                    <th className="col-md-2">Quantity</th>
                    <th className="col-md-2">Price</th>
                    <th className="col-md-2">Row Total</th>
                    {props.screen == "orders" && props.data.status == 30 ? (
                      <th className="col-md-2">Packed</th>
                    ) : null}
                    {/* {props.screen == "orders" ? (
                      <th className="col-md-1" />
                    ) : null} */}
                  </tr>
                </thead>
                <tbody>
                  {props.data.items &&
                    props.data.items.length > 0 &&
                    props.data.items.map((item, index) => (
                      <tr key={index}>
                        <th>
                          <img
                            src={
                              item.product.image ? item.product.image : noImage
                            }
                            alt="Header Avatar"
                            width="36px"
                          />
                        </th>
                        <th>
                          <span className="co-name">{item.product.name}</span>
                        </th>
                        <td>{item.product.description}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {editQuantity.val && editQuantity.ind == index ? (
                            <div
                              style={{
                                display: "inline-flex",
                                whiteSpace: "nowrap",
                                width: "min-content",
                              }}
                            >
                              <InputGroup style={{ whiteSpace: "nowrap" }}>
                                <InputGroupAddon
                                  addonType="prepend"
                                  style={{ margin: 4 }}
                                >
                                  <Button
                                    size="sm"
                                    style={{
                                      borderRadius: 25,
                                      background: "#76C4CC",
                                      borderColor: "#76C4CC",
                                      width: 27,
                                      height: 27,
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      if (quantity !== 0) {
                                        setquantity(quantity - 1)
                                      }
                                    }}
                                  >
                                    -
                                  </Button>
                                </InputGroupAddon>
                                <Input
                                  type="text"
                                  pattern="[0-9]*"
                                  value={quantity}
                                  onChange={e => {
                                    let value = Number(e.target.value)
                                    setquantity(value)
                                  }}
                                  className="px-0"
                                  style={{
                                    textAlign: "center",
                                    borderRadius: 5,
                                    height: "35px",
                                    maxWidth: "40px",
                                    minWidth: "30px",
                                  }}
                                />
                                <InputGroupAddon
                                  addonType="append"
                                  style={{
                                    margin: 4,
                                  }}
                                >
                                  <Button
                                    size="sm"
                                    style={{
                                      borderRadius: 25,
                                      background: "#76C4CC",
                                      borderColor: "#76C4CC",
                                      width: 27,
                                      height: 27,
                                    }}
                                    onClick={() => {
                                      setquantity(quantity + 1)
                                    }}
                                  >
                                    +
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                              {updateOrderItemQuantityLoading ? (
                                <Col style={{ alignSelf: "center" }}>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle" />
                                </Col>
                              ) : (
                                <Col style={{ alignSelf: "center" }}>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      updateQuantity()
                                    }}
                                    className="text-success m-1"
                                  >
                                    <img
                                      src={tickDark}
                                      style={{
                                        marginLeft: 10,
                                      }}
                                      width="12px"
                                    />
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setEditQuantity({ val: false })
                                    }}
                                    className="text-success m-1"
                                  >
                                    <img
                                      src={removeDark}
                                      style={{ marginLeft: 5 }}
                                      width="10px"
                                    />
                                  </Link>
                                </Col>
                              )}
                            </div>
                          ) : (
                            <div>
                              <Badge
                                style={{
                                  width: "25%",
                                  minWidth: "50px",
                                }}
                                className={
                                  item.quantity < 5
                                    ? "font-size-12 bg-danger rounded-pill"
                                    : "font-size-12 bg-success rounded-pill"
                                }
                              >
                                {item.quantity}
                              </Badge>
                              {props.data.status <= 30 && (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setEditQuantity({
                                      val: true,
                                      ind: index,
                                      id: item.id,
                                    })
                                    setquantity(item.quantity)
                                  }}
                                  className="text-success"
                                >
                                  <img
                                    src={edit}
                                    alt="Header Avatar"
                                    style={{ marginLeft: 10 }}
                                    width="14px"
                                  />
                                </Link>
                              )}
                            </div>
                          )}
                        </td>
                        <td>${item.price}</td>
                        <td>
                          {item.quantity == 0
                            ? "$00.00"
                            : `$${
                                item.price && item.quantity
                                  ? (item.price * item.quantity).toFixed(2)
                                  : null
                              }`}
                        </td>
                        {props.screen == "orders" && props.data.status == 30 ? (
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              className="form-check-input"
                              style={{}}
                              onClick={event => {
                                event.target.checked
                                  ? setItemsPacked(itemsPacked + 1)
                                  : setItemsPacked(itemsPacked - 1)
                              }}
                              id="packedCheck"
                            />
                          </td>
                        ) : null}
                        {/* {props.screen == "orders" ? (
                          <td>
                            <div className="d-flex gap-3">
                              <Link to="#" className="text-success">
                                <img
                                  src={removeBlack}
                                  onClick={() => setConfirmDeleteAlert(true)}
                                  alt="Header Avatar"
                                  style={{ marginRight: 10 }}
                                  width="14px"
                                />
                              </Link>
                            </div>
                          </td>
                        ) : null} */}
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div className="float-end mx-5 my-2">
                <div className="col">
                  <div className="float-start">
                    <p className="ms-3 mb-1 font-size-14">Items Subtotal:</p>
                    <p className="ms-3 mb-1 font-size-14">Tax</p>
                    <p className="ms-3 mb-1 font-size-14">Fee</p>
                    <p className="ms-3 mb-1 font-size-14">Tip</p>
                  </div>
                  <div className="float-end">
                    <p className="ms-3 mb-1 text-primary font-size-14">
                      ${props.data.subtotal}
                    </p>
                    <p className="ms-3 mb-1 text-primary font-size-14">
                      ${props.data.tax}
                    </p>
                    <p className="ms-3 mb-1 text-primary font-size-14">
                      ${props.data.fee}
                    </p>
                    <p className="ms-3 mb-1 text-primary font-size-14">
                      ${props.data.tips}
                    </p>
                  </div>
                </div>
                <div className="row bg-light text-center border border-info rounded ms-2 my-2">
                  <Row>
                    <Col className="mt-2 pe-0 ">
                      <h5 className="float-start" style={{ color: "#336E74" }}>
                        Total:{" "}
                      </h5>
                      <h5 className="float-end" style={{ color: "red" }}>
                        {" "}
                        ${props.data.total}
                      </h5>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {props.screen == "orders" ? (
              <Row className="mt-4">
                {props.data.status == 20 || props.data.status == 30 ? (
                  <label
                    className="btn btn-light me-2 "
                    style={{
                      width: "150px",
                      height: "37px",
                      borderColor: "#76C4CC",
                    }}
                  >
                    Cancel Order
                    <input
                      type="button"
                      style={{ display: "none" }}
                      onClick={() => setCancelOrderAlert(true)}
                    />
                  </label>
                ) : null}
                {props.data.status == 50 || props.data.status == 60 ? null : (
                  <label
                    className="btn btn-success float-end"
                    style={{
                      background: "#76C4CC",
                      borderColor: "#76C4CC",
                      width: "150px",
                      height: "37px",
                    }}
                  >
                    {(loading || updateOrderItemQuantityLoading) ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                    ) : null}
                    {props.data.status == 20
                      ? "Accept Order"
                      : props.data.status == 30
                      ? "Pack Order"
                      : props.data.status == 40
                      ? "Complete Order"
                      : null}
                    <input
                      type="button"
                      disabled={loading}
                      style={{ display: "none" }}
                      onClick={() => handleOrderStatus()}
                    />
                  </label>
                )}
              </Row>
            ) : (
              <Row className="mt-4">
                <label
                  className="btn btn-light me-2 "
                  style={{ width: "150px", height: "40px" }}
                >
                  Close
                  <input
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => toggle()}
                  />
                </label>
              </Row>
            )}
          </ModalFooter>
        </div>
      ) : null}
    </Modal>
  )
}

OrderDetails.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  screen: PropTypes.string,
}

export default OrderDetails
