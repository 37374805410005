export const FETCH_STORE = "FETCH_STORE"
export const FETCH_STORE_SUCCESS = "FETCH_STORE_SUCCESS"
export const FETCH_STORE_ERROR = "FETCH_STORE_ERROR"
export const UPDATE_STORE = "UPDATE_STORE"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_ERROR = "UPDATE_STORE_ERROR"
export const UPLOAD_STORE_PHOTO = "UPLOAD_STORE_PHOTO"
export const UPLOAD_STORE_PHOTO_SUCCESS = "UPLOAD_STORE_PHOTO_SUCCESS"
export const UPLOAD_STORE_PHOTO_ERROR = "UPLOAD_STORE_PHOTO_ERROR"
export const RESET_STORE_STATE = "RESET_STORE_STATE"
