import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import filter from "../../assets/images/orders/filter.png"

import {
  Badge,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
} from "reactstrap"

import "../../assets/scss/custom/pages/style.css"
import removeBlack from "../../assets/images/inventory/remove.png"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import OrderDetails from "./orderDetails"
//Skeleton
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
//Pagination
import ReactPaginate from "react-paginate"
//redux
import { useSelector, useDispatch } from "react-redux"
import { getPastOrders } from "../../store/actions"
import { dateFormat } from "../../helpers/utils"

const PastOrders = props => {
  const [btnFilter, setBtnFilter] = useState(false)
  const [inStoreFilter, setInStoreFilter] = useState(false)
  const [curbsideFilter, setCurbsideFilter] = useState(false)
  const [completedFilter, setCompletedFilter] = useState(false)
  const [cancelledFilter, setCancelledFilter] = useState(false)

  const [isCurbside, setIsCurbside] = useState(null)
  const [status, setStatus] = useState("60,100")

  const [orderDetailModel, setOrderDetailModel] = useState(false)
  const toggleViewModal = () => setOrderDetailModel(!orderDetailModel)

  //paginate
  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 10
  //data
  const [searchTerm, setSearchTerm] = useState("")
  const [mapResult, setMapResult] = useState()
  const [ordersResult, setOrdersResult] = useState()
  const [modelData, setModelData] = useState()
  //redux
  const dispatch = useDispatch()
  const { orders, success, error, loading } = useSelector(state => ({
    success: state.orders.getPastOrdersComplete,
    error: state.orders.getPastOrdersError,
    orders: state.orders.pastOrders,
    loading: state.orders.getPastOrdersLoading,
  }))

  useEffect(() => {
    // console.log('Hello at 62!!!!!!!!!!!!!!!!!')
    if (!success && !loading) {
      dispatch(getPastOrders(itemOffset, searchTerm, isCurbside, status))
      // console.log(status, 'Hello Status!!!!!!!!!!!')
      // console.log(itemOffset, 'Hello itemoffset!!!!!!!!!!!')
      // console.log(isCurbside, 'Hello isCurbside!!!!!!!!!!!')

    }
  }, [orders, success, error])

  const fetchSearchTerm = value => {
    dispatch(getPastOrders(0, value, isCurbside, status))
  }

  useEffect(() => {
    if (orders != null) {
      setOrdersResult(orders.results)
    }
  }, [orders])

  useEffect(() => {
    if (success && !loading) {
      dispatch(getPastOrders(0, searchTerm, isCurbside, status))
    }
  }, [isCurbside, status])

  useEffect(() => {
    if (mapResult != null) {
      setCurrentItems(mapResult)
      setPageCount(Math.ceil(orders.count / itemsPerPage))
    }
  }, [itemOffset, searchTerm, mapResult])
  const fetchNewOffsetItems = offset => {
    dispatch(getPastOrders(offset, searchTerm, isCurbside, status))
  }
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % orders.count
    setItemOffset(newOffset)
    fetchNewOffsetItems(newOffset)
  }

  useEffect(() => {
    if (orders != null) {
      if (curbsideFilter && inStoreFilter) {
        setIsCurbside(null)
      } else if (curbsideFilter && !inStoreFilter) {
        setIsCurbside(true)
      } else if (inStoreFilter && !curbsideFilter) {
        setIsCurbside(false)
      } else {
        setIsCurbside(null)
      }
      if (completedFilter && cancelledFilter) {
        setStatus("60,100")
      } else if (completedFilter && !cancelledFilter) {
        setStatus("60")
      } else if (!completedFilter && cancelledFilter) {
        setStatus("100")
      } else {
        setStatus("60,100")
      }
    }
  }, [inStoreFilter, curbsideFilter, completedFilter, cancelledFilter])

  useEffect(() => {
    if (ordersResult) {
      setMapResult(ordersResult)
    }
  }, [ordersResult])

  return (
    <React.Fragment>
      <OrderDetails
        isOpen={orderDetailModel}
        screen="pastOrders"
        data={modelData}
        toggle={toggleViewModal}
      />
      <div className="page-content">
        <MetaTags>
          <title>Orders | The Convenience App</title>
        </MetaTags>
        <Container
          fluid
          style={
            currentItems && currentItems.length < 2
              ? { paddingBottom: "200px" }
              : null
          }
        >
          <h4 className="">Past Orders</h4>
          <div className="mx-1 d-flex flex-column flex-md-row-reverse flex-lg-row-reverse flex-sm-column">
            <input
              type="search"
              placeholder="Search by order # or User Name"
              className="m-0 mt-1 px-3 font-size-12"
              style={{
                borderRadius: 5,
                borderWidth: 0,
                width: "250px",
                height: "40px",
              }}
              onChange={() => {
                let value = event.target.value.toLowerCase()
                setSearchTerm(value)
                fetchSearchTerm(value)
              }}
            />
            <Dropdown
              className="mt-1 "
              style={{ flex: "none" }}
              isOpen={btnFilter}
              toggle={() => setBtnFilter(!btnFilter)}
            >
              <DropdownToggle
                tag="button"
                className="btn px-2 me-2"
                style={{
                  flex: "none",
                  whiteSpace: "nowrap",
                  border: "1px solid #76C4CC",
                  borderRadius: "4px",
                  backgroundColor: "#F4FAFB",
                }}
              >
                <img src={filter} width="16px" className="me-1" />
                Filter
                {inStoreFilter ? (
                  <Badge
                    style={{ whiteSpace: "pre-wrap" }}
                    className={"badge-soft-secondary py-2 px-2 ms-1"}
                  >
                    In-Store pickup
                    <img
                      src={removeBlack}
                      style={{
                        width: "10px",
                        marginLeft: "5px",
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        setBtnFilter(false)
                        setInStoreFilter(false)
                      }}
                    />
                  </Badge>
                ) : null}
                {curbsideFilter ? (
                  <Badge className={"badge-soft-secondary py-2 px-2 ms-1"}>
                    Curbside Delivery
                    <img
                      src={removeBlack}
                      style={{
                        width: "10px",
                        marginLeft: "5px",
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        setBtnFilter(false)
                        setCurbsideFilter(false)
                      }}
                    />
                  </Badge>
                ) : null}
                {completedFilter ? (
                  <Badge className={"badge-soft-secondary py-2 px-2 ms-1"}>
                    Completed
                    <img
                      src={removeBlack}
                      style={{
                        width: "10px",
                        marginLeft: "5px",
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        setBtnFilter(false)
                        setCompletedFilter(false)
                      }}
                    />
                  </Badge>
                ) : null}
                {cancelledFilter ? (
                  <Badge className={"badge-soft-secondary py-2 px-2 ms-1"}>
                    Cancelled
                    <img
                      src={removeBlack}
                      style={{
                        width: "10px",
                        marginLeft: "5px",
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        setBtnFilter(false)
                        setCancelledFilter(false)
                      }}
                    />
                  </Badge>
                ) : null}
              </DropdownToggle>
              <DropdownMenu>
                <div className="mb-0 ms-3 me-4">
                  <p className="m-2">Type</p>
                  <div style={{ display: "grid" }} className="m-2">
                    <div
                      className="form-check"
                      onChange={() => setInStoreFilter(!inStoreFilter)}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={inStoreFilter}
                        className="form-check-input"
                        id="inStore"
                      />
                      <label
                        className="form-check-label"
                        style={{ whiteSpace: "nowrap" }}
                        htmlFor="inStore"
                      >
                        In-store Pickup
                      </label>
                    </div>

                    <div
                      className="form-check"
                      onChange={() => setCurbsideFilter(!curbsideFilter)}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={curbsideFilter}
                        className="form-check-input"
                        id="curbside"
                      />
                      <label
                        className="form-check-label"
                        style={{ whiteSpace: "nowrap" }}
                        htmlFor="curbside"
                      >
                        Curbside Delivery
                      </label>
                    </div>
                  </div>
                  <p className="m-2">Status</p>
                  <div style={{ display: "grid" }} className="m-2">
                    <div
                      className="form-check"
                      onChange={() => setCompletedFilter(!completedFilter)}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={completedFilter}
                        className="form-check-input"
                        id="completed"
                      />
                      <label className="form-check-label" htmlFor="completed">
                        Completed
                      </label>
                    </div>

                    <div
                      className="form-check"
                      onChange={() => setCancelledFilter(!cancelledFilter)}
                    >
                      <input
                        type="checkbox"
                        defaultChecked={cancelledFilter}
                        className="form-check-input"
                        id="cancelled"
                      />
                      <label className="form-check-label" htmlFor="cancelled">
                        Cancelled
                      </label>
                    </div>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Table className="project-list-table table-borderless rounded align-middle table">
            <Tbody>
              {currentItems &&
              currentItems != null &&
              currentItems !== undefined ? (
                currentItems.map((order, index) => (
                  <Tr
                    key={index}
                    style={{ marginTop: 20, cursor: "pointer" }}
                    onClick={() => {
                      toggleViewModal()
                      setModelData(order)
                    }}
                  >
                    <Th>
                      <p
                        className="my-3 ms-3 font-size-14"
                        style={{ whiteSpace: "nowrap", fontWeight: "normal" }}
                      >
                        Order #{" "}
                        <span style={{ fontWeight: "bold", whiteSpace: "" }}>
                          {order.id}
                        </span>
                      </p>
                    </Th>
                    <Th>
                      <p
                        className="my-3 font-size-14"
                        style={{ fontWeight: "normal" }}
                      >
                        User Name:{" "}
                        <span
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          {order.customer.first_name} {order.customer.last_name}
                        </span>
                      </p>
                    </Th>
                    <Th>
                      <p
                        className="my-3 font-size-14"
                        style={{ fontWeight: "normal" }}
                      >
                        Last Modified:{" "}
                        <span
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          {dateFormat(order.updated_at)}
                        </span>
                      </p>
                    </Th>
                    <Th>
                      <Badge
                        style={
                          order.is_curbside_pickup
                            ? {
                                backgroundColor: "rgba(31, 98, 199, 0.2)",
                                color: "#4677C1",
                              }
                            : {
                                backgroundColor: "rgba(148, 39, 117, 0.2)",
                                color: "#A1558C",
                              }
                        }
                        className="py-1 px-2 font-size-11"
                      >
                        {order.is_curbside_pickup
                          ? "Curbside Delivery"
                          : "In-Store pickup"}
                      </Badge>
                    </Th>
                    <Th>
                      <Badge
                        style={
                          order.status == 100
                            ? {
                                color: "#7C715C",
                                backgroundColor: "rgba(124, 113, 92, 0.2)",
                              }
                            : {
                                color: "#0E6858",
                                backgroundColor: "rgba(14, 104, 88, 0.2)",
                              }
                        }
                        className="rounded-pill py-1 px-2 font-size-11"
                      >
                        {order.status == 100 ? "Cancelled" : "Completed"}
                      </Badge>
                    </Th>
                  </Tr>
                ))
              ) : (
                <Tr style={{ marginTop: 20 }}>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                  <Td>
                    <Skeleton />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          {orders && orders.count > itemsPerPage ? (
            <div className="float-end rounded px-4 pb-5">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                activeLinkClassName="bg-custom-blue text-white"
                renderOnZeroPageCount={null}
              />
            </div>
          ) : mapResult && mapResult.length == 0 ? (
            <Card>
              <h4 className="my-4" style={{ textAlign: "center" }}>
                No orders yet!
              </h4>
            </Card>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PastOrders
