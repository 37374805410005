/* ORDERS_LIST */
export const GET_ORDERS_LIST = "GET_ORDERS_LIST"
export const GET_ORDERS_LIST_SUCCESS = "GET_ORDERS_LIST_SUCCESS"
export const GET_ORDERS_LIST_ERROR = "GET_ORDERS_LIST_ERROR"
export const GET_PAST_ORDERS = "GET_PAST_ORDERS"
export const GET_PAST_ORDERS_SUCCESS = "GET_PAST_ORDERS_SUCCESS"
export const GET_PAST_ORDERS_ERROR = "GET_PAST_ORDERS_ERROR"
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS"
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS"
export const UPDATE_ORDER_STATUS_ERROR = "UPDATE_ORDER_STATUS_ERROR"
export const UPDATE_ORDER_ITEM_QUANTITY = "UPDATE_ORDER_ITEM_QUANTITY"
export const UPDATE_ORDER_ITEM_QUANTITY_SUCCESS =
  "UPDATE_ORDER_ITEM_QUANTITY_SUCCESS"
export const UPDATE_ORDER_ITEM_QUANTITY_ERROR =
  "UPDATE_ORDER_ITEM_QUANTITY_ERROR"
export const RESET_ORDER_QUANTITY_STATE = "RESET_ORDER_QUANTITY_STATE"
