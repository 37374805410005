import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Profile/index"
import Privacy from "../pages/Privacy"
import WELCOME from "pages/Authentication/Welcome"
import About from "pages/Authentication/About"
import Contact from "pages/Authentication/Contact"
import Privacy_California from "../pages/Privacy_California"
import Terms from "pages/Terms"
import Review from "../pages/Review"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register/index"
import ForgetPwd from "../pages/Authentication/ForgotPassword"
import ResetPassword from "pages/Authentication/ResetPassword"

// Dashboard
import MyStore from "../pages/MyStore/index"
import Financials from "../pages/Financial/index"

//Inventory Management
import InventoryManagement from "../pages/InventoryManagement/index"
import AddEditProduct from "pages/InventoryManagement/addEditProduct"

//Order
import Orders from "../pages/Order/index"
import PastOrders from "../pages/Order/pastOrders"

const authProtectedRoutes = [
  { path: "/review", component: Review },
  { path: "/myStore", component: MyStore },
  { path: "/products", component: InventoryManagement },
  { path: "/products/:id", component: AddEditProduct },
  { path: "/financials", component: Financials },
  { path: "/orders", component: Orders },
  { path: "/allOrders", component: PastOrders },
  
  { path: "/myProfile", component: UserProfile },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/myStore" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/password-reset/confirm/:uid/:token", component: ResetPassword },
  { path: "/privacy", component: Privacy},
  { path: "/privacy_california", component: Privacy_California},
  { path: "/terms", component: Terms},
  { path: "/main", component: WELCOME},
  { path: "/about", component: About},
  { path: "/contact", component: Contact},
  { path: "/", exact: true, component: () => <Redirect to="/main" /> },
]

export { publicRoutes, authProtectedRoutes }
