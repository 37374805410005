import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardText,
  UncontrolledAlert,
} from "reactstrap"
import PropTypes from "prop-types"
import classnames from "classnames"
import StoreInfo from "./storeInfo"
import Photo from "./photo"
import WorkingHours from "./workingHours"
import WeeklyDays from "./weeklyDays"
//redux
import { useSelector, useDispatch } from "react-redux"
import {
  fetchStore,
  searchAddress,
  onboardingLink,
  updateStore,
  changePreloader,
  uploadStorePhoto,
  resetStoreState,
} from "../../store/actions"

const MyStore = props => {
  const dispatch = useDispatch()
  const {
    storeFetchSuccess,
    storeFetchError,
    store,
    success,
    error,
    addresses,
    loading,
    storePhotoUploadComplete,
    storePhotoUploadLoading,
    storePhotoUploadError,
  } = useSelector(state => ({
    storeFetchSuccess: state.store.storeFetchSuccess,
    storeFetchError: state.store.storeFetchError,
    loading: state.store.storeFetchLoading,
    store: state.store.store,
    addresses: state.Account.addresses,
    success: state.store.storeUpdateComplete,
    error: state.store.storeUpdateError,
    storePhotoUploadComplete: state.store.storePhotoUploadComplete,
    storePhotoUploadLoading: state.store.storePhotoUploadLoading,
    storePhotoUploadError: state.store.storePhotoUploadError,
  }))
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertError, setAlertError] = useState(false)
  useEffect(() => {
    return () => {
      dispatch(resetStoreState())
    }
  }, [])
  useEffect(() => {
    if (!storeFetchSuccess && !loading) {
      dispatch(fetchStore())
    }
    if (storeFetchSuccess) {
      dispatch(changePreloader(false))
    }
  }, [store, storeFetchSuccess, storeFetchError])

  useEffect(() => {
    if ((success && !error && !loading) || storePhotoUploadComplete) {
      //alert on success
      setAlertSuccess(true)
      setTimeout(function () {
        setAlertSuccess(false)
      }, 5000)
    }
    if ((error && !loading) || storePhotoUploadError) {
      //alert
      setAlertError(true)
      setTimeout(function () {
        setAlertError(false)
      }, 5000)
    }
  }, [error, success, storePhotoUploadComplete, storePhotoUploadError])

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  const updateChanges = data => {
    dispatch(updateStore(data))
  }
  const updateStoreStatus = data => {
    dispatch(updateStore(data))
  }

  const uploadPhoto = data => {
    dispatch(uploadStorePhoto(data))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>My Store | The Convenience App</title>
        </MetaTags>
        <Container fluid>
          <h4>My Store</h4>
          <Row>
            <Col xl={9}>
              <Card>
                <CardBody>
                  {alertError ? (
                    <UncontrolledAlert
                      color="danger"
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      {error}{" "}
                    </UncontrolledAlert>
                  ) : null}
                  {alertSuccess ? (
                    <UncontrolledAlert
                      color="success"
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      Changes saved successfully.
                    </UncontrolledAlert>
                  ) : null}
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                          setAlertError(false)
                          setAlertSuccess(false)
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span
                          className="d-none d-sm-block"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          BASIC INFORMATION
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                          setAlertError(false)
                          setAlertSuccess(false)
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">UPLOAD PHOTO</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3")
                          setAlertError(false)
                          setAlertSuccess(false)
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">WORKING HOURS</span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "4",
                          })}
                          onClick={() => {
                            toggleCustom("4")
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span className="d-none d-sm-block" >WEEKLY DEALS</span>
                        </NavLink>
                      </NavItem> */}
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <StoreInfo
                            store={store}
                            updateChanges={updateChanges}
                            addresses={addresses}
                            searchAddress={searchAddress}
                            dispatch={dispatch}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <Photo store={store} uploadPhoto={uploadPhoto} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <WorkingHours
                            store={store}
                            updateChanges={updateChanges}
                            dispatch={dispatch}
                            updateStoreStatus={updateStoreStatus}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <WeeklyDays
                            store={store}
                            updateChanges={updateChanges}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3"></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyStore

MyStore.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}
