import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link, useHistory } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  Container,
  CardBody,
  Badge,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  CardFooter,
} from "reactstrap"
import "../../assets/scss/custom/pages/style.css"
//Table
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Skeleton
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
//Pagination
import ReactPaginate from "react-paginate"
//Redux
import { useSelector, useDispatch } from "react-redux"
import {
  fetchProducts,
  updateProduct,
  resetProductState,
} from "../../store/actions"
//Images
import noImage from "../../assets/images/inventory/no-image.png"
import tick from "../../assets/images/inventory/tick.png"
import edit from "../../assets/images/inventory/edit.png"
import remove from "../../assets/images/inventory/remove.png"
const InventoryManagement = props => {
  const {
    productsFetchError,
    productsFetchSuccess,
    productsFetchLoading,
    products,
    updateProductLoading,
    updateProductError,
    updateProductSuccess,
  } = useSelector(state => ({
    productsFetchError: state.products.productsFetchError,
    productsFetchSuccess: state.products.productsFetchSuccess,
    productsFetchLoading: state.products.productsFetchLoading,
    products: state.products.products,
    updateProductSuccess: state.products.updateProductSuccess,
    updateProductError: state.products.updateProductError,
    updateProductLoading: state.products.updateProductLoading,
  }))
  const history = useHistory()
  const dispatch = useDispatch()
  const [showAlert, setShowAlert] = useState(false)
  const [quantity, setquantity] = useState(0)
  const [editQuantity, setEditQuantity] = useState({
    val: true,
    ind: -1,
    id: -1,
  })
  const [productResult, setProductResult] = useState(
    products ? products.results : null
  )
  const [searchTerm, setSearchTerm] = useState("")
  //paginate
  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 10

  useEffect(() => {
    if (
      !productsFetchSuccess &&
      !productsFetchLoading &&
      (productsFetchError === null || typeof productsFetchError === "undefined")
    ) {
      dispatch(fetchProducts(itemOffset, searchTerm))
    }
  }, [products, searchTerm, itemOffset])

  useEffect(() => {
    if (productResult != null) {
      setCurrentItems(productResult)
      setPageCount(Math.ceil(products.count / itemsPerPage))
    }
  }, [itemOffset, productResult, searchTerm])

  useEffect(() => {
    products ? setProductResult(products.results) : null
  }, [products])

  useEffect(() => {
    if (!updateProductLoading && updateProductSuccess && !updateProductError) {
      dispatch(fetchProducts(itemOffset, searchTerm))
      setEditQuantity({ val: false })
      setShowAlert(true)
      dispatch(resetProductState())
    }
  }, [updateProductSuccess])
  useEffect(() => {
    if (showAlert) {
      setTimeout(function () {
        setShowAlert(false)
      }, 5000)
    }
  }, [showAlert])

  const updateQuantity = () => {
    const data = {
      quantity: quantity,
    }
    if (editQuantity.id && editQuantity.id > 0) {
      dispatch(updateProduct(data, editQuantity.id))
    }
  }
  const fetchSearchTerm = value => {
    dispatch(fetchProducts(0, value))
  }
  const fetchNewOffsetItems = offset => {
    dispatch(fetchProducts(offset, searchTerm))
  }
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % products.count
    setItemOffset(newOffset)
    fetchNewOffsetItems(newOffset)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inventory Management | The Convenience App</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h4>Inventory Management</h4>
                  <div className="float-end mb-1">
                    <label
                      className="btn mt-2"
                      style={{
                        color: "white",
                        background: "#76C4CC",
                        width: "150px",
                        height: "35px",
                        marginRight: 10,
                      }}
                    >
                      Add Product
                      <input
                        type="button"
                        style={{ display: "none" }}
                        onClick={() => {
                          history.push("/products/0")
                        }}
                      />
                    </label>
                    <input
                      type="search"
                      placeholder="Search by Name ..."
                      className="m-0 px-3"
                      style={{
                        borderRadius: 5,
                        borderWidth: 0,
                        width: "250px",
                        height: "40px",
                        marginRight: 10,
                      }}
                      onChange={() => {
                        let value = event.target.value.toLowerCase()
                        // const results = products.results.filter(product =>
                        //   product.name.toLowerCase().includes(value)
                        // )
                        setSearchTerm(value)
                        fetchSearchTerm(value)
                        //setProductResult(results)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              {/* Alert Here*/}
              {showAlert ? (
                <UncontrolledAlert
                  variant="success"
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  <p className="mb-0">
                    <i className="mdi mdi-check-all me-2"></i>
                    Product Updated !
                  </p>
                </UncontrolledAlert>
              ) : null}

              <Card style={{ borderRadius: 10 }}>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0 px-1"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table align-middle table-borderless mb-0 "
                      >
                        <Thead>
                          <Tr className="font-size-14">
                            <Th className="col-md-1"></Th>
                            <Th className="col-md-2">Name</Th>
                            <Th className="col-md-2 col-sm-3">Quantity</Th>
                            <Th className="col-md-2">Tags</Th>
                            <Th className="col-md-2">Status</Th>
                            <Th className="col-md-2">Price</Th>
                            <Th className="col-md-2">Tax</Th>
                            <Th className="col-md-2"></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentItems && !productsFetchLoading ? (
                            currentItems.map((product, index) => (
                              <Tr key={index}>
                                <Th>
                                  <img
                                    src={
                                      product.image ? product.image : noImage
                                    }
                                    alt="Header Avatar"
                                    width="36px"
                                    height="36px"
                                  />
                                </Th>
                                <Th>
                                  <span className="co-name">
                                    {product.name}
                                  </span>
                                </Th>
                                <Td style={{ whiteSpace: "nowrap" }}>
                                  {editQuantity.val &&
                                  editQuantity.ind == index ? (
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <InputGroup
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <InputGroupAddon
                                          addonType="prepend"
                                          style={{ margin: 4 }}
                                        >
                                          <Button
                                            size="sm"
                                            style={{
                                              borderRadius: 25,
                                              background: "#76C4CC",
                                              borderColor: "#76C4CC",
                                              width: 27,
                                              height: 27,
                                              textAlign: "center",
                                              alignItems: "center",
                                            }}
                                            onClick={() => {
                                              if (quantity !== 0) {
                                                setquantity(quantity - 1)
                                              }
                                            }}
                                          >
                                            -
                                          </Button>
                                        </InputGroupAddon>
                                        <Input
                                          type="text"
                                          pattern="[0-9]*"
                                          value={quantity}
                                          onChange={() => {
                                            let value = Number(
                                              event.target.value
                                            )
                                            setquantity(value)
                                          }}
                                          className="px-0"
                                          style={{
                                            textAlign: "center",
                                            borderRadius: 5,
                                            height: "35px",
                                            maxWidth: "40px",
                                            minWidth: "30px",
                                          }}
                                        />
                                        <InputGroupAddon
                                          addonType="append"
                                          style={{
                                            margin: 4,
                                          }}
                                        >
                                          <Button
                                            size="sm"
                                            style={{
                                              borderRadius: 25,
                                              background: "#76C4CC",
                                              borderColor: "#76C4CC",
                                              width: 27,
                                              height: 27,
                                            }}
                                            onClick={() => {
                                              setquantity(quantity + 1)
                                            }}
                                          >
                                            +
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                      {updateProductLoading ? (
                                        <Col style={{ alignSelf: "center" }}>
                                          <i className="bx bx-loader bx-spin font-size-16 align-middle" />
                                        </Col>
                                      ) : (
                                        <Col style={{ alignSelf: "center" }}>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              updateQuantity()
                                            }}
                                            className="text-success m-1"
                                          >
                                            <img
                                              src={tick}
                                              alt="Header Avatar"
                                              style={{
                                                marginLeft: 10,
                                              }}
                                              width="12px"
                                            />
                                          </Link>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setEditQuantity({ val: false })
                                            }}
                                            className="text-success m-1"
                                          >
                                            <img
                                              src={remove}
                                              alt="Header Avatar"
                                              style={{ marginLeft: 5 }}
                                              width="10px"
                                            />
                                          </Link>
                                        </Col>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <Badge
                                        style={{
                                          width: "25%",
                                          minWidth: "50px",
                                        }}
                                        className={
                                          product.quantity < 5
                                            ? "font-size-12 bg-danger rounded-pill"
                                            : "font-size-12 bg-success rounded-pill"
                                        }
                                      >
                                        {product.quantity}
                                      </Badge>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setEditQuantity({
                                            val: true,
                                            ind: index,
                                            id: product.id,
                                          })
                                          setquantity(product.quantity)
                                        }}
                                        className="text-success"
                                      >
                                        <img
                                          src={edit}
                                          alt="Header Avatar"
                                          style={{ marginLeft: 10 }}
                                          width="14px"
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </Td>
                                <Td>
                                  <Badge className={"font-size-12 bg-light "}>
                                    {product.tags
                                      .map(tag => tag.name)
                                      .join(", ")}
                                  </Badge>
                                </Td>
                                <Td>
                                  <Badge
                                    className={
                                      product.is_active
                                        ? "font-size-12 bg-dark rounded-pill"
                                        : "font-size-12 bg-secondary rounded-pill"
                                    }
                                  >
                                    {product.is_active ? "Active" : "InActive"}
                                  </Badge>
                                </Td>
                                <Td>{product.price}</Td>
                                <Td>
                                  {product.tax_percent
                                    ? product.tax_percent.slice(
                                        0,
                                        product.tax_percent.length - 1
                                      )
                                    : 0}
                                  %
                                </Td>
                                <Td
                                  style={{
                                    whiteSpace: "nowrap",
                                    color: "#67BFC8",
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname: "/products/" + product.id,
                                      page: "View",
                                    }}
                                    className="text-success"
                                  >
                                    View Details
                                  </Link>
                                </Td>
                              </Tr>
                            ))
                          ) : (
                            <Tr>
                              <Th className="col-md-1">
                                {" "}
                                <Skeleton width="36px" />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                              <Th className="col-md-2">
                                {" "}
                                <Skeleton />{" "}
                              </Th>
                            </Tr>
                          )}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
                {products && products.count > itemsPerPage ? (
                  // && productResult.length > itemsPerPage
                  <CardFooter>
                    <div className="float-end">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="bg-custom-blue text-white"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </CardFooter>
                ) : productResult && productResult.length == 0 ? (
                  <h4 className="mt-2 mb-4" style={{ textAlign: "center" }}>
                    No product found !
                  </h4>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InventoryManagement
