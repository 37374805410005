import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
  getRequest,
  postRequest,
  patchRequest,
  getErrorMessage,
  patchMultiPartRequest,
  getQueryParams,
  postRequestForm
} from "../../helpers/apiHelper"
import * as url from "../../helpers/urlHelper"
//Account Redux states
import {
  FETCH_TAGS,
  FETCH_PRODUCT,
  FETCH_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_PHOTO,
  SAVE_PRODUCT,
} from "./actionTypes"
import {
  fetchTagsSuccess,
  fetchTagsError,
  resetProduct,
  fetchProductSuccess,
  fetchProductError,
  fetchProductsSuccess,
  fetchProductsError,
  saveProductSuccess,
  saveProductError,
  updateProductSuccess,
  updateProductError,
  updateProductPhotoSuccess,
  updateProductPhotoError,
} from "./actions"

function* fetchTags() {
  try {
    let params = {
      limit: 100,
    }
    const response = yield call(
      getRequest,
      url.FETCH_TAGS_URL + getQueryParams(params),
      true
    )
    yield put(fetchTagsSuccess(response.results))
  } catch (error) {
    yield put(fetchTagsError(getErrorMessage(error)))
  }
}

function* fetchProducts({ payload: { offset, search } }) {
  try {
    let params = {
      limit: 10,
      offset: offset,
      search: search,
    }
    const response = yield call(
      getRequest,
      url.PRODUCTS_URL + getQueryParams(params),
      true
    )
    yield put(fetchProductsSuccess(response))
  } catch (error) {
    yield put(fetchProductsError(getErrorMessage(error)))
  }
}

function* fetchProduct({ payload: { id } }) {
  try {
    const response = yield call(getRequest, url.PRODUCTS_URL + id + '/', true)
    yield put(fetchProductSuccess(response))
  } catch (error) {
    yield put(fetchProductError(getErrorMessage(error)))
  }
}

function* saveProduct({ payload: { data } }) {
  try {
    // console.log(data,"data")
    const response = yield call(postRequestForm, url.PRODUCTS_URL, data, true);
    yield put(saveProductSuccess(response))
  } catch (error) {
    yield put(saveProductError(getErrorMessage(error)))
  }
}

function* updateProduct({ payload: { data, id } }) {
  try {
    console.log('Updating product with ID:', id);
    console.log('Request payload:', data);

    const response = yield call(patchMultiPartRequest, url.PRODUCTS_URL + id + '/', data, true);

    console.log('API Response:', response);

    yield put(updateProductSuccess(response));
  } catch (error) {
    console.error('Error updating product:', error);
    yield put(updateProductError(getErrorMessage(error)));
  }
}

function* updateProductPhoto({ payload: { data, id } }) {
  try {
    console.log('Updating product photo with ID:', id);
    console.log('Request payload:', data);

    const response = yield call(patchMultiPartRequest, url.PRODUCTS_URL + id + '/', data, true);

    console.log('API Response:', response);

    yield put(updateProductPhotoSuccess(response));
  } catch (error) {
    console.error('Error updating product photo:', error);
    yield put(updateProductPhotoError(getErrorMessage(error)));
  }
}


export function* watchFetchTags() {
  yield takeEvery(FETCH_TAGS, fetchTags)
}
export function* watchFetchProducts() {
  yield takeEvery(FETCH_PRODUCTS, fetchProducts)
}
export function* watchFetchProduct() {
  yield takeEvery(FETCH_PRODUCT, fetchProduct)
}
export function* watchSaveProduct() {
  yield takeEvery(SAVE_PRODUCT, saveProduct)
}
export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct)
}
export function* watchUpdateProductPhoto() {
  yield takeEvery(UPDATE_PRODUCT_PHOTO, updateProductPhoto)
}
function* productsSaga() {
  yield all([
    fork(watchFetchTags),
    fork(watchSaveProduct),
    fork(watchUpdateProduct),
    fork(watchUpdateProductPhoto),
    fork(watchFetchProducts),
    fork(watchFetchProduct),
  ])
}

export default productsSaga
