import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  ONBOARDING_LINK,
  ONBOARDING_LINK_SUCCESS,
  ONBOARDING_LINK_ERROR
} from "./actionTypes"

const initialState = {
  error: "",
  success:false,
  loading: false,
  onboardingError: null,
  link:"",
  onboardingSuccess:false,
  onboardingLoading:false
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        success:true,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case ONBOARDING_LINK:
      state = {
        ...state,
        onboardingSuccess:true,
        onboardingLoading:true,
        onboardingError:null
      }
      break
    case ONBOARDING_LINK_SUCCESS:
      state = {
        ...state,
        link:action.payload,
        onboardingLoading:false,
        onboardingSuccess:true,
        onboardingError:null
      }
      break
    case ONBOARDING_LINK_ERROR:
      state = { 
        ...state, 
        onboardingError: action.payload, 
        onboardingLoading:false,
        onboardingSuccess:true
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
