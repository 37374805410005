import React, { useState, useEffect } from "react"
import {useForm} from 'react-hook-form';
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  Container,
  CardBody,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
  UncontrolledAlert,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import Switch from "react-switch"
import Select from "react-select"
import noImage from "../../assets/images/inventory/no-image.png"
import backImage from "../../assets/images/inventory/back.png"
import {
  fetchTags,
  fetchProduct,
  saveProduct,
  updateProduct,
  updateProductPhoto,
  resetProductState,
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { OnSymbol, Offsymbol } from "../../helpers/utils"
import ImageView from "common/imageView"
const AddEditProduct = props => {
  const history = useHistory()
  const id = props.match.params.id
  const dispatch = useDispatch()
  const {
    tagsFetchSuccess,
    tagsFetchError,
    tagsFetchLoading,
    tags,
    saveProductSuccess,
    saveProductError,
    saveProductLoading,
    updateProductLoading,
    updateProductError,
    updateProductSuccess,
    productFetchError,
    productFetchSuccess,
    productFetchLoading,
    updateProductPhotoSuccess,
    product,
  } = useSelector(state => ({
    tagsFetchSuccess: state.products.tagsFetchSuccess,
    tagsFetchError: state.products.tagsFetchError,
    tagsFetchLoading: state.products.tagsFetchLoading,
    tags: state.products.tags,
    saveProductSuccess: state.products.saveProductSuccess,
    saveProductError: state.products.saveProductError,
    saveProductLoading: state.products.saveProductLoading,
    updateProductSuccess: state.products.updateProductSuccess,
    updateProductPhotoSuccess: state.products.updateProductPhotoSuccess,
    updateProductError: state.products.updateProductError,
    updateProductLoading: state.products.updateProductLoading,
    productFetchError: state.products.productFetchError,
    productFetchSuccess: state.products.productFetchSuccess,
    productFetchLoading: state.products.productFetchLoading,
    product: state.products.product,
  }))
  const [active, setactive] = useState(true)
  const [name, setName] = useState("")
  const [description, setdescription] = useState("")
  const [quantity, setquantity] = useState(0)
  const [selectedTags, setselectedTags] = useState(null)
  const [selectedPhoto, setselectedPhoto] = useState(null)
  const [price, setprice] = useState("")
  const [tax, settax] = useState("")
  const [tagOptions, settagOptions] = useState([])
  const [photo, setPhoto] = useState(null)
  const [clearActive, setClearActive] = useState(false)
  useEffect(() => {
    return () => {
      dispatch(resetProductState())
    }
  }, [])

  useEffect(() => {
    if (
      !tagsFetchSuccess &&
      !tagsFetchLoading &&
      (tagsFetchError === null || typeof tagsFetchError === "undefined")
    ) {
      dispatch(fetchTags())
    }
    if (tags && tags.length > 0) {
      const tagList = [
        {
          options: tags.map(tag => {
            return { label: tag.name, value: tag.id }
          }),
        },
      ]
      settagOptions(tagList)
    }
  }, [tags, tagsFetchSuccess, tagsFetchError, tagsFetchLoading])

  const defaultValues = () => {
    setName(product.name)
    setdescription(product.description)
    setquantity(product.quantity)
    setactive(product.is_active)
    setprice(product.price)
    settax(product.tax_percent)
    setPhoto(product.image)
    setselectedTags(
      product.tags.map(tag => {
        return { label: tag.name, value: tag.id }
      })
    )
  }

  useEffect(() => {
    if (
      !productFetchSuccess &&
      !productFetchLoading &&
      (productFetchError === null || typeof productFetchError === "undefined")
    ) {
      if (parseInt(id) > 0) {
        dispatch(fetchProduct(id))
      }
    }
    if (product && parseInt(product.id) === parseInt(id)) {
      defaultValues()
    }
  }, [product, productFetchSuccess, productFetchError, productFetchLoading])

  useEffect(() => {
    if (
      saveProductSuccess &&
      !saveProductLoading &&
      (saveProductError === null || typeof saveProductError === "undefined")
    ) {
      //upload product photo here
      const formData = new FormData()
      selectedPhoto ? formData.append("image", selectedPhoto) : null
      if (product.id) {
        dispatch(updateProductPhoto(formData, product.id))
      }
      history.push("/products")
    }
    if (
      updateProductSuccess &&
      !updateProductLoading &&
      (updateProductError === null || typeof updateProductError === "undefined")
    ) {
      //upload product photo here
      const formData = new FormData()
      selectedPhoto ? formData.append("image", selectedPhoto) : null
      if (product && product.id && selectedPhoto) {
        dispatch(updateProductPhoto(formData, product.id))
      }
      // console.log("here")
      if (selectedPhoto) {
        // console.log("here1")
        if (updateProductSuccess) {
          // console.log("here")
          history.push("/products")
        }
      } else {
        // console.log("here")
        history.push("/products")
      }
    }
  }, [
    saveProductSuccess,
    saveProductError,
    saveProductLoading,
    updateProductSuccess,
    updateProductError,
    updateProductLoading,
    updateProductPhotoSuccess,
  ])

  function imageSize(base64Str) {
    return new Promise(resolve => {
      const width = 256
      const height = 256
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement("canvas")
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  const onProductPhoto = async event => {
    event.stopPropagation()
    event.preventDefault()
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      const base64Str = await toBase64(file)
      const resizedStr = await imageSize(base64Str)
      try {
        fetch(resizedStr)
          .then(res => res.blob())
          .then(data => {
            const finalfile = new File([data], file.name, {
              type: file.type,
            })
            setselectedPhoto(finalfile)
          })
      } catch (e) {
        // console.log(e)
      }
    }
    setClearActive(true)
  }
  function handlePhoto(selectedPhoto) {
    setselectedPhoto(selectedPhoto)
  }

  function handleAcceptedFiles(file) {
    setselectedPhoto(file)
    //setselectedPhoto(URL.createObjectURL(new Blob(file, {type: "application/zip"})))
  }

  const filterBySize = file => {
    //filter out images larger than 5MB
    return file.size <= 5242880
  }

  const submitData = () => {
    if (validateData()) {
      saveUpdateData()
    }
  }

  const saveUpdateData = () => {
    const data = {
      name: name,
      description: description,
      price: price,
      tax_percent: tax,
      quantity: quantity,
      is_active: active,
      sort_order: 100,
      tags: selectedTags.map(tag => tag.value),
      image:selectedPhoto
    }
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('tax_percent', tax);
    formData.append('quantity', quantity);
    formData.append('is_active', active);
    formData.append('sort_order', 100);
    formData.append('tags', selectedTags.map(tag => tag.value));
    formData.append('image', selectedPhoto); // Assuming selectedPhoto is a File object
    for (const entry of formData.entries()) {
      // console.log(entry);
    }
    if (id && id > 0) {
      // console.log(id,"id")
      dispatch(updateProduct(formData, id))
    } else {
      // console.log(id,"id1")
      dispatch(saveProduct(formData))
    }
  }

  const validateData = () => {
    if (
      name === "" ||
      price < 0 ||
      tax < 0 ||
      !selectedTags ||
      selectedTags.length === 0
    ) {
      setShowAlert(true)
      setTimeout(function () {
        setShowAlert(false)
      }, 5000)
      return false
    }
    return true
  }

  const [readOnly, setReadOnly] = useState(
    props.match.params.id != "0" ? true : false
  )
  const [showAlert, setShowAlert] = useState(false)
  const [openImageView, setOpenImageView] = useState(false)
  const toggleImageView = () => setOpenImageView(!openImageView)
  const [priceError, setPriceError] = useState('');
  const [taxError, setTaxError] = useState('');

  const handlePriceChange = (e) => {
    // console.log('at 293 **************',e.target.value)
    // const inputPrice = parseFloat(e.target.value);
    if (e.target.value >= 0) {
      setprice(e.target.value);
      setPriceError('');
    } else {
      setPriceError('Please enter a positive number');
    }
  };

  const handleTaxChange = (e) => {
    // console.log('at 304 *********************', e.target.value)
    // const inputTax = parseFloat(e.target.value);
    if (e.target.value >= 0) {
      settax(e.target.value);
      setTaxError('');
    } else {
      setTaxError('Please enter a positive number');
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product | The Convenience App</title>
        </MetaTags>
        <Container fluid>
          <ImageView 
            isOpen={openImageView}
            image={
              selectedPhoto
              ? URL.createObjectURL(selectedPhoto)
              : photo
              ? photo
              : noImage
            }
            toggle={toggleImageView}
          />
          <Row>
            <Col style={{ whiteSpace: "nowrap" }}>
              <img
                className="float-start me-3 my-1 py-0"
                style={{ cursor: "pointer" }}
                src={backImage}
                width="18px"
                height="18px"
                onClick={() => {
                  history.goBack()
                }}
              ></img>
              <h3 height="18px" style={{}}>
                {props.match.params.id == "0"
                  ? "Add Product"
                  : "Product Details"}
              </h3>
            </Col>
            <Col>
              {props.match.params.id != "0" && readOnly ? (
                <label
                  className="btn btn-success float-end"
                  style={{
                    background: "#76C4CC",
                    width: "150px",
                    height: "35px",
                  }}
                >
                  Edit Product
                  <input
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => setReadOnly(false)}
                  />
                </label>
              ) : null}
            </Col>
          </Row>
          {showAlert ? (
            <UncontrolledAlert
              color="secondary"
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <p className="mb-0">Please fill all fields!!</p>
            </UncontrolledAlert>
          ) : null}
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col sm="6">
                  <label className="col-md-6 col-form-label">Name*</label>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      value={name}
                      readOnly={readOnly}
                      onChange={e => {
                        setClearActive(true)
                        setName(e.target.value)
                      }}
                      placeholder="Type Name here"
                    />
                  </div>
                </Col>
              </Row>

              <div className="mb-3 w-75">
                <Label htmlFor="productdesc">Product Description</Label>
                <textarea
                  value={description}
                  readOnly={readOnly}
                  className="form-control mb-3"
                  id="productdesc"
                  onChange={e => {
                    setClearActive(true)
                    setdescription(e.target.value)
                  }}
                  rows="5"
                />
              </div>
              <Row className="mb-3">
                <Col sm="6">
                  <label className="col-md-6 col-form-label">Quantity*</label>
                  <div style={{ width: "140px", marginLeft: 20 }}>
                    <InputGroup>
                      <InputGroupAddon
                        addonType="prepend"
                        style={{ margin: 5 }}
                      >
                        <Button
                          size="sm"
                          style={{
                            borderRadius: 25,
                            width: 32,
                            height: 32,
                          }}
                          disabled={readOnly}
                          onClick={() => {
                            if (quantity !== 0) {
                              setquantity(quantity - 1)
                            }
                          }}
                        >
                          -
                        </Button>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        readOnly={readOnly}
                        value={quantity}
                        style={{ textAlign: "center", borderRadius: 8 }}
                        onChange={() => {
                          let value = Number(event.target.value)
                          setquantity(value)
                          setClearActive(true)
                        }}
                      />
                      <InputGroupAddon addonType="append" style={{ margin: 5 }}>
                        <Button
                          size="sm"
                          style={{
                            borderRadius: 25,
                            width: 32,
                            height: 32,
                          }}
                          disabled={readOnly}
                          onClick={() => {
                            setquantity(quantity + 1)
                          }}
                        >
                          +
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                </Col>
                <Col sm="6">
                  <label className="col-md-6 col-form-label">Photo</label>
                  <div>
                    <img
                      height="30px"
                      width="30px"
                      style={{ borderRadius: "20px", marginLeft: "5px", cursor:'pointer' }}
                      src={
                        selectedPhoto
                          ? URL.createObjectURL(selectedPhoto)
                          : photo
                          ? photo
                          : noImage
                      }
                      onClick={()=>{
                        if(photo || selectedPhoto){
                          toggleImageView()
                        }
                      }}
                    />
                    <input
                      type="file"
                      name="myImage"
                      disabled={readOnly}
                      style={{ marginLeft: "5px" }}
                      onChange={onProductPhoto}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm="6">
                  <label className="col-md-6 col-form-label">Tags*</label>
                  <div className="col-md-6">
                    <Select
                      value={selectedTags}
                      isMulti={true}
                      isDisabled={readOnly}
                      onChange={tagsArr => {
                        setClearActive(true)
                        setselectedTags(tagsArr)
                      }}
                      options={tagOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <label className="col-md-6 col-form-label">Status*</label>
                  <div style={{ marginLeft: 10 }}>
                    <Col sm="6">
                      <Row>
                        <Col className="col-sm-3 col-lg-2">
                          <Label
                            className="py-1"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Active
                          </Label>
                        </Col>
                        <Col sm="4">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="ms-2 me-1 mb-sm-8 mb-2"
                            onColor="#76C4CC"
                            disabled={readOnly}
                            onChange={() => {
                              setClearActive(true)
                              setactive(!active)
                            }}
                            checked={active}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="6">
                <label className="col-md-6 col-form-label">Price*</label>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      step="any"
                      // readOnly={readOnly}
                      value={price}
                      onChange={handlePriceChange}
                      // onChange={e => {
                      //   const inputPrice = e.target.value;
                      //   if (inputPrice >= 0) {
                      //     setClearActive(true);
                      //     setprice(inputPrice);
                      //   }
                      // }}
                      placeholder="Type Item price here"
                    />
                    {priceError && <div className="text-danger">{priceError}</div>}
                  </div>
                </Col>
                <Col sm="6">
                  <label className="col-md-6 col-form-label">Tax %*</label>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      step="any"
                      readOnly={readOnly}
                      value={tax}
                      onChange={handleTaxChange}
                      // onChange={e => {
                      //   const inputPrice = e.target.value;
                      //   if (inputPrice >= 0) {
                      //     setClearActive(true);
                      //     setprice(inputPrice);
                      //   }
                      // }}
                      placeholder="Type Item tax rate here"
                    />
                    {taxError && <div className="text-danger">{taxError}</div>}
                  </div>
                </Col>
              </Row>
              {!readOnly ? (
                <div>
                  <hr />
                  <label
                    className="btn btn-success float-end ms-3"
                    style={{
                      background: "#76C4CC",
                      width: "150px",
                      height: "35px",
                    }}
                  >
                    {saveProductLoading || updateProductLoading ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                    ) : null}
                    Save Changes
                    <input
                      type="button"
                      style={{ display: "none" }}
                      onClick={() => submitData()}
                    />
                  </label>
                  <label
                    className="btn btn-success float-end"
                    style={{
                      background: "#ffffff",
                      color: "#76C4CC",
                      borderColor: "#76C4CC",
                      width: "120px",
                      height: "35px",
                    }}
                  >
                    Cancel
                    <input
                      type="button"
                      style={{ display: "none" }}
                      onClick={() => {
                        props.match.params.id == "0"
                          ? history.goBack()
                          : defaultValues()
                        setReadOnly(true)
                      }}
                    />
                  </label>
                </div>
              ) : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddEditProduct

AddEditProduct.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
}
