import {
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_ERROR,
  GET_ORDERS_LIST_SUCCESS,
  GET_PAST_ORDERS,
  GET_PAST_ORDERS_ERROR,
  GET_PAST_ORDERS_SUCCESS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_ERROR,
  UPDATE_ORDER_ITEM_QUANTITY,
  UPDATE_ORDER_ITEM_QUANTITY_SUCCESS,
  UPDATE_ORDER_ITEM_QUANTITY_ERROR,
  RESET_ORDER_QUANTITY_STATE,
} from "./actionTypes"

export const getOrdersList = (search, is_curbside_pickup) => ({
  type: GET_ORDERS_LIST,
  payload: { search, is_curbside_pickup },
})

export const getOrdersListSuccess = orders => ({
  type: GET_ORDERS_LIST_SUCCESS,
  payload: orders,
})

export const getOrdersListFail = error => ({
  type: GET_ORDERS_LIST_ERROR,
  payload: error,
})
export const getPastOrders = (offset, search, is_curbside_pickup, status) => {
  return {
    type: GET_PAST_ORDERS,
    payload: { offset, search, is_curbside_pickup, status },
  }
}

export const getPastOrdersSuccess = orders => ({
  type: GET_PAST_ORDERS_SUCCESS,
  payload: orders,
})

export const getPastOrdersFail = error => ({
  type: GET_PAST_ORDERS_ERROR,
  payload: error,
})

export const updateOrderStatus = (data, id) => {
  return {
    type: UPDATE_ORDER_STATUS,
    payload: { data, id },
  }
}
export const updateOrderStatusSuccess = order => {
  return {
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload: order,
  }
}
export const updateOrderStatusError = error => {
  return {
    type: UPDATE_ORDER_STATUS_ERROR,
    payload: error,
  }
}
export const updateOrderItemQuantity = (data, id, itemID) => {
  return {
    type: UPDATE_ORDER_ITEM_QUANTITY,
    payload: { data, id, itemID },
  }
}
export const updateOrderItemQuantitySuccess = order => {
  return {
    type: UPDATE_ORDER_ITEM_QUANTITY_SUCCESS,
    payload: order,
  }
}
export const updateOrderItemQuantityError = error => {
  return {
    type: UPDATE_ORDER_ITEM_QUANTITY_ERROR,
    payload: error,
  }
}
export const resetOrderQuantityState = () => {
  return {
    type: RESET_ORDER_QUANTITY_STATE,
  }
}
