import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
  getRequest,
  postRequest,
  getQueryParams,
  getErrorMessage,
} from "../../../helpers/apiHelper"
import * as url from "../../../helpers/urlHelper"
//Account Redux states
import { REGISTER_USER, SEARCH_ADDRESS } from "./actionTypes"
import {
  registerUserSuccessful,
  registerUserFailed,
  searchAddressSuccessful,
  searchAddressFailed,
} from "./actions"

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(postRequest, url.REGISTER_USER_URL, user)
    localStorage.setItem("authUser", response.key)
    yield put(registerUserSuccessful())
  } catch (error) {
    yield put(registerUserFailed(getErrorMessage(error)))
  }
}

function* searchAddress({ payload: { keyword } }) {
  try {
    let params = {
      search: keyword,
    }
    const response = yield call(
      getRequest,
      url.SEARCH_ADDRESSES_URL + getQueryParams(params)
    )
    yield put(searchAddressSuccessful(response))
  } catch (error) {
    yield put(searchAddressFailed(getErrorMessage(error)))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

export function* watchSearchAddress() {
  yield takeEvery(SEARCH_ADDRESS, searchAddress)
}

function* accountSaga() {
  yield all([fork(watchUserRegister), fork(watchSearchAddress)])
}

export default accountSaga
