import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
  getRequest,
  patchRequest,
  getErrorMessage,
  patchMultiPartRequest,
} from "../../helpers/apiHelper"
import * as url from "../../helpers/urlHelper"
//Account Redux states
import { FETCH_STORE, UPDATE_STORE, UPLOAD_STORE_PHOTO } from "./actionTypes"
import {
  fetchStoresSucces,
  fetchStoreError,
  updateStoreSucces,
  updateStoreError,
  uploadStorePhotoSucces,
  uploadStorePhotoError,
} from "./actions"

function* fetchStore() {
  try {
    const response = yield call(getRequest, url.STORE_URL, true)
    yield put(fetchStoresSucces(response))
  } catch (error) {
    yield put(fetchStoreError(getErrorMessage(error)))
  }
}

function* updateStore({ payload: { data } }) {
  try {
    const response = yield call(patchRequest, url.STORE_URL, data, true)
    yield put(updateStoreSucces(response))
  } catch (error) {
    yield put(updateStoreError(getErrorMessage(error)))
  }
}
function* updateStorePhoto({ payload: { data } }) {
  try {
    const response = yield call(
      patchMultiPartRequest,
      url.STORE_URL,
      data,
      true
    )
    yield put(uploadStorePhotoSucces(response))
  } catch (error) {
    yield put(uploadStorePhotoError(getErrorMessage(error)))
  }
}

export function* watchFetchStore() {
  yield takeEvery(FETCH_STORE, fetchStore)
}

export function* watchUpdateStore() {
  yield takeEvery(UPDATE_STORE, updateStore)
}

export function* watchPhotoUpload() {
  yield takeEvery(UPLOAD_STORE_PHOTO, updateStorePhoto)
}

function* storeSaga() {
  yield all([
    fork(watchFetchStore),
    fork(watchUpdateStore),
    fork(watchPhotoUpload),
  ])
}

export default storeSaga
