import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import logoImg from "../../assets/images/store/logo.png"
import banner from "../../assets/images/store/banner.png"
import PropTypes from "prop-types"

const Photo = props => {
  const { store, uploadPhoto } = props
  const [logo, setlogo] = useState(null)
  const [photo, setphoto] = useState(null)
  const [logourl, setlogourl] = useState(null)
  const [photourl, setphotourl] = useState(null)
  const [clearActive, setClearActive] = useState(false)

  const defaultValues = () => {
    setlogourl(store.logo && store.logo)
    setphotourl(store.photo && store.photo)
  }
  useEffect(() => {
    //defaultValues()
  }, [[logourl, photourl]])

  const resetChanges = () => {
    defaultValues()
    setlogourl(null)
    setphotourl(null)
    setClearActive(false)
  }

  const updateData = () => {
    const formData = new FormData()
    photo ? formData.append("photo", photo) : null
    logo ? formData.append("logo", logo) : null
    uploadPhoto(formData)
    setClearActive(false)
  }

  function imageSize(base64Str, fileType) {
    return new Promise(resolve => {
      const width = fileType === "logo" ? 60 : 340
      const height = fileType === "logo" ? 60 : 90
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement("canvas")
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const onChangePhoto = async event => {
    event.stopPropagation()
    event.preventDefault()
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      const base64Str = await toBase64(file)
      const resizedStr = await imageSize(base64Str, "photo")
      try {
        fetch(resizedStr)
          .then(res => res.blob())
          .then(data => {
            const finalfile = new File([data], file.name, {
              type: file.type,
            })
            setphoto(finalfile)
            setphotourl(URL.createObjectURL(file))
          })
      } catch (e) {
        // console.log(e)
      }
    }
    setClearActive(true)
  }

  const onChangeLogo = async event => {
    event.stopPropagation()
    event.preventDefault()
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      const base64Str = await toBase64(file)
      const resizedStr = await imageSize(base64Str, "logo")
      try {
        fetch(resizedStr)
          .then(res => res.blob())
          .then(data => {
            const finalfile = new File([data], file.name, {
              type: file.type,
            })
            setlogo(finalfile)
            setlogourl(URL.createObjectURL(file))
          })
      } catch (e) {
        // console.log(e)
      }
    }
    setClearActive(true)
  }
  return (
    <React.Fragment>
      {" "}
      <h5 className="mt-4 mb-4">
        Upload Store Photo*
        <span className="font-size-12 ms-2" style={{ color: "red" }}>
          Photo Size should be 340 x 90px{" "}
        </span>
      </h5>
      <Row>
        <Col sm="12" md="7" lg="7" className="ps-4">
          {photourl ? (
            <img
              src={photourl ? photourl : banner}
              id={photourl ? "photo" : "banner"}
              alt="Header Avatar"
              width="340px"
              height="90px"
              style={{ borderRadius: "5px" }}
            />
          ) : store && store.photo !== null ? (
            <img
              src={store.photo}
              alt="Header Avatar"
              width="340px"
              height="90px"
              id="header"
              style={{ borderRadius: "5px" }}
            />
          ) : (
            <img src={banner} alt="Header Avatar" width="340px" height="90px" />
          )}
        </Col>
        <Col
          sm="12 mt-2 px-4"
          md="5"
          lg="5"
          style={{
            alignSelf: "end",
          }}
        >
          <label
            className="btn btn-success "
            style={{
              background: "#76C4CC",
              borderColor: "#76C4CC",
              width: "170px",
            }}
          >
            Upload Store Photo
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{ display: "none" }}
              onChange={onChangePhoto}
            />
          </label>
        </Col>
      </Row>
      <h5 className="mt-4">
        Upload Store Logo*
        <span className="font-size-12 ms-2" style={{ color: "red" }}>
          Photo Size should be 60 x 60px{" "}
        </span>
      </h5>
      <Row>
        <Col sm="12" md="7" lg="7" className="ps-4">
          {logourl ? (
            <img
              className="rounded-circle company-profile-user mt-4"
              src={logourl ? logourl : logoImg}
              alt="Header Avatar"
              width="60px"
              height="60px"
            />
          ) : store && store.logo !== null ? (
            <img
              className="rounded-circle company-profile-user mt-4"
              src={store.logo}
              alt="Header Avatar"
              width="60px"
              height="60px"
            />
          ) : (
            <img
              className="rounded-circle company-profile-user mt-4"
              src={logoImg}
              alt="Header Avatar"
              width="60px"
              height="60px"
            />
          )}
        </Col>
        <Col
          sm="12 mt-2 px-4"
          md="5"
          lg="5"
          style={{
            alignSelf: "end",
          }}
        >
          <label
            className="btn btn-success float-start"
            style={{
              background: "#76C4CC",
              borderColor: "#76C4CC",
              width: "170px",
            }}
          >
            Upload Logo
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              style={{ display: "none" }}
              onChange={onChangeLogo}
            />
          </label>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl={6}></Col>
        <Col xl={6}>
          <label
            className="btn btn-success float-end ms-3"
            style={{
              background: "#76C4CC",
              borderColor: "#76C4CC",
              width: "150px",
              height: "35px",
            }}
          >
            Save Changes
            <input
              type="button"
              style={{ display: "none" }}
              onClick={() => (clearActive ? updateData() : null)}
            />
          </label>
          {clearActive && (
            <label
              className="btn btn-success float-end"
              style={{
                background: "#ffffff",
                borderColor: "#76C4CC",
                color: "#76C4CC",
                width: "150px",
                height: "35px",
              }}
            >
              Clear
              <input
                type="button"
                style={{ display: "none" }}
                onClick={() => resetChanges()}
              />
            </label>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Photo

Photo.propTypes = {
  store: PropTypes.object,
  uploadPhoto: PropTypes.func,
}
