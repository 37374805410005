import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
// Redux
import { withRouter, Link, NavLink } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Alert,
  UncontrolledAlert,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { loginUser, changePreloader } from "../../store/actions"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// import images
import logo from "../../assets/images/logo-svg.svg"
import logoLightSvg from "../../assets/images/logo-svg.svg"

const Login = props => {
  const dispatch = useDispatch()
  const { error, success, loading } = useSelector(state => ({
    error: state.Login.error,
    success: state.Login.success,
    loading: state.Login.loading,
  }))

  useEffect(() => {
    if (success) {
      props.history.push("/myStore")
    }
    if (loading) {
      dispatch(changePreloader(true))
    }
  }, [success, loading])

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    try {
      dispatch(loginUser(values))
    } catch (e) {
      // console.log(e)
    }
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | The Convenience App</title>
      </MetaTags>
      <div
        style={{
          backgroundImage:
            "url(" +
            "https://ngineered.s3.us-west-2.amazonaws.com/login.jpg" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          height: "100vh",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Link to="/" className="logo logo-dark">
          <img src={logo} alt="" height="60" />
        </Link>

        <Link to="/" className="logo logo-light">
          <img src={logoLightSvg} alt="" height="60" />
        </Link>
        <div className="auth-full-bg my-0 pt-sm-5 w-100">
          <Container>
            <Row className=" justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div className="p-4 text-center">
                        <h5 style={{ color: "#ffffff", fontSize: "28px" }}>
                        WELCOME TO THE CONVENIENCE APP MANAGEMENT SYSTEM
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Card className=" overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-4">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {error ? (
                          <UncontrolledAlert
                            color="danger"
                            className="alert-dismissible fade show"
                            role="alert"
                          >
                            {error}{" "}
                          </UncontrolledAlert>
                        ) : null}
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="text"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter password"
                          />
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-success btn-block"
                            style={{
                              background: "#76C4CC",
                              borderColor: "#76C4CC",
                            }}
                            type="submit"
                          >
                            {loading ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                            ) : null}
                            Log In
                          </button>
                        </div>
                      </AvForm>
                      <Link to="/forgot-password">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-success btn-block"
                            style={{
                              background: "#ffffff",
                              borderColor: "#76C4CC",
                              color: "#76C4CC",
                            }}
                          >
                            Forgot Password ?
                          </button>
                        </div>
                      </Link>
                      <Link to="/register">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-success btn-block"
                            style={{
                              background: "#000000",
                              borderColor: "#fff",
                              color: "#ffffff",
                            }}
                          >
                            Create New Account
                          </button>
                        </div>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <NavLink to={`/privacy`} target='_blank' rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div style={{ position: "fixed", bottom: 0, right: 30}}>
              <h5 style={{ color: "white" }}>Privacy Policy</h5>
              </div>
            </NavLink>
            <NavLink to={`/terms`} target='_blank' rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              <div style={{ position: "fixed", bottom: 0, right: 170}}>
              <h5 style={{ color: "white" }}>Terms and Conditions</h5>
              </div>
            </NavLink>



          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

