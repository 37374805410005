import React from "react";
import about1 from "./about1.webp"
import about2 from "./about2.webp"
import about3 from "./about3.webp"
export default function About() {
  return (
    <>
      <div className="main-aboutUs-sec">
        <div className="container-lg">
          <div className="about-heading text-center mt-5">
            <h1>About Us</h1>
          </div>

          <div className="about-outer-section p-2">
            <div className="row mb-5">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="about-img">
                <img src={about1} alt="" />
                  {/* <img
                    src="https://img1.wsimg.com/isteam/ip/6facc963-0d66-4b9a-8ea4-c351e3ed2131/Enjoy%20slide%20on%20app.jpg/:/cr=t:24.9%25,l:0%25,w:100%25,h:50.19%25/rs=w:600,h:451,cg=true"
                    alt=""
                  /> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-items-center d-flex">
                <div className="about-text">
                  <h6>
                    Order Online, Stay in Your Car, We&apos;ll Bring the Store to You!
                  </h6>
                  <p>
                    Our application is consistent with the behavior of today&apos;s
                    world. Convenience is desired in all facets of life especially
                    with newer generations and the developments of more complex
                    technologies. Individuals today are used to such behaviors in
                    different industries and we want to emphasize those behaviors
                    in the convenience store and gas station industry.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-items-center d-flex about-text-item">
                <div className="about-text">
                  <h6>Why Choose Our App</h6>
                  <p>
                    Our platform allows you to grab your daily essentials from the
                    Convenience store with a few easy clicks while never having to
                    leave the comfort of your vehicle.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 about-img-itmes">
                <div className="about-img">
                <img src={about2} alt="" />
                  {/* <img
                    src="https://img1.wsimg.com/isteam/ip/6facc963-0d66-4b9a-8ea4-c351e3ed2131/Browse%20slide%20on%20app.jpg/:/cr=t:24.89%25,l:0%25,w:100%25,h:50.22%25/rs=w:600,h:451,cg=true"
                    alt=""
                  /> */}
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-6">
                <div className="about-img">
                <img src={about3} alt="" />
                  {/* <img
                    src="https://img1.wsimg.com/isteam/ip/6facc963-0d66-4b9a-8ea4-c351e3ed2131/1W6A8504.jpg/:/cr=t:0%25,l:3.48%25,w:93.05%25,h=100%25/rs=w:600,h:451,cg=true"
                    alt=""
                  /> */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 align-items-center d-flex">
                <div className="about-text">
                  <h6>Discover the Advantages</h6>
                  <p>
                    Keep the music going and the AC blasting at the pump with the
                    convenience App Easily order daily essentials from your phone,
                    save time with scheduled curbside pickup, and enjoy safe,
                    contactless delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
