import {
  FETCH_STORE,
  FETCH_STORE_SUCCESS,
  FETCH_STORE_ERROR,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_ERROR,
  UPLOAD_STORE_PHOTO,
  UPLOAD_STORE_PHOTO_SUCCESS,
  UPLOAD_STORE_PHOTO_ERROR,
  RESET_STORE_STATE,
} from "./actionTypes"

export const fetchStore = () => {
  return {
    type: FETCH_STORE,
  }
}

export const fetchStoresSucces = store => {
  return {
    type: FETCH_STORE_SUCCESS,
    payload: store,
  }
}

export const fetchStoreError = error => {
  return {
    type: FETCH_STORE_ERROR,
    payload: error,
  }
}

export const updateStore = data => {
  return {
    type: UPDATE_STORE,
    payload: { data },
  }
}

export const updateStoreSucces = store => {
  return {
    type: UPDATE_STORE_SUCCESS,
    payload: store,
  }
}

export const updateStoreError = error => {
  return {
    type: UPDATE_STORE_ERROR,
    payload: error,
  }
}
export const uploadStorePhoto = data => {
  return {
    type: UPLOAD_STORE_PHOTO,
    payload: { data },
  }
}

export const uploadStorePhotoSucces = photo => {
  return {
    type: UPLOAD_STORE_PHOTO_SUCCESS,
    payload: photo,
  }
}

export const uploadStorePhotoError = error => {
  return {
    type: UPLOAD_STORE_PHOTO_ERROR,
    payload: error,
  }
}
export const resetStoreState = () => {
  return {
    type: RESET_STORE_STATE,
  }
}
