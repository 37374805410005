import encodeUrl from "encodeurl";
import axios from "axios";

// Clean baseUrl from any unwanted characters or comments
let baseUrl = process.env.REACT_APP_APIKEY.replace(/#.*$/, '').trim();

const getToken = () => {
  return "Token " + localStorage.getItem("authUser");
};

const handleResponse = async (response) => {
  if (!response.ok) {
    const error = new Error(
      "Error " + response.status + ": " + response.statusText
    );
    error.response = response;
    throw error;
  }
  return response.json();
};

const cleanUrl = (url) => {
  console.log(`Base URL: "${baseUrl}"`);
  console.log(`Provided URL: "${url}"`);
  const cleanedUrl = `${baseUrl}${url.trim()}`;
  console.log(`Cleaned URL: "${cleanedUrl}"`);
  return cleanedUrl;
};

export const getRequest = async (url, authorized = false) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (authorized) {
    headers["Authorization"] = getToken();
  }

  const options = {
    method: "GET",
    headers,
  };

  const fullUrl = cleanUrl(url);

  try {
    const response = await fetch(fullUrl, options);
    return await handleResponse(response);
  } catch (error) {
    const errorResponse = await error.response.json();
    throw errorResponse;
  }
};

export const postRequest = async (url, data, authorized = false) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (authorized) {
    headers["Authorization"] = getToken();
  }

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  };

  const fullUrl = cleanUrl(url);

  try {
    const response = await fetch(fullUrl, options);
    return await handleResponse(response);
  } catch (error) {
    const errorResponse = await error.response.json();
    throw errorResponse;
  }
};

export const postRequestForm = async (url, data, authorized = false) => {
  let headers = {};

  if (authorized) {
    headers["Authorization"] = getToken();
  }

  const options = {
    method: "POST",
    headers,
    body: data,
  };

  const fullUrl = cleanUrl(url);

  try {
    const response = await fetch(fullUrl, options);
    return await handleResponse(response);
  } catch (error) {
    const errorResponse = await error.response.json();
    throw errorResponse;
  }
};

export const patchRequest = async (url, data, authorized = false) => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (authorized) {
    headers["Authorization"] = getToken();
  }

  if (!url.endsWith("/")) {
    url += "/";
  }

  const options = {
    method: "PATCH",
    headers,
    body: JSON.stringify(data),
  };

  const fullUrl = cleanUrl(url);

  try {
    const response = await fetch(fullUrl, options);
    return await handleResponse(response);
  } catch (error) {
    const errorResponse = await error.response.json();
    throw errorResponse;
  }
};

export const patchMultiPartRequest = async (
  url,
  formData,
  authorized = false
) => {
  let headers = {
    "Content-Type": "multipart/form-data",
  };

  if (authorized) {
    const token = await getToken();
    headers["Authorization"] = token;
  }

  const fullUrl = cleanUrl(url);

  try {
    const response = await axios.patch(fullUrl, formData, { headers });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getQueryParams = (params) => {
  let query = Object.keys(params)
    .map((k) => encodeUrl(k) + "=" + encodeUrl(params[k]).replace("%20", " "))
    .join("&");
  return "?" + query;
};

export const getErrorMessage = (error) => {
  try {
    const key = Object.keys(error)[0];
    const errorArr = Object.values(error)[0];
    if (key == "phone") {
      return "Phone number is invalid";
    }
    if (key == "token") {
      return "Token Expired";
    }
    return errorArr[0].message;
  } catch (ex) {
    return error;
  }
};
