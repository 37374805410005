import React from "react"
import MetaTags from "react-meta-tags"
import { Link, NavLink } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import About from "./About"
import Contact from "./Contact"
import HeroSec from "./HeroSec"
import Footer from "./Footer"
import ExperienceSec from "./ExperienceSec"
import ContactMap from "./ContactMap"
import "./my.scss"
const WELCOME = () => {
  return (
    <React.Fragment>
      <HeroSec />
      <About />
      {/* <ExperienceSec/> */}
      <Contact />
      <ContactMap />
      <Footer />
    </React.Fragment>
  )
}

export default WELCOME