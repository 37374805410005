import {
  GET_ORDERS_LIST,
  GET_ORDERS_LIST_ERROR,
  GET_ORDERS_LIST_SUCCESS,
  GET_PAST_ORDERS,
  GET_PAST_ORDERS_ERROR,
  GET_PAST_ORDERS_SUCCESS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_ERROR,
  UPDATE_ORDER_ITEM_QUANTITY,
  UPDATE_ORDER_ITEM_QUANTITY_SUCCESS,
  UPDATE_ORDER_ITEM_QUANTITY_ERROR,
  RESET_ORDER_QUANTITY_STATE,
} from "./actionTypes"

const initialState = {
  ordersList: null,
  getOrdersListError: null,
  getOrdersListComplete: false,
  getOrdersListLoading: false,
  pastOrders: null,
  getPastOrdersError: null,
  getPastOrdersComplete: false,
  getPastOrdersLoading: false,
  orderStatus: null,
  updateOrderStatusError: null,
  updateOrderStatusSuccess: false,
  updateOrderStatusLoading: false,
  updateOrderItemQuantityError: null,
  updateOrderItemQuantitySuccess: false,
  updateOrderItemQuantityLoading: false,
}

const ordersList = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_LIST:
      state = {
        ...state,
        getOrdersListComplete: true,
        getOrdersListLoading: true,
        getOrdersListError: null,
      }
      break
    case GET_ORDERS_LIST_SUCCESS:
      state = {
        ...state,
        ordersList: action.payload,
        getOrdersListComplete: true,
        getOrdersListLoading: false,
        getOrdersListError: null,
      }
      break
    case GET_ORDERS_LIST_ERROR:
      state = {
        ...state,
        getOrdersListError: action.payload,
        getOrdersListComplete: true,
        getOrdersListLoading: false,
      }
      break
    case GET_PAST_ORDERS:
      state = {
        ...state,
        getPastOrdersComplete: true,
        getPastOrdersLoading: true,
        getPastOrdersError: null,
      }
      break
    case GET_PAST_ORDERS_SUCCESS:
      state = {
        ...state,
        pastOrders: action.payload,
        getPastOrdersComplete: true,
        getPastOrdersLoading: false,
        getPastOrdersError: null,
      }
      break
    case GET_PAST_ORDERS_ERROR:
      state = {
        ...state,
        getPastOrdersError: action.payload,
        getPastOrdersComplete: true,
        getPastOrdersLoading: false,
      }
      break
    case UPDATE_ORDER_STATUS:
      state = {
        ...state,
        updateOrderStatusLoading: true,
        updateOrderStatusSuccess: false,
        updateOrderStatusError: null,
      }
      break
    case UPDATE_ORDER_STATUS_SUCCESS:
      state = {
        ...state,
        updateOrderStatusLoading: false,
        updateOrderStatusSuccess: true,
        orderStatus: action.payload,
        updateOrderStatusError: null,
      }
      break
    case UPDATE_ORDER_STATUS_ERROR:
      state = {
        ...state,
        updateOrderStatusLoading: false,
        updateOrderStatusSuccess: true,
        updateOrderStatusError: action.payload,
      }
    case UPDATE_ORDER_ITEM_QUANTITY:
      state = {
        ...state,
        updateOrderItemQuantityLoading: true,
        updateOrderItemQuantitySuccess: false,
        updateOrderItemQuantityError: null,
      }
      break
    case UPDATE_ORDER_ITEM_QUANTITY_SUCCESS:
      state = {
        ...state,
        updateOrderItemQuantityLoading: false,
        updateOrderItemQuantitySuccess: true,
        updateOrderItemQuantityError: null,
      }
      break
    case UPDATE_ORDER_ITEM_QUANTITY_ERROR:
      state = {
        ...state,
        updateOrderItemQuantityLoading: false,
        updateOrderItemQuantitySuccess: true,
        updateOrderItemQuantityError: action.payload,
      }
      break
    case RESET_ORDER_QUANTITY_STATE:
      state = {
        ...state,
        updateOrderItemQuantityError: null,
        updateOrderItemQuantitySuccess: false,
        updateOrderItemQuantityLoading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ordersList
