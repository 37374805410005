import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { size } from 'lodash';

const Review = () => {
    console.log('Review component is rendering.');

    const [reviews, setReviews] = useState([]);
    const [filteredReviews, setFilteredReviews] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const authToken = localStorage.getItem('authUser');

                if (!authToken) {
                    console.error('Authentication token not found. Token key:', 'authToken');
                    return;
                }

                console.log('Found authentication token:', authToken);

                const response = await axios.get('https://backend.theconvenienceapp.com/review-orders/', {
                    headers: {
                        Authorization: `Token ${authToken}`,
                    },
                });

                console.log('Response from server:', response.data);

                setReviews(response.data);
                setFilteredReviews(response.data); // Initialize filteredReviews with all reviews
            } catch (error) {
                console.error('Error fetching reviews:', error);
                if (error.response && error.response.status === 404) {
                    setError('No reviews found for your store.');
                } else {
                    setError('An error occurred while fetching reviews.');
                }
            }
        };

        fetchReviews();
    }, []);

    const handleFilter = (searchTerm) => {
        const filteredResults = reviews.filter((review) => {
            const searchFields = [
                review.stars.toString(),
                review.order_id.toString(),
                review.text,
                review.product_names.join(', '), // Join product names into a comma-separated string
                review.customer_name,
            ];
            return searchFields.some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()));
        });

        setFilteredReviews(filteredResults);
    };

    const calculateAverageStars = () => {
        if (reviews.length === 0) {
            return 0;
        }

        const totalStars = reviews.reduce((sum, review) => sum + review.stars, 0);
        return (totalStars / reviews.length).toFixed(2);
    };

    const exportToCSV = () => {
        const csvContent = [
            'Order ID,Stars,Description,Product Names,Customer Name',
            ...reviews.map((review) => {
                return `${review.order_id},${review.stars},"${review.text}","${review.product_names.join(',')}","${review.customer_name}"`;
            }),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Customer_Reviews.csv';
        link.click();
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
                <h1 style={{ marginBottom: '20px', textAlign: 'center' , marginTop: '80px' }}>Customer Reviews</h1>
                {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <input
                        type="text"
                        placeholder="Search Your Reviews..."
                        onChange={(e) => handleFilter(e.target.value)}
                        style={{ padding: '8px', marginRight: '10px' }}
                    />
                    <button
                        style={{
                            backgroundColor: 'lightblue',
                            padding: '8px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            border: '1px solid black'
                        }}
                        onClick={exportToCSV}
                    >
                        Export to CSV
                    </button>
                </div> */}
                <div className='average-head' style={{ marginBottom: '8px', width: '90%', textAlign: 'left' , display: 'flex' , justifyContent: 'space-between', alignItems: 'center' }}>
                   <h3 style={{  backgroundColor: 'lightblue', padding: '8px' , fontSize: '16px' , margin: '0px' ,  width: '100%', maxWidth: 'fit-content' }}> Average Stars: {calculateAverageStars()} </h3>
                   <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Search Your Reviews..."
                        onChange={(e) => handleFilter(e.target.value)}
                        style={{ padding: '8px', marginRight: '10px' }}
                    />
                    <button
                        style={{
                            backgroundColor: 'lightblue',
                            padding: '8px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            border: '1px solid black'
                        }}
                        onClick={exportToCSV}
                    >
                        Export to CSV
                    </button>
                </div>
                </div>
                {error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                ) : (
                    <div style={{ overflowX: 'auto', width: '90%', marginTop: '20px' }}>
                        {filteredReviews.length === 0 ? (
                            <p>No results found.</p>
                        ) : (
                            <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: 'lightblue', textAlign: 'center' }}>Order ID</th>
                                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: 'lightblue', textAlign: 'center' }}>Stars</th>
                                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: 'lightblue', textAlign: 'center' }}>Description</th>
                                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: 'lightblue', textAlign: 'center' }}>Product Names</th>
                                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: 'lightblue', textAlign: 'center' }}>Customer Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredReviews.map((review, index) => (
                                        <tr key={review.id}>
                                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{review.order_id}</td>
                                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{review.stars}</td>
                                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{review.text}</td>
                                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{review.product_names.join(', ')}</td>
                                            <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{review.customer_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Review;
