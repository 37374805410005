import React from "react";
import { NavLink } from "react-router-dom";

export default function Contact() {
  return (
    <>



      <div className="contact-form-item ">
        <div className="container-lg">
          <div className="contact-inner">
            <div className="contact-form">
              <h2>Contact Us</h2>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6">
                <div className="form-text-inner mb-sm-5 mb-xs-5">
                  <h2 className="mb-2">Better yet, see us in person!</h2>
                  <p>
                    We love our customers, so feel free to visit during normal
                    business hours.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-text-inner ">
                  <h2 className="mb-2">Location</h2>
                  <a href='https://www.google.com/maps/dir//650+Suffolk+St+Lowell,+MA+01854+USA/@42.6499313,-71.3158949,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e3a4465f1ca2bf:0xf2662077b0def875!2m2!1d-71.3158949!2d42.6499313?entry=ttu' target="_blank" rel="noopener noreferrer">
  <p>650 Suffolk Street, Lowell, Massachusetts 01854, United States</p>
</a>

                  {/* <NavLink to='https://www.google.com/maps/dir//650+Suffolk+St+Lowell,+MA+01854+USA/@42.6499313,-71.3158949,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e3a4465f1ca2bf:0xf2662077b0def875!2m2!1d-71.3158949!2d42.6499313?entry=ttu' target="_blank" rel="noopener noreferrer">
  <p>650 Suffolk Street, Lowell, Massachusetts 01854, United States</p>
</NavLink> */}

                </div>
              </div>
              <div className="col-lg-6">
                <div className=' contact-number'>
                <p><span>Phone :</span> <a href="tel:+15087539079"><span> +1(508)753-9079</span></a></p>
                  <p><span>Email :</span> <a href="mailto:contact@theconvenienceapp.com"><span> contact@theconvenienceapp.com</span></a></p>
                </div>
                {/* <div className="contact-details mt-5">
                  <h4 className="mb-2">
                    Phone:
                    <span className="text-danger">+1(508)753-9079</span>
                  </h4>
                  <h4 >
                    Email:
                    <span className="text-danger">
                      contact@theconvenienceapp.com
                    </span>
                  </h4>
                </div> */}
              </div>
              {/* <div className="col-lg-6 col-md-12">
                <div className="form-item">
                  <p>Drop us a line!</p>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Name"
                    />
                    <label htmlFor="floatingInput"> Name</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingMailk"
                      placeholder="Email"
                    />
                    <label htmlFor="floatingPassword">Email*</label>
                  </div>
                  <div className="form-floating">
                    <textarea
                      class="form-control"
                      placeholder="Message"
                      style={{ height: "200px" }}
                      id="floatingTextarea2"
                    ></textarea>

                    <label htmlFor="floatingPassword">Message</label>
                  </div>
                  <div className="attached-item">
                    <p className="text-danger">
                      <i class="fa fa-paperclip" aria-hidden="true"></i> Attach
                      Files
                    </p>
                    <p>Attachments (0)</p>
                  </div>
                  <div className="btn-submit mt-3">
                    <div className="btn btn-danger w-100">SEND</div>
                  </div>
                  <div className="terms text-center">
                    <p>
                      This site is protected by reCAPTCHA and the Google{" "}
                      <span className="text-danger">Privacy Policy</span> and{" "}
                      <span className="text-danger">Terms of Service</span>{" "}
                      apply.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="contact-details-sec mx-4">
                  <div className="form-text-inner">
                    <h4 className="mb-4">Better yet, see us in person!</h4>
                    <p>
                      We love our customers, so feel free to visit during normal
                      business hours.
                    </p>
                  </div>
                  <div className="form-text-inner mt-5">
                    <h4 className="mb-4">TheConvenienceapp</h4>
                    <p>
                      650 Suffolk Street, Lowell, Massachusetts 01854, United
                      States
                    </p>
                  </div>

                  <div className="contact-details mt-5">
                    <h4 className="mb-4">
                      Phone:
                      <span className="text-danger">+1(508)753-9079</span>
                    </h4>
                    <h4 className="mb-4">
                      Email:
                      <span className="text-danger">
                        contact@theconvenienceapp.com
                      </span>
                    </h4>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}