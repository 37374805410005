import {
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_ERROR,
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CHANGE_PASSWORD_SUCCESS,
  REQUEST_CHANGE_PASSWORD_ERROR,
  RESET_PASSWORD_CHANGE_STATE,
} from "./actionTypes"

const initialState = {
  passwordResetError: null,
  email: "",
  passwordResetSuccess: false,
  passwordResetLoading: false,
  passwordChange: null,
  passwordChangeSuccess: false,
  passwordChangeLoading: false,
  passwordChangeError: null,
}

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_EMAIL:
      state = {
        ...state,
        passwordResetSuccess: true,
        passwordResetLoading: true,
        passwordResetError: null,
      }
      break
    case PASSWORD_RESET_EMAIL_SUCCESS:
      state = {
        ...state,
        email: action.payload,
        passwordResetLoading: false,
        passwordResetSuccess: true,
        passwordResetError: null,
      }
      break
    case PASSWORD_RESET_EMAIL_ERROR:
      state = {
        ...state,
        passwordResetError: action.payload,
        passwordResetLoading: false,
        passwordResetSuccess: true,
      }
      break
    case REQUEST_CHANGE_PASSWORD:
      state = {
        ...state,
        passwordChangeLoading: true,
        passwordChangeSuccess: false,
        passwordChangeError: null,
      }
      break
    case REQUEST_CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        passwordChangeLoading: false,
        passwordChangeSuccess: true,
        passwordChange: action.payload,
        passwordChangeError: null,
      }
      break
    case REQUEST_CHANGE_PASSWORD_ERROR:
      state = {
        ...state,
        passwordChangeLoading: false,
        passwordChangeSuccess: true,
        passwordChangeError: action.payload,
      }
      break
    case RESET_PASSWORD_CHANGE_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default resetPassword
