import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import storeSaga from "./store/saga"
import productsSaga from "./products/saga"
import ordersSaga from "./orders/saga"
import resetPasswordSaga from "./auth/forgotPassword/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(storeSaga),
    fork(productsSaga),
    fork(ordersSaga),
    fork(resetPasswordSaga),
  ])
}
