import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderImage from "./Profile/logo.svg"


const Privacy_California = () => {

    const tabledata = [
        {id: 1, col1: "A) Identifiers.", col2: "A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.", col3: "YES"},
        {id: 2, col1: "B) Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).", col2: "A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.Some personal information included in this category may overlap with other categories.", col3: "YES"},
        {id: 3, col1: "C) Protected classification characteristics under California or federal law.", col2: "Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression,pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).", col3: ""},
        {id: 4, col1: "D) Commercial information.", col2: "Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.", col3: "YES"},
        {id: 5, col1: "E) Biometric information.", col2: "Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.", col3: "NO"},
        {id: 6, col1: "F) Internet or other similar network activity.", col2: "Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.", col3: "YES"},
        {id: 7, col1: "G) Geolocation data.", col2: "Physical location or movements.", col3: "YES"},
        {id: 8, col1: "H) Sensory data.", col2: "Audio, electronic, visual, thermal, olfactory, or similar information.", col3: "[YES/NO]"},
        {id: 9, col1: "I) Professional or employment-related information.", col2: "Current or past job history or performance evaluations.", col3: "NO"},
        {id: 10, col1: "J) Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).", col2: "Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.", col3: "NO"},
        {id: 11, col1: "K) Inferences drawn from other personal information.", col2: "Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.", col3: "[YES/NO]"},
    ];

    const tabledata_1 = [
        {id:1, col1: "A) Identifiers.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:2, col1: "B) California Customer Records personal information categories.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:3, col1: "C) Protected classification characteristics under California or federal law.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:4, col1: "D) Commercial information.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:5, col1: "E) Biometric information.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:6, col1: "F) Internet or other similar network activity.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:7, col1: "G) Geolocation data.", col2: "Third-Party Service Provider Partners | Subsidiaries and Affiliates", col3: "[Please provide.]"},
        {id:8, col1: "H) Sensory data.", col2: "[None.]", col3: "[Please provide.]"},
        {id:9, col1: "I) Professional or employment-related information.", col2: "[None.]", col3: "[Please provide.]"},
        {id:10, col1: "J) Non-public education information.", col2: "[None.]", col3: "[Please provide.]"},
        {id:11, col1: "K: Inferences drawn from other personal information.", col2: "[None.]", col3: "[Please provide.]"}
    ]

    const imageStyle = {
        maxWidth: "100%",
    };
  return (
    <>
    <div>
        <header className='myHeader'>
            <a href='https://www.theconvenienceapp.com' target='_blank' rel='noopener noreferrer'> 
            <img src={HeaderImage} alt='Header Image'/>
            </a>
        </header>
    </div>
    <div>
    <h5><b><u><center>The Convenience App, LLC Privacy Policy for California Residents</center></u></b></h5>
    <p><b>Effective Date: [DATE]</b></p>
    <p><b>Last Updated on: [DATE]</b></p>
    <p>This <span><b>Privacy Policy for California Residents</b></span> supplements the information contained in The Convenience App, LLC &rsquo;s [INSERT AS LINK TO MOBILE APP PRIVACY POLICY] and <NavLink to={`/privacy`}><span>Website Privacy Policy</span></NavLink> and applies solely to all visitors, users, and others who reside in the State of California (&quot; consumers &quot; or &quot; you &quot;). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this Policy.</p>
    </div>
    <div>
        <h5><b><u>Information We Collect</u></b></h5>
        <p>We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (<span><b>&quot; personal information &quot;</b></span>).</p>
        <p>Personal information does not include:</p>
        <ul>
            <li>Publicly available information from government records.</li>
            <li>Deidentified or aggregated consumer information.</li>
        </ul>
        <p>In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months: </p>
    </div>
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <table style={{borderCollapse: "collapse", border: "1px solid black"}}>
            <thead>
                <tr>
                    <th style={{border: "1px solid black", padding: "8px", textAlign: "center", maxWidth: "300px"}}><b>Category</b></th>
                    <th style={{border: "1px solid black", padding: "8px", maxWidth: "300px", textAlign: "center"}}><b>Examples</b></th>
                    <th style={{border: "1px solid black", padding: "8px", textAlign: "center", maxWidth: "300px"}}><b>Collected</b></th>
                </tr>
            </thead>
            <tbody>
                {tabledata.map(row => (
                    <tr key={row.id}>
                        <td style={{border: "1px solid black", padding: "8px", maxWidth: "300px", textAlign: "center"}}>{row.col1}</td>
                        <td style={{border: "1px solid black", padding: "8px", maxWidth: "300px"}}>{row.col2}</td>
                        <td style={{border: "1px solid black", padding: "8px", maxWidth: "300px"}}>{row.col3}</td> 
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <div>
        <br/>
        <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
        <ul>
            <li>Directly from you. For example, from forms you complete or products and services you purchase.</li>
            <li>Indirectly from you. For example, from observing your actions on our Website or Application.</li>
        </ul>
    </div>
    <div>
        <h5><b><center><u>Use of Personal Information</u></center></b></h5>
        <p>We may use or disclose the personal information we collect for one or more of the following purposes:</p>
        <ul>
            <li>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product orders or process returns.</li>
            <li>To provide, support, personalize, and develop our Application, Website, products, and services.</li>
            <li>To create, maintain, customize, and secure your account with us.</li>
            <li>To process your requests, purchases, transactions, and payments and prevent transactional fraud</li>
            <li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
            <li>To personalize your Application and Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Application and/or Website, and via email or text message (with your consent, where required by law).</li>
            <li>To help maintain the safety, security, and integrity of our Application and Website, products and services, databases and other technology assets, and business.</li>
            <li>For testing, research, analysis, and product development, including to develop and improve our Application and Website, products, and services.</li>
            <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
            <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
            <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our Application and Website users is among the assets transferred.</li>
        </ul>
        <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
    </div>
    <div>
        <h5><b><center><u>Sharing Personal Information</u></center></b></h5>
        <p>We may share your personal information by disclosing it to a third party for a business purpose. We only make these business purpose disclosures under written contracts that describe the purposes, require the recipient to keep the personal information confidential, and prohibit using the disclosed information for any purpose except performing the contract. In the preceding twelve (12) months, Company has disclosed personal information for a business purpose to the categories of third parties indicated in the chart below.</p>
        <p>We do not sell personal information in exchange for money. However, the CCPA views the “sale” of Personal Information more broadly, to include any transfer to another business or third party for valuable consideration. Under this broad definition, we have shared identifiers (as defined above) with entities that compile Personal Information about prospective or current Convenience App customers who reside in California for purposes of updating, verifying or deduplicating Personal Information, prospecting new potential customers, and improving the types of services and products we offer to you. In addition, the collection of Internet or electronic network activity information (as defined above) through cookies on our Sites for a variety of purposes, including personalized advertising and facilitating your activity on social media, may constitute a “sale” under CCPA.</p>
        <p>n the preceding twelve (12) months, Company has sold the following categories of personal information to the categories of third parties indicated in the chart below. For more on your personal information sale rights, see <span style={{backgroundColor: "lightblue"}}>Personal Information Sales Opt-Out and Opt-In Rights</span>.</p>
    </div>
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <table style={{borderCollapse: "collapse", border: "1px solid black"}}>
            <thead>
                <tr>
                    <th style={{border: "1px solid black", padding: "8px", textAlign: "center", maxWidth: "300px"}}><b>Personal Information Category</b></th>
                    <th style={{border: "1px solid black", padding: "8px", maxWidth: "300px", textAlign: "center"}}><b>Business Purpose Disclosures</b></th>
                    <th style={{border: "1px solid black", padding: "8px", textAlign: "center", maxWidth: "300px"}}><b>Sales</b></th>
                </tr>
            </thead>
            <tbody>
                {tabledata_1.map(row => (
                    <tr key={row.id}>
                        <td style={{border: "1px solid black", padding: "8px", maxWidth: "300px", textAlign: "center"}}>{row.col1}</td>
                        <td style={{border: "1px solid black", padding: "8px", maxWidth: "300px", listStyleType: "disc", textAlign: "left"}}>
                            <ul>
                                {row.col2.split(' | ').map((bulletText, index) => (
                                <li key={index}>{bulletText}</li>
                                ))}
                            </ul>
                            </td>
                        <td style={{border: "1px solid black", padding: "8px", maxWidth: "300px"}}>{row.col3}</td> 
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <br/>
    <div>
        <h5><b><u><center>Your Rights and Choices </center></u></b></h5>
        <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
        <p><b><i>Right to Know and Data Portability</i></b></p>
        <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months (the &quote; right to know &quot;). Once we receive your request and confirm your identity (see <span style={{backgroundColor: "lightblue"}}>Exercising Your Rights to Know or Delete</span>), we will disclose to you:</p>
        <ul>
            <li>The categories of personal information we collected about you.</li>
            <li>The categories of sources for the personal information we collected about you.</li>
            <li>Our business or commercial purpose for collecting or selling that personal information.</li>
            <li>The categories of third parties with whom we share that personal information.</li>
            <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
            <ul>
                <li>sales, identifying the personal information categories that each category of recipient purchased; and </li>
                <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained. </li>
            </ul>
            <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
        </ul>
    </div>
    <div>
        <h5><b><i>Right to Delete</i></b></h5>
        <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions (the &quot; right to delete &quot;). Once we receive your request and confirm your identity (see <span style={{backgroundColor: "lightblue"}}>Exercising Your Rights to Know or Delete</span>), we will review your request to see if an exception allowing us to retain the information applies. We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to: </p>
        <ol>
            <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
            <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
            <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
            <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information &rsquo;s deletion may likely render impossible or seriously impair the research &rsquo;s achievement, if you previously provided informed consent.</li>
            <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
            <li>Comply with a legal obligation.</li>
            <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
        </ol>
        <p>We will delete or deidentify personal information not subject to one of these exceptions from our records and will direct our service providers to take similar action.</p>
    </div>
    <div>
        <h5><b><i>Exercising Your Rights to Know or Delete</i></b></h5>
        <p>To exercise your rights to know or delete described above, please submit a request by either:</p>
        <ul>
            <li>Calling us at [TOLL-FREE NUMBER].</li>
            <li>Emailing us at <a href="mailto: contact@theconvenienceapp.com">contact@theconvenienceapp.com</a></li>
            <li>Visiting <a href='https://theconvenienceapp.com' target='_blank' rel="noopener noreferrer" style={{ textDecoration: "none" }}>theconvenienceapp.com</a></li>
            <li>[PASSWORD PROTECTED ACCOUNT INSTRUCTIONS].</li>
            <li>[OTHER METHOD].</li>
        </ul>
        <p>Only you, or someone legally authorized to act on your behalf, may make a request to know or delete related to your personal information. [To designate an authorized agent, [INSTRUCTIONS].]</p>
        <p>You may only submit a request to know twice within a 12-month period. Your request to know or delete must:</p>
        <ul>
            <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include:</li>
            <ul>
                <li>[VERIFICATION REQUIREMENTS  EXAMPLE OF VERIFICATION REQUIREMENTS ATTACHED WITH THE COVER EMAIL].</li>
            </ul>
            <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
        </ul>
        <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.</p>
        <p>You do not need to create an account with us to submit a request to know or delete. [However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account.]</p>
        <p>We will only use personal information provided in the request to verify the requestor &rsquo;s identity or authority to make it.</p>
        <p>For instructions on exercising your sale opt-out or opt-in rights, see Personal Information Sales Opt-Out and Opt-In Rights.</p>
    </div>
    <div>
        <h5><b><i>Response Timing and Format</i></b></h5>
        <p>We will confirm receipt of your request within ten (10) business days. If you do not receive confirmation within the 10-day timeframe, please contact us via any of the contact methods set forth above (see <span style={{backgroundColor: "lightblue"}}>Exercising Your Rights to Know or Delete</span>).</p>
        <p>We endeavor to substantively respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to another 45 days), we will inform you of the reason and extension period in writing.</p>
        <p>If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.</p>
        <p>Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
        <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
    </div>
    <div>
        <h5><b><i>Personal Information Sales Opt-Out and Opt-In Rights</i></b></h5>
        <p>If you are age 16 or older, you have the right to direct us to not sell your personal information at any time (the &quot; right to opt-out &quot;). We do not sell the personal information of consumers we actually know are less than 16 years old[, unless we receive affirmative authorization (the &quot; right to opt-in &quot;) from either the consumer who is between 13 and 15 years old, or the parent or guardian of a consumer less than 13 years old]. Consumers who opt-in to personal information sales may opt-out of future sales at any time.</p>
        <p>To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by visiting the following Internet Web page link:</p>
        <p>[<span style={{backgroundColor: "lightblue"}}>HYPERLINKED URL with the title &quot; Do Not Sell My Personal Information &quot;</span>]</p>
        <p>Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back in to personal information sales at any time by:</p>
        <p>[<span style={{backgroundColor: "lightblue"}}>OPT-IN INSTRUCTIONS OR URL LINK</span>]</p>
        <p>You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request</p>    
    </div>
    <div>
        <h5><b><u><center>Non-Discrimination</center></u></b></h5>
        <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
        <ul>
            <li>Deny you goods or services.</li>
            <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
            <li>Provide you a different level or quality of goods or services.</li>
            <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
        </ul>
        <p>However, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information &rsquo;s value and contain written terms that describe the program &rsquo;s material aspects. Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at any time. [<span style={{backgroundColor: "lightblue"}}>We currently provide the following financial incentives:</span>]</p>
    </div>
    <div>
        <header className='myHeader'>
            {/* <img src={HeaderImage} alt='Header Image' style={imageStyle}/> */}
        </header>
    </div>
    </>
    );
};

export default Privacy_California;