export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"
export const FETCH_PROFILE = "FETCH_PROFILE"
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS"
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR"
export const REQUEST_UPDATE_PASSWORD = "REQUEST_UPDATE_PASSWORD"
export const REQUEST_UPDATE_PASSWORD_SUCCESS = "REQUEST_UPDATE_PASSWORD_SUCCESS"
export const REQUEST_UPDATE_PASSWORD_ERROR = "REQUEST_UPDATE_PASSWORD_ERROR"
export const RESET_PROFILE_STATE = "RESET_PROFILE_STATE"
