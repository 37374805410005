import React, { useEffect, useState } from "react"

import InputMask from "react-input-mask"

import { Row, Col, Card, CardBody, UncontrolledAlert, Input } from "reactstrap"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { AvForm, AvField } from "availity-reactstrap-validation"
const UserInfo = props => {
  const { profile, updateChanges } = props
  const [clearActive, setClearActive] = useState(false)
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [email, setemail] = useState("")
  const [phone, setphone] = useState("")

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertError, setAlertError] = useState(false)

  const { success, error, loading } = useSelector(state => ({
    success: state.Profile.complete,
    error: state.Profile.error,
    loading: state.Profile.loading,
  }))

  const defaultValues = () => {
    setfirstName(profile.first_name)
    setlastName(profile.last_name)
    setemail(profile.email)
    setphone(profile.phone)
  }
  useEffect(() => {
    defaultValues()
    if (error || success) {
      setClearActive(false)
    }
  }, [profile, success, error])

  const resetChanges = () => {
    defaultValues()
    setClearActive(false)
  }

  const updateData = () => {
    if (phone.replaceAll("_", "").length == 12 && clearActive) {
      const data = {
        email: email,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
      }
      updateChanges(data)
    }
  }

  useEffect(() => {
    if (success && !error && !loading) {
      setAlertSuccess(true)
      setTimeout(function () {
        setAlertSuccess(false)
      }, 5000)
    }
    if (error && !loading) {
      setAlertError(true)
      setTimeout(function () {
        setAlertError(false)
      }, 5000)
    }
  }, [error, success, loading])

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          {alertError ? (
            <UncontrolledAlert
              color="danger"
              className="alert-dismissible fade show"
              role="alert"
            >
              {error}{" "}
            </UncontrolledAlert>
          ) : null}
          {alertSuccess ? (
            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
            >
              Record saved successfully!
            </UncontrolledAlert>
          ) : null}

          <Row className="mb-3">
            <Col sm="6">
              <label className="col-md-6 col-form-label">First Name*</label>
              <div className="col-md-10 col-lg-6">
                <input
                  className="form-control"
                  type="text"
                  value={firstName}
                  onChange={e => {
                    setClearActive(true)
                    setfirstName(e.target.value)
                  }}
                  placeholder="Type First Name here"
                />
              </div>
            </Col>
            <Col sm="6">
              <label className="col-md-6 col-form-label">Last Name*</label>
              <div className="col-md-10 col-lg-6">
                <input
                  className="form-control"
                  type="text"
                  value={lastName}
                  onChange={e => {
                    setClearActive(true)
                    setlastName(e.target.value)
                  }}
                  placeholder="Type Last Name here"
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm="6">
              <label className="col-md-6 col-form-label">Email*</label>
              <div className="col-md-10 col-lg-6">
                <input
                  className="form-control"
                  type="text"
                  value={email}
                  onChange={e => {
                    setClearActive(true)
                    setemail(e.target.value)
                  }}
                  placeholder="Type Email Address here"
                />
              </div>
            </Col>
            <Col sm="6">
              <label className="col-md-6 col-form-label">Phone No*</label>
              <div className="col-md-10 col-lg-6">
                <AvForm>
                  <AvField
                    name="phoneNumber"
                    value={phone}
                    className="form-control"
                    placeholder="Enter phone number"
                    type="text"
                    mask="+99999999999"
                    tag={[Input, InputMask]}
                    onChange={e => {
                      setClearActive(true)
                      setphone(e.target.value)
                    }}
                    required
                  />
                </AvForm>
              </div>
            </Col>
          </Row>

          <hr />
          <Row className="mt-4">
            <Col xl={6}></Col>
            <Col xl={6}>
              <label
                className="btn btn-success float-end ms-3"
                style={{
                  background: "#76C4CC",
                  borderColor: "#76C4CC",
                  width: "150px",
                  height: "37px",
                }}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                ) : null}
                Save Changes
                <input
                  type="button"
                  style={{ display: "none" }}
                  onClick={() => updateData()}
                />
              </label>
              {clearActive && (
                <label
                  className="btn btn-success float-end"
                  style={{
                    background: "#ffffff",
                    borderColor: "#76C4CC",
                    color: "#76C4CC",
                    width: "150px",
                    height: "37px",
                  }}
                >
                  Reset
                  <input
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => resetChanges()}
                  />
                </label>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default UserInfo

UserInfo.propTypes = {
  profile: PropTypes.object,
  updateChanges: PropTypes.func,
}
