import {
  FETCH_TAGS,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERROR,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_ERROR,
  SAVE_PRODUCT,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,
  UPDATE_PRODUCT_PHOTO,
  UPDATE_PRODUCT_PHOTO_SUCCESS,
  UPDATE_PRODUCT_PHOTO_ERROR,
  RESET_PRODUCT_STATE,
} from "./actionTypes"

const initialState = {
  tagsFetchError: null,
  tagsFetchSuccess: false,
  tagsFetchLoading: false,
  tags: null,
  productsFetchError: null,
  productsFetchSuccess: false,
  productsFetchLoading: false,
  products: null,
  productFetchError: null,
  productFetchSuccess: false,
  productFetchLoading: false,
  product: null,
  saveProductError: null,
  saveProductSuccess: false,
  saveProductLoading: false,
  updateProductError: null,
  updateProductSuccess: false,
  updateProductLoading: false,
  updateProductPhotoError: null,
  updateProductPhotoSuccess: false,
  updateProductPhotoLoading: false,
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAGS:
      state = {
        ...state,
        tagsFetchLoading: true,
        tagsFetchSuccess: false,
        tagsFetchError: null,
      }
      break
    case FETCH_TAGS_SUCCESS:
      state = {
        ...state,
        tagsFetchLoading: false,
        tagsFetchSuccess: true,
        tags: action.payload,
        tagsFetchError: null,
      }
      break
    case FETCH_TAGS_ERROR:
      state = {
        ...state,
        tagsFetchLoading: false,
        tagsFetchSuccess: true,
        tagsFetchError: action.payload,
      }
      break
    case FETCH_PRODUCTS:
      state = {
        ...state,
        productsFetchLoading: true,
        productsFetchSuccess: false,
        productsFetchError: null,
      }
      break
    case FETCH_PRODUCTS_SUCCESS:
      state = {
        ...state,
        productsFetchLoading: false,
        productsFetchSuccess: true,
        products: action.payload,
        productsFetchError: null,
      }
      break
    case FETCH_PRODUCTS_ERROR:
      state = {
        ...state,
        productsFetchLoading: false,
        productsFetchSuccess: true,
        productsFetchError: action.payload,
      }
      break
    case FETCH_PRODUCT:
      state = {
        ...state,
        product: null,
        productFetchLoading: true,
        productFetchSuccess: false,
        productFetchError: null,
      }
      break
    case FETCH_PRODUCT_SUCCESS:
      state = {
        ...state,
        productFetchLoading: false,
        productFetchSuccess: true,
        product: action.payload,
        productFetchError: null,
      }
      break
    case FETCH_PRODUCT_ERROR:
      state = {
        ...state,
        productFetchLoading: false,
        productFetchSuccess: true,
        productFetchError: action.payload,
      }
      break
    case SAVE_PRODUCT:
      state = {
        ...state,
        saveProductLoading: true,
        saveProductSuccess: false,
        saveProductError: null,
      }
      break
    case SAVE_PRODUCT_SUCCESS:
      state = {
        ...state,
        saveProductLoading: false,
        saveProductSuccess: true,
        product: action.payload,
        saveProductError: null,
      }
      // console.log(state,action.payload,"payload")
      break
    case SAVE_PRODUCT_ERROR:
      state = {
        ...state,
        saveProductLoading: false,
        saveProductSuccess: true,
        saveProductError: action.payload,
      }
      break
    case UPDATE_PRODUCT:
      state = {
        ...state,
        updateProductLoading: true,
        updateProductSuccess: false,
        updateProductError: null,
      }
      break
    case UPDATE_PRODUCT_SUCCESS:
      state = {
        ...state,
        updateProductLoading: false,
        updateProductSuccess: true,
        product: action.payload,
        updateProductError: null,
      }
      break
    case UPDATE_PRODUCT_ERROR:
      state = {
        ...state,
        updateProductLoading: false,
        updateProductSuccess: true,
        updateProductError: action.payload,
      }
      break
    case UPDATE_PRODUCT_PHOTO:
      state = {
        ...state,
        updateProductPhotoLoading: true,
        updateProductPhotoSuccess: false,
        updateProductPhotoError: null,
      }
      break
    case UPDATE_PRODUCT_PHOTO_SUCCESS:
      state = {
        ...state,
        updateProductPhotoLoading: false,
        updateProductPhotoSuccess: true,
        product: action.payload,
        updateProductPhotoError: null,
      }
      break
    case UPDATE_PRODUCT_PHOTO_ERROR:
      state = {
        ...state,
        updateProductPhotoLoading: false,
        updateProductPhotoSuccess: true,
        updateProductPhotoError: action.payload,
      }
      break
    case RESET_PRODUCT_STATE:
      state = {
        ...state,
        ...initialState,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default products
