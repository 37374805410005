import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
// Redux
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Row, Col, CardBody, Card, Container, Alert } from "reactstrap"

import {
  passwordResetEmail,
  resetPasswordChangeState,
} from "../../store/actions"
// import images
import logo from "../../assets/images/logo-svg.svg"
import logoLightSvg from "../../assets/images/logo-svg.svg"
import { useSelector, useDispatch } from "react-redux"
import { AvForm, AvField } from "availity-reactstrap-validation"
import sentImg from "../../assets/images/register/sent.png"

const ForgotPassword = props => {
  const dispatch = useDispatch()
  const [sent, setsent] = useState(false)
  const [notSent, setnotSent] = useState(false)
  const { error, success, loading } = useSelector(state => ({
    error: state.ResetPassword.passwordResetError,
    success: state.ResetPassword.passwordResetSuccess,
    loading: state.ResetPassword.passwordResetLoading,
  }))
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    try {
      dispatch(passwordResetEmail(values))
    } catch (e) {
      // console.log(e)
    }
  }
  useEffect(() => {
    if (success && !loading && !error) {
      setsent(true)
      dispatch(resetPasswordChangeState())
    }
    if (error && !loading) {
      setnotSent(true)
      setTimeout(function () {
        setnotSent(false)
      }, 5000)
    }
  }, [success, loading, error])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | The Convenience App</title>
      </MetaTags>
      <div
        style={{
          backgroundImage:
            "url(" +
            "https://ngineered.s3.us-west-2.amazonaws.com/login.jpg" +
            ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          height: "100vh",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <Link to="/" className="logo logo-dark">
          <img src={logo} alt="" height="60" />
        </Link>

        <Link to="/" className="logo logo-light">
          <img src={logoLightSvg} alt="" height="60" />
        </Link>
        <div className="auth-full-bg my-5 pt-sm-5">
          <Container>
            <Row className=" justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div className="p-4 text-center">
                        <h5
                          style={{ color: "#ffffff", fontSize: "28px" }}
                        >{`FORGOT PASSWORD?`}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Card className=" overflow-hidden">
                  {!sent ? (
                    <CardBody className="pt-4 mx-4 my-4">
                      {notSent ? <Alert color="danger">{error}</Alert> : null}
                      <span style={{ color: "#B6493F" }}>
                        Enter the email address you used when you joined and
                        we’ll send you instructions to reset your password.
                      </span>
                      <div className="pt-4">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email*"
                              value=""
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              required
                            />
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-success btn-block"
                              style={{ background: "#76C4CC" }}
                              type="submit"
                            >
                              {loading ? (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                              ) : null}
                              Send Reset Email
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody className="pt-4 mx-4 my-4">
                      <span>
                        Please check your email and follow directions for reset
                        password
                      </span>
                      <div className="mt-4 mb-4 text-center">
                        <img src={sentImg} alt="" />
                      </div>
                      <Link to="/">
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-success btn-block"
                            style={{ background: "#76C4CC" }}
                            type="submit"
                          >
                            Return to Login
                          </button>
                        </div>
                      </Link>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgotPassword)

ForgotPassword.propTypes = {
  history: PropTypes.object,
}
