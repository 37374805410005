import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_SUCCESS,
  REQUEST_UPDATE_PASSWORD,
  REQUEST_UPDATE_PASSWORD_ERROR,
  REQUEST_UPDATE_PASSWORD_SUCCESS,
  RESET_PROFILE_STATE,
} from "./actionTypes"

export const fetchProfile = () => {
  return {
    type: FETCH_PROFILE,
  }
}

export const fetchProfileSuccess = user => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: user,
  }
}

export const fetchProfileError = error => {
  return {
    type: FETCH_PROFILE_ERROR,
    payload: error,
  }
}

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
export const requestUpdatePassword = (data, id) => {
  return {
    type: REQUEST_UPDATE_PASSWORD,
    payload: { data, id },
  }
}

export const requestUpdatePasswordSucces = data => {
  return {
    type: REQUEST_UPDATE_PASSWORD_SUCCESS,
    payload: data,
  }
}

export const requestUpdatePasswordError = error => {
  return {
    type: REQUEST_UPDATE_PASSWORD_ERROR,
    payload: error,
  }
}
export const resetProfileState = () => {
  return {
    type: RESET_PROFILE_STATE,
  }
}
