import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import PropTypes from "prop-types"
import "./style.css";
import drink from "../../assets/images/store/product.png"

const WeeklyDays = (props) => {
  const {store,updateChanges} = props;
  const [storeName,setStoreName] = useState("");
  const [clearActive, setClearActive] = useState(false);

  const defaultValues=()=>{
    setStoreName(store && store.name)
  }
  useEffect(()=>{
    defaultValues();
  },[store])

  const resetChanges=()=>{
    defaultValues();
    setClearActive(false)
  }

  const updateData = ()=>{
    updateChanges()
  }

  const rowStyle= {
    justifyContent:'center', 
    display: "flex", 
    alignItems: 'center', 
    textAlign:"center",
    background:"#ffffff",
    height:"100%", 
    border: "1px solid #E6E7E9",
    borderRadius:"0.25rem"
  }
  const alignCenter= {
    justifyContent:'center', 
    display: "flex", 
    alignItems: 'center', 
    textAlign:"center",
  }
  return (
    <React.Fragment>
      {" "}
        <Row><h5 className="mt-4 mb-4">Special Weekly Deals</h5></Row>
          <Row>
            <Col sm="3">
                <Row style={rowStyle}>
                    <Col sm="3">
                        <img
                            src={drink}
                            alt="Header Avatar"
                            width="36px"
                        />
                    </Col>
                    <Col sm="3">
                        <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                            >
                            Pepsi
                        </label>
                    </Col>
                    <Col sm="3">
                        <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                            >
                            Drinks
                        </label>
                    </Col>
                    <Col sm="3">
                        <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                            >
                            $122
                        </label>
                    </Col>
                </Row>
            </Col>
            <Col sm="1" style={alignCenter}>
                <label
                    className="col-form-label"
                    >
                    +
                </label>
            </Col>
            <Col sm="3">
                <Row style={rowStyle}>
                    <Col sm="3">
                        <img
                            src={drink}
                            alt="Header Avatar"
                            width="36px"
                            height="36px"
                        />
                    </Col>
                    <Col sm="3">
                        <label
                            className="col-form-label"
                            >
                            Pepsi
                        </label>
                    </Col>
                    <Col sm="3">
                        <label
                            className="col-form-label"
                            >
                            Drinks
                        </label>
                    </Col>
                    <Col sm="3">
                        <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                            >
                            $122
                        </label>
                    </Col>
                </Row>
            </Col>
            <Col sm="1" style={alignCenter}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                    >
                    =
                </label>
            </Col>
            <Col sm="4">
                <Row>
                    <Col sm="3" style={rowStyle}>
                        <label
                            className="col-form-label pt-4 pb-4"
                            >
                            $244
                        </label>
                    </Col>
                    <Col sm="4">
                        <label className="btn btn-success float-end" style={{justifyContent:'center', 
                                display: "flex", 
                                alignItems: 'center', 
                                textAlign:"center",background:"#76C4CC",width:"100%", height:"100%"}}>
                            Edit
                            <input type='button' style={{display: 'none'}} onClick={()=>updateData()} />
                        </label>
                    </Col>
                </Row>
            </Col>
        </Row>
    </React.Fragment>
  )
}

export default WeeklyDays


WeeklyDays.propTypes = {
  store: PropTypes.object,
  updateChanges:PropTypes.func,
}