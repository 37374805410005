import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classNames from "classnames";
import OrderDetails from "../../pages/Order/orderDetails";
import { updateOrderStatus } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { dateFormat } from "../../helpers/utils";
import notificationSound from "./iphone.mp3";

const CardTaskBox = (props) => {
  const [orderDetailModel, setOrderDetailModel] = useState(false);
  const orderData = props.data;
  const dispatch = props.dispatch;
  const [cancelOrderAlert, setCancelOrderAlert] = useState(false);
  const [successDialogue, setSuccessDialogue] = useState(false);
  const [commentOrderCancel, setCommentOrderCancel] = useState("");
  const [intervalId, setIntervalId] = useState(null);

  const toggleViewModal = () => setOrderDetailModel(!orderDetailModel);

  const handleOrderStatus = () => {
    if (orderData.status === 20) {
      dispatch(updateOrderStatus({ status: 30 }, orderData.id));
    } else if (orderData.status === 30) {
      toggleViewModal();
    } else if (orderData.status === 40) {
      dispatch(updateOrderStatus({ status: 50 }, orderData.id));
    }
  };

  const handleCancelOrder = () => {
    dispatch(
      updateOrderStatus(
        { status: 100, store_comment: commentOrderCancel },
        orderData.id
      )
    );
    setSuccessDialogue(true);
  };

  useEffect(() => {
    const sendNotification = () => {
      if (Notification.permission === "granted") {
        const notification = new Notification("New Order!", {
          body: `Congratulations! You have received a new order:${orderData.id}.`,
        });
        playNotificationSound(); // Play notification sound
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notification = new Notification("New Order!", {
              body: `Order #${orderData.id} has a new status.`,
            });
            playNotificationSound(); // Play notification sound
          }
        });
      }
    };

    if (orderData.status === 20) {
      sendNotification(); // Send initial notification

      const id = setInterval(() => {
        if (orderData.status === 20) {
          sendNotification(); // Repeat notification every 30 seconds
        }
      }, 30000);

      setIntervalId(id);

      return () => clearInterval(id); // Cleanup interval on component unmount
    } else {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval if order status changes
        setIntervalId(null);
      }
    }
  }, [orderData.status]);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  const handleNotificationButtonClick = () => {
    playNotificationSound();
  };

  const handleCancelOrderClick = () => {
    setCancelOrderAlert(true);
  };

  return (
    <React.Fragment>
      <OrderDetails
        isOpen={orderDetailModel}
        screen="orders"
        data={orderData}
        toggle={toggleViewModal}
      />
      {cancelOrderAlert ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Cancel Order"
          cancelBtnText="Go Back"
          confirmBtnStyle={{ background: "#76C4CC", borderWidth: 0 }}
          onConfirm={() => {
            handleCancelOrder();
            setCancelOrderAlert(false);
          }}
          onCancel={() => setCancelOrderAlert(false)}
        ></SweetAlert>
      ) : null}

      {successDialogue ? (
        <SweetAlert
          success
          title="Cancelled"
          onConfirm={() => {
            setSuccessDialogue(false);
          }}
        ></SweetAlert>
      ) : null}

      {orderData && orderData.customer !== undefined ? (
        <Card className="task-box">
          <CardBody
            className="borad-width"
            onClick={toggleViewModal}
            style={{ minWidth: "290px" }}
          >
            <Row>
              <Col className="col-12">
                <Row>
                  <Col className="col-4" style={{ whiteSpace: "nowrap" }}>
                    <h5 className="m-0 font-size-12">
                      Order #:
                      <span> </span>
                      <span>{orderData.id}</span>
                    </h5>
                  </Col>
                  <Col className="col-8" style={{ whiteSpace: "nowrap" }}>
                    <h5
                      className="text-end text text-muted m-0 font-size-12"
                      style={{ color: "#336E74" }}
                    >
                      {dateFormat(orderData.created_at)}
                      <span> </span>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <h5
                      className="my-2 font-size-13"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      User:
                      <span className="m-1">
                        {props.data.customer.first_name}{" "}
                        {props.data.customer.last_name}
                      </span>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-6" style={{ whiteSpace: "nowrap" }}>
                    <span
                      style={
                        orderData.is_curbside_pickup
                          ? {
                              backgroundColor: "rgba(31, 98, 199, 0.2)",
                              color: "#4677C1",
                            }
                          : {
                              backgroundColor: "rgba(148, 39, 117, 0.2)",
                              color: "#A1558C",
                            }
                      }
                      className={classNames(
                        "badge p-1 me-2 mt-3 font-size-12"
                      )}
                    >
                      {orderData.is_curbside_pickup
                        ? "Curbside Delivery"
                        : "In-store Pickup"}
                    </span>
                  </Col>
                  <Col className="col-6" style={{ whiteSpace: "nowrap" }}>
                    <Row
                      style={{
                        backgroundColor: "#F4FAFB",
                        border: "1px solid #76C4CC",
                        borderRadius: "4px",
                      }}
                      className="row text-center mx-1 my-2"
                    >
                      <Col className="p-2">
                        <h6
                          className="float-start m-0"
                          style={{ color: "#336E74" }}
                        >
                          Total:{" "}
                        </h6>
                        <h6 className="float-end m-0" style={{ color: "red" }}>
                          {" "}
                          ${orderData.total}
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col style={{ whiteSpace: "nowrap" }}>
                {(orderData.status === 30 || orderData.status === 40) &&
                orderData.has_checked_in ? (
                  <span
                    style={{
                      color: props.checkedIn ? "#f8482c" : "white",
                      backgroundColor: props.checkedIn ? "white" : "#f8482c",
                    }}
                    className={classNames("badge py-1 px-2 font-size-12 ")}
                  >
                    Checked In
                  </span>
                ) : null}
              </Col>
              <Col>
                <div style={{ display: "float", float: "right" }}>
                  {orderData.status === 55 ? null : (
                    <UncontrolledDropdown>
                      <DropdownToggle
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="arrow-none"
                        tag="i"
                      >
                        <i className="mdi mdi-dots-horizontal text-muted h4" />
                      </DropdownToggle>
                      <DropdownMenu
                        style={{
                          marginLeft:
                            orderData.status === 50 ||
                            orderData.status === 60
                              ? "-160px"
                              : "35px",
                          marginTop: "-25px",
                        }}
                      >
                        {orderData.status === 20 ||
                        orderData.status === 30 ||
                        orderData.status === 40 ? (
                          <DropdownItem
                            href="#"
                            onClick={() => handleOrderStatus()}
                          >
                            {props.data.status === 20
                              ? "Accept Order"
                              : props.data.status === 30
                              ? "Pack Order"
                              : props.data.status === 40
                              ? "Complete Order"
                              : null}
                          </DropdownItem>
                        ) : null}
                        {orderData.status === 20 ||
                        orderData.status === 30 ||
                        orderData.status === 40 ? (
                          <DropdownItem
                            href="#"
                            onClick={() => {
                              setCancelOrderAlert(true);
                            }}
                          >
                            Cancel Order
                          </DropdownItem>
                        ) : orderData.status === 50 ||
                          orderData.status === 60 ? (
                          <DropdownItem
                            href="#"
                            onClick={() => {
                              setCancelOrderAlert(true);
                            }}
                          >
                            Cancel Order
                          </DropdownItem>
                        ) : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card className="task-box"></Card>
      )}
    </React.Fragment>
  );
};

CardTaskBox.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  checkedIn: PropTypes.bool,
};

export default CardTaskBox;
