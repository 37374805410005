import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { postRequest, getErrorMessage } from "../../../helpers/apiHelper"

// Login Redux States
import { PASSWORD_RESET_EMAIL, REQUEST_CHANGE_PASSWORD } from "./actionTypes"
import {
  passwordResetEmailSuccess,
  passwordResetEmailError,
  requestChangePasswordSucces,
  requestChangePasswordError,
} from "./actions"
import * as url from "../../../helpers/urlHelper"

function* emailResetPassword({ payload: { data } }) {
  try {
    const response = yield call(postRequest, url.EMAIL_RESET_PASSWORD_URL, data)
    yield put(passwordResetEmailSuccess(response))
  } catch (error) {
    yield put(passwordResetEmailError(getErrorMessage(error)))
  }
}

function* requestChangePassword({ payload: { data } }) {
  try {
    const response = yield call(
      postRequest,
      url.REQUEST_CHANGE_PASSWORD_URL,
      data
    )
    yield put(requestChangePasswordSucces(response))
  } catch (error) {
    yield put(requestChangePasswordError(getErrorMessage(error)))
  }
}
function* watchResetPasswordEmail() {
  yield takeEvery(PASSWORD_RESET_EMAIL, emailResetPassword)
}
function* watchChangePassword() {
  yield takeEvery(REQUEST_CHANGE_PASSWORD, requestChangePassword)
}

function* resetPasswordSaga() {
  yield all([fork(watchResetPasswordEmail), fork(watchChangePassword)])
}
export default resetPasswordSaga
