import { call, put, takeEvery} from "redux-saga/effects"
import {
  postRequest,
  getErrorMessage,
} from "../../../helpers/apiHelper"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  ONBOARDING_LINK,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  onboardingLinkSuccess,
  onboardingLinkError,
} from "./actions"
import * as url from "../../../helpers/urlHelper"

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postRequest, url.LOGIN_USER_URL, user)
    localStorage.setItem("authUser", response.key)
    yield put(loginSuccess())
  } catch (error) {
    yield put(apiError(getErrorMessage(error)))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    // console.log("Hitted")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))

    }
    // window.location.href("/login")
    location.assign("/login");
  } catch (error) {
    yield put(apiError(getErrorMessage(error)))
  }
}

function* onboardingLink({ payload: { data } }) {
  try {
    const response = yield call(
      postRequest,
      url.ONBOARDING_LINK_URL,
      data,
      true
    )
    yield put(onboardingLinkSuccess(response.url))
  } catch (error) {
    yield put(onboardingLinkError(getErrorMessage(error)))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser),
    yield takeEvery(ONBOARDING_LINK, onboardingLink)
}

export default authSaga
