import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ORDERS_LIST,
  UPDATE_ORDER_STATUS,
  GET_PAST_ORDERS,
  UPDATE_ORDER_ITEM_QUANTITY,
} from "./actionTypes"
import {
  getOrdersListSuccess,
  getOrdersListFail,
  getPastOrdersSuccess,
  getPastOrdersFail,
  updateOrderStatusError,
  updateOrderStatusSuccess,
  updateOrderItemQuantitySuccess,
  updateOrderItemQuantityError,
} from "./actions"
import * as url from "../../helpers/urlHelper"
import {
  getRequest,
  getQueryParams,
  getErrorMessage,
  patchRequest,
} from "../../helpers/apiHelper"

function* fetchOrdersList({ payload: { search, is_curbside_pickup } }) {
  try {
    let params = {
      limit: 100,
      offset: 0,
      search: search,
      is_curbside_pickup: is_curbside_pickup,
      status__in: [20, 30, 40, 50, 60],
    }
    const response = yield call(
      getRequest,
      url.ORDERS_LIST_URL + getQueryParams(params),
      true
    )
    yield put(getOrdersListSuccess(response))
  } catch (error) {
    yield put(getOrdersListFail(getErrorMessage(error)))
  }
}
function* fetchPastOrders({
  payload: { offset, search, is_curbside_pickup, status },
}) {
  try {
    let pastOrdersParams = {
      limit: 10,
      offset: offset,
      search: search,
      is_curbside_pickup: is_curbside_pickup,
      status__in: [status],
    }
    const response = yield call(
      getRequest,
      url.ORDERS_LIST_URL + getQueryParams(pastOrdersParams),
      true
    )
    yield put(getPastOrdersSuccess(response))
  } catch (error) {
    yield put(getPastOrdersFail(getErrorMessage(error)))
  }
}
function* updateOrderStatus({ payload: { data, id } }) {
  try {
    const response = yield call(
      patchRequest,
      url.ORDERS_LIST_URL + id,
      data,
      true
    )
    yield put(updateOrderStatusSuccess(response))
  } catch (error) {
    if (typeof error == "string") {
      yield put(updateOrderStatusError(error))
    } else {
      yield put(updateOrderStatusError(getErrorMessage(error)))
    }
  }
}
function* updateOrderItemQuantity({ payload: { data, id, itemID } }) {
  try {
    const response = yield call(
      patchRequest,
      url.ORDERS_LIST_URL + id + "/items/" + itemID + "/",
      data,
      true
    )
    yield put(updateOrderItemQuantitySuccess(response))
  } catch (error) {
    yield put(updateOrderItemQuantityError(getErrorMessage(error)))
  }
}
export function* watchGetOrdersList() {
  yield takeEvery(GET_ORDERS_LIST, fetchOrdersList)
}
export function* watchGetPastOrders() {
  yield takeEvery(GET_PAST_ORDERS, fetchPastOrders)
}
export function* watchUpdateOrderStatus() {
  yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatus)
}
export function* watchUpdateOrderItemQuantity() {
  yield takeEvery(UPDATE_ORDER_ITEM_QUANTITY, updateOrderItemQuantity)
}
function* ordersListSaga() {
  yield all([
    fork(watchGetOrdersList),
    fork(watchUpdateOrderStatus),
    fork(watchGetPastOrders),
    fork(watchUpdateOrderItemQuantity),
  ])
}
export default ordersListSaga
