import React, { useEffect, useState } from "react"

import InputMask from "react-input-mask"

import { Row, Col, Card, CardBody, Input } from "reactstrap"
import MapsGoogle from "./map"
import PropTypes from "prop-types"
import AutoSuggest from "react-autosuggest"
import "./style.css"

import { useSelector } from "react-redux"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { AvForm, AvField } from "availity-reactstrap-validation"
const StoreInfo = props => {
  const { store, updateChanges, addresses, searchAddress, dispatch } = props
  const [storeName, setStoreName] = useState("")
  const [storePhoneNumber, setStorePhoneNumber] = useState("")
  const [address, setAddress] = useState("")
  const [placeId, setPlaceId] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [clearActive, setClearActive] = useState(false)
  const [value, setValue] = useState("")

  const { success, error, loading } = useSelector(state => ({
    success: state.store.storeUpdateComplete,
    error: state.store.storeUpdateError,
    loading: state.store.storeUpdateLoading,
  }))
  useEffect(() => {
    success || error ? setClearActive(false) : null
    error && !loading ? defaultValues() : null
  }, [success, error])

  const defaultValues = () => {
    setStoreName(store && store.name)
    setAddress(store.address)
    setStorePhoneNumber(store.phone)
    setPlaceId(store.google_maps_place_id)
  }
  useEffect(() => {
    defaultValues()
  }, [store])

  const onSuggestionSelected = id => {
    setPlaceId(id)
  }
  const onSuggestionsFetchRequested = value => {
    dispatch(searchAddress(value))
    setAddress(value)
    setSuggestions(getSuggestions(value))
  }
  const onStoreAddressChange = newValue => {
    setAddress(newValue)
    setClearActive(true)
  }
  function getSuggestions(value) {
    return lowerCasedAddresses.filter(address => {
      return address.name.includes(value.trim().toLowerCase())
    })
  }
  const lowerCasedAddresses = addresses.map(address => {
    return {
      id: address.place_id,
      name: address.address.toLowerCase(),
    }
  })

  const resetChanges = () => {
    defaultValues()
    setClearActive(false)
  }

  const updateData = () => {
    if (
      address.length > 0 &&
      storePhoneNumber.replaceAll("_", "").length == 12 &&
      clearActive
    ) {
      updateChanges({
        name: storeName,
        google_maps_place_id: placeId,
        phone: storePhoneNumber,
      })
    } else {
    }
  }

  return (
    <React.Fragment>
      {" "}
      <Row>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <Row className="mb-1">
                <label
                  htmlFor="example-text-input"
                  className="col-md-10 col-form-label"
                >
                  Store Name*
                </label>
              </Row>
              <Row className="mb-3">
                <div className="col-md-10">
                  {loading ? (
                    <Skeleton className="form-control" type="text" />
                  ) : (
                    <input
                      className="form-control"
                      type="text"
                      value={storeName}
                      onChange={e => {
                        setClearActive(true)
                        setStoreName(e.target.value)
                      }}
                      placeholder="Type Store Name here"
                    />
                  )}
                </div>
              </Row>
              <Row className="mb-1">
                <label
                  htmlFor="example-text-input"
                  className="col-md-10 col-form-label"
                >
                  Address
                </label>
              </Row>
              <Row className="mb-3">
                <div className="col-md-10">
                  {loading ? (
                    <Skeleton className="form-control" type="text" />
                  ) : (
                    <AutoSuggest
                      suggestions={suggestions}
                      onSuggestionsClearRequested={() => setSuggestions([])}
                      onSuggestionsFetchRequested={({ value }) => {
                        onSuggestionsFetchRequested(value)
                        setValue(value)
                      }}
                      onSuggestionSelected={(_, data) =>
                        onSuggestionSelected(data.suggestion.id)
                      }
                      getSuggestionValue={suggestion => suggestion.name}
                      renderSuggestion={suggestion => (
                        <span>{suggestion.name}</span>
                      )}
                      inputProps={{
                        placeholder: "Type your address",
                        value: address,
                        onChange: (_, { newValue, method }) => {
                          onStoreAddressChange(newValue)
                        },
                      }}
                      className="form-control is-touched is-dirty av-invalid is-invalid form-control"
                      highlightFirstSuggestion={true}
                    />
                  )}
                </div>
              </Row>
            </Col>
            <Col sm="6">
              <label className="col-md-10 col-form-label mb-1">Phone No*</label>
              <div className="col-md-10 col-lg-10">
                {loading ? (
                  <Skeleton className="form-control" type="text" />
                ) : (
                  <AvForm>
                    <AvField
                      name="phoneNumber"
                      value={storePhoneNumber}
                      className="form-control"
                      placeholder="Enter phone number"
                      type="text"
                      mask="+99999999999"
                      tag={[Input, InputMask]}
                      onChange={e => {
                        setClearActive(true)
                        setStorePhoneNumber(e.target.value)
                      }}
                      required
                    />
                  </AvForm>
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <div className="mt-4 mt-sm-0">
              <MapsGoogle store={store} />
            </div>
          </Row>
          <hr />
          <Row className="mt-4">
            <Col xl={6}></Col>
            <Col xl={6}>
              <label
                className="btn btn-success float-end ms-3"
                style={{
                  background: "#76C4CC",
                  width: "150px",
                  height: "35px",
                }}
              >
                {loading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                ) : null}
                Save Changes
                <input
                  type="button"
                  style={{ display: "none" }}
                  onClick={() => (clearActive ? updateData() : null)}
                />
              </label>
              {clearActive && (
                <label
                  className="btn btn-success float-end"
                  style={{
                    background: "#ffffff",
                    color: "#76C4CC",
                    borderColor: "#76C4CC",
                    width: "150px",
                    height: "35px",
                  }}
                >
                  Reset
                  <input
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => resetChanges()}
                  />
                </label>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default StoreInfo

StoreInfo.propTypes = {
  store: PropTypes.object,
  updateChanges: PropTypes.func,
  searchAddress: PropTypes.func,
  addresses: PropTypes.array,
  dispatch: PropTypes.func,
}
