import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  EDIT_PROFILE,
  FETCH_PROFILE,
  REQUEST_UPDATE_PASSWORD,
} from "./actionTypes"
import {
  profileSuccess,
  profileError,
  fetchProfileSuccess,
  fetchProfileError,
  requestUpdatePasswordSucces,
  requestUpdatePasswordError,
} from "./actions"
import * as url from "../../../helpers/urlHelper"
import {
  getRequest,
  patchRequest,
  getErrorMessage,
  postRequest,
} from "../../../helpers/apiHelper"

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(patchRequest, url.PROFILE_URL, user, true)
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(getErrorMessage(error)))
  }
}

function* fetchProfile() {
  try {
    const response = yield call(getRequest, url.PROFILE_URL, true)
    yield put(fetchProfileSuccess(response))
  } catch (error) {
    yield put(fetchProfileError(getErrorMessage(error)))
  }
}
function* updatePassword({ payload: { data, id } }) {
  try {
    const response = yield call(
      postRequest,
      url.PASSWORD_UPDATE_URL,
      data,
      true
    )
    yield put(requestUpdatePasswordSucces(response))
  } catch (error) {
    yield put(requestUpdatePasswordError(getErrorMessage(error)))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}
export function* watchPasswordUpdate() {
  yield takeEvery(REQUEST_UPDATE_PASSWORD, updatePassword)
}
export function* watchFetchProfile() {
  yield takeEvery(FETCH_PROFILE, fetchProfile)
}

function* ProfileSaga() {
  yield all([
    fork(watchProfile),
    fork(watchFetchProfile),
    fork(watchPasswordUpdate),
  ])
}

export default ProfileSaga
