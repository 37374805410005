import React, { useEffect, useState } from "react"
// Redux
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  InputGroup,
  Label,
  Alert,
} from "reactstrap"
// availity-reactstrap-validation
import line from "../../../assets/images/register/line.png"
import tick from "../../../assets/images/register/tick.png"
import fourLight from "../../../assets/images/register/4-light.png"

import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation"

const Step4 = props => {
  const [error, setError] = useState("")
  const [password1, setpassword1] = useState([])
  const [password2, setpassword2] = useState([])
  const [password1Type, setpassword1Type] = useState(false)
  const [password2Type, setpassword2Type] = useState(false)
  const re = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  )

  const handleValidSubmit = (event, values) => {
    if (validatePassword()) {
      const data = { ...props.data, password1: password1, password2: password2 }
      props.setdata(data)
      props.submitData(data)
      setError("")
    }
  }
  const validatePassword = () => {
    if (password1 === "" || password2 === "") {
      setError("Password field should not be empty!")
      setTimeout(function () {
        setError("")
      }, 5000)
      return false
    }
    if (password1 !== password2) {
      setError("Passwords do not match!")
      setTimeout(function () {
        setError("")
      }, 5000)
      return false
    }
    return true
  }
  return (
    <React.Fragment>
      <Row className=" justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <div className="bg-soft">
            <Row>
              <Col className="col-12">
                <div className="p-4 text-center">
                  <h5
                    style={{ color: "#ffffff", fontSize: "28px" }}
                  >{`CREATE YOUR PASSWORD`}</h5>
                </div>
              </Col>
            </Row>
          </div>
          <Card className=" overflow-hidden">
            <CardBody className="pt-4 mx-4">
              {error ? <Alert color="danger">{error}</Alert> : null}

              <div className="mt-4 mb-4 text-center">
                <span>
                  <img
                    src={tick}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstep(1)
                    }}
                  />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img
                    src={tick}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstep(2)
                    }}
                  />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img
                    src={tick}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setstep(3)
                    }}
                  />
                </span>
                <span className="mx-2">
                  <img src={line} alt="" />
                </span>
                <span>
                  <img src={fourLight} alt="" />
                </span>
              </div>
              <div className="p-0">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <FormGroup className="mb-3">
                    <Label>Password*</Label>
                    <InputGroup>
                      <input
                        name="password"
                        value={password1}
                        className="form-control"
                        onChange={e => {
                          setpassword1(e.target.value)
                        }}
                        placeholder="Enter password"
                        type={password1Type ? "text" : "password"}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i
                            className={
                              password1Type ? "mdi mdi-eye" : "mdi mdi-eye-off"
                            }
                            onClick={() => setpassword1Type(!password1Type)}
                          />
                        </span>
                      </div>
                    </InputGroup>
                    <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                      {password1 !== "" &&
                        !re.test(password1) &&
                        "Password should be atleast 8 characters containing capital letter, small letter, number and special character"}
                    </span>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Label>Confirm Password*</Label>
                    <InputGroup>
                      <input
                        name="password"
                        value={password2}
                        className="form-control"
                        onChange={e => {
                          setpassword2(e.target.value)
                        }}
                        placeholder="Enter password"
                        type={password2Type ? "text" : "password"}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i
                            className={
                              password2Type ? "mdi mdi-eye" : "mdi mdi-eye-off"
                            }
                            onClick={() => setpassword2Type(!password2Type)}
                          />
                        </span>
                      </div>
                    </InputGroup>
                    <span style={{ color: "#f46a6a", fontSize: "80%" }}>
                      {password2 !== "" &&
                        !re.test(password2) &&
                        "Password should be atleast 8 characters containing capital letter, small letter, number and special character"}
                    </span>
                  </FormGroup>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-success btn-block"
                      style={{ background: "#76C4CC", borderColor: "#76C4CC" }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </AvForm>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(Step4)

Step4.propTypes = {
  submitData: PropTypes.func,
  setstep: PropTypes.func,
  setdata: PropTypes.func,
  data: PropTypes.object,
}
