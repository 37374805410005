export const FETCH_TAGS = "FETCH_TAGS"
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS"
export const FETCH_TAGS_ERROR = "FETCH_TAGS_ERROR"
export const UPDATE_STORE = "UPDATE_STORE"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_ERROR = "UPDATE_STORE_ERROR"
export const FETCH_PRODUCTS = "FETCH_PRODUCTS"
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS"
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR"
export const FETCH_PRODUCT = "FETCH_PRODUCT"
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS"
export const FETCH_PRODUCT_ERROR = "FETCH_PRODUCT_ERROR"
export const SAVE_PRODUCT = "SAVE_PRODUCT"
export const SAVE_PRODUCT_SUCCESS = "SAVE_PRODUCT_SUCCESS"
export const SAVE_PRODUCT_ERROR = "SAVE_PRODUCT_ERROR"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR"
export const UPDATE_PRODUCT_PHOTO = "UPDATE_PRODUCT_PHOTO"
export const UPDATE_PRODUCT_PHOTO_SUCCESS = "UPDATE_PRODUCT_PHOTO_SUCCESS"
export const UPDATE_PRODUCT_PHOTO_ERROR = "UPDATE_PRODUCT_PHOTO_ERROR"
export const RESET_PRODUCT_STATE = "RESET_PRODUCT_STATE"
