import React from 'react'
import LogoImg from './logo-svg.svg'
import { NavLink } from 'react-router-dom';
function HeroSec() {
  return (
    <>
      <div className="banner-sec">
        <div className="banner-logo">
          <img src={LogoImg} alt="" />
          <NavLink to="/login" className="login-button">
          Login
        </NavLink>
        </div>
      </div>
    </>
  )
}

export default HeroSec