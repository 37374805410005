import PropTypes from 'prop-types'
import React from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
import { CardTitle } from "reactstrap"

const LoadingContainer = () => <div>Loading...</div>

const MapsGoogle = props => {
  const selectedPlace = {}
  const {store} = props;
  function onMarkerClick() {
    alert("You clicked in this marker")
  }

  return (
    <React.Fragment>
        <CardTitle className="mb-3">
            Location on Google Map
        </CardTitle>
        <div
            id="gmaps-markers"
            className="gmaps"
            style={{ position: "relative" }}
        >
            {store && <Map
                google={props.google}
                style={{ width: "100%", height: "100%" }}
                zoom={12}
                initialCenter={{
                    lat: store.latitude,
                    lng: store.longitude,
                }}
            >
                <Marker
                    title={store && store.address}
                    name={store && store.name}
                    position={{ lat: store.latitude, lng: store.longitude }}
                />
                <InfoWindow>
                <div>
                    <h1>{selectedPlace.name}</h1>
                </div>
                </InfoWindow>
            </Map>
          }
        </div>
    </React.Fragment>
  )
}

MapsGoogle.propTypes = {
  google: PropTypes.object
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLEAPIKEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
)

MapsGoogle.propTypes = {
  store: PropTypes.object,
}