import React from "react"
import PropTypes from "prop-types"
import {
  Modal
} from "reactstrap"

const ImageView = props => {

  const { isOpen, toggle,image } = props

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className=""
      toggle={toggle}
      size="md"
    >
        <img
            height="100%"
            width="100%"
            style={{ borderRadius: "7px"}}
            src={image}
        />
    </Modal>
  )
}

ImageView.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  image: PropTypes.object,
}

export default ImageView
